import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/activityRelations/RenderActivityCodes.module.scss';
import { Pills } from 'components/common/pills/pills';

import { QueryResponse } from 'api/queries/activityCodesByVersion.query';

const maxLength = 12;
const notAvailable = 'N/A';

export const RenderActivityCodes = ({ value = [] }: { value: QueryResponse[] }) => {
    const activityCodes = value.map((i) => `${i.scope}: ${i.typeName}: ${i.description}`).sort();
    const more = (
        <div className={classes.more}>
            {activityCodes.length > maxLength && `& ${activityCodes.length - maxLength} more...`}
        </div>
    );

    return (
        <div data-testid={'activity-codes-container'} className={classes.renderActivityCodesContainer}>
            <span className={classes.title}>Activity Codes: </span>
            <span
                className={`${classes.value} ${activityCodes.length === 0 && classes.notAvailable}`}
                title={activityCodes.length > 0 ? activityCodes.join(', ') : notAvailable}
            >
                {activityCodes.length > 0
                    ? activityCodes.slice(0, maxLength).map((item) => <Pills key={item}>{item}</Pills>)
                    : notAvailable}
                {more}
            </span>
        </div>
    );
};
