import React, { useState } from 'react';
import Popover from '@mui/material/Popover';
import { TooltipPosition } from './customizedTooltip.enums';
import { configureTooltipPosition } from './customizedTooltip.utils';
import colorsVars from 'styles/colors.module.scss';

const { textColor, tooltipBackGround } = colorsVars;

const TOOLTIP_DEFAULT_STYLES = {
    backgroundColor: tooltipBackGround,
    border: 'solid 1px var(--tooltip_border)',
    borderRadius: '6px',
    boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.57)',
    color: textColor,
    fontFamily: `'Roboto', sans-serif`,
    fontSize: '1.5rem',
    lineHeight: '1.54rem',
    padding: '1rem',
    textAlign: 'left',
    wordBreak: 'break-word',
};

interface ICustomizedTooltipProps {
    triggerElement: React.ReactNode;
    tooltipContent: React.ReactNode;
    contentStyles?: {
        [key: string]: any;
    };
    tooltipPosition?: TooltipPosition;
}

export const CustomizedTooltip = ({
    triggerElement,
    tooltipContent,
    contentStyles = {},
    tooltipPosition = TooltipPosition.Top,
}: ICustomizedTooltipProps) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);

    const mergedStyle = { ...TOOLTIP_DEFAULT_STYLES, ...contentStyles };

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <div
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                data-testid={'tooltip-trigger-element'}
            >
                {triggerElement}
            </div>
            <Popover
                sx={{
                    pointerEvents: 'none',
                    '& .MuiPaper-root': mergedStyle,
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={configureTooltipPosition(tooltipPosition).anchorOrigin}
                transformOrigin={configureTooltipPosition(tooltipPosition).transformOrigin}
                disableRestoreFocus
                disableEnforceFocus={true}
                disableScrollLock
                data-testid={'tooltip-content-element'}
            >
                {tooltipContent}
            </Popover>
        </>
    );
};
