import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { useQuery } from 'react-query';
import {
    IStatus,
    IAssignee,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/battlecards.types';
import { QueryKeys } from 'api/queryKeys';

const queryFn = (params) => {
    return network.post(API_URL().GET_ACTIVITY_PREDICTION, params).then((response) => {
        return response.data;
    });
};

export const useQueryActivityPrediction = (params: QueryParams) => {
    return useQuery<QueryResponse, QueryError, QueryResponse, [QueryKeys, unknown]>({
        queryKey: ['activityPrediction', params],
        queryFn: () => queryFn(params),
        enabled: Boolean(params.hashCode) && Boolean(params.versionId),
    });
};

interface QueryResponse {
    taskId: number;
    activityId: string;
    name: string;
    status: IStatus;
    assignee: IAssignee;
    planedFinishDate: number | null;
    optimisticFinishDate: number | null;
    optimisticFinishVariance: number | null;
    bestFinishDate: number | null;
    bestFinishVariance: number | null;
    pessimisticsFinishDate: number | null;
    pessimisticsFinishVariance: number | null;
    baselineFinishDate: number | null;
    baselineFinishVariance: number | null;
    isVirtual: boolean;
    info: IToolTip;
    probability: number;
}

interface QueryParams {
    hashCode?: string | null;
    versionId?: number;
}
