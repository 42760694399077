import { GraphPoints } from 'components/Dashboards/Project/Components/VersionCompare/components/WidgetCardGraph';
import { useVersionStore } from 'store/version.store';
import colorsVars from 'styles/colors.module.scss';
import { formatValueForGraph } from 'utilitys/helpers/general';

const { white, graphAnchorBorderColor, cardGraphBackGroundDark, cardGraphFont } = colorsVars;

interface serializeDataProps {
    data: GraphPoints[];
    themeStatus: boolean;
    yaxisTitle: string;
}

export const serializeData = ({
    data,
    themeStatus,
    yaxisTitle,
}: serializeDataProps): ApexAxisChartSeries | ApexNonAxisChartSeries | undefined => {
    const versionName = useVersionStore.getState().version?.versionName;
    return [
        {
            name: yaxisTitle,
            data: data.map((item, index) => ({
                x: index + 1,
                y: item.score,
                fillColor: white,
                strokeColor:
                    versionName === item.versionName ? graphAnchorBorderColor : themeStatus ? white : cardGraphFont,
            })),
            color: themeStatus ? white : cardGraphFont,
        },
    ];
};

interface serializeOptionsProps {
    data: GraphPoints[];
    themeStatus: boolean;
    xaxisTitle: string;
    yaxisTitle: string;
    yAxisMaxValue: number | undefined;
}

export const serializeOptions = ({
    data,
    themeStatus,
    xaxisTitle,
    yaxisTitle,
    yAxisMaxValue,
}: serializeOptionsProps): ApexCharts.ApexOptions => {
    const categories = data.map((item) => item.versionName);

    return {
        chart: {
            background: themeStatus ? cardGraphBackGroundDark : white,
            foreColor: cardGraphFont,
            toolbar: {
                show: false,
            },
        },
        xaxis: {
            type: 'category',
            categories: categories,
            labels: {
                show: false,
            },
            title: {
                text: xaxisTitle,
                offsetX: 0,
                offsetY: -15,
                style: {
                    fontSize: '1.4rem',
                    fontWeight: 400,
                },
            },
        },
        stroke: {
            width: 2,
        },
        yaxis: {
            min: 0,
            max: yAxisMaxValue,
            tickAmount: 4,
            labels: {
                style: {
                    fontSize: '1.2rem',
                },
                formatter: (value) => {
                    return formatValueForGraph(value);
                },
            },
            title: {
                text: yaxisTitle,
                style: {
                    fontSize: '1.4rem',
                    fontWeight: 400,
                },
            },
        },
        markers: {
            size: 5,
        },
        tooltip: {
            theme: themeStatus ? 'dark' : 'light',
        },
    };
};
