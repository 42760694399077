import { history } from 'components/App/app';

const error500Codes = [501, 502, 503, 504, 505, 506, 507, 508, 509, 510];

const interceptor = (instance) => {
    instance.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if (error.response) {
                const status = error.response.status;
                if (status === 401) {
                    history.push('/logout');
                }
                if (status === 500) {
                    history.push('/error500');
                }
                if (status === 404) {
                    const pathList = history.location.pathname.split('/');
                    const path = pathList.length > 1 ? [pathList[0], pathList[1]].join('/') : '';
                    history.push(`${path}/error404`);
                }
                if (error500Codes.includes(status)) {
                    error.response.data = `Network error (${status}): Gateway Timeout server error. please try again later.`;
                }
            }
            return Promise.reject(error);
        },
    );
};

export default {
    interceptor,
};
