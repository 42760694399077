import moment from 'moment-timezone';
import colorsVars from 'styles/colors.module.scss';
import { orderBy, cloneDeep } from 'lodash';
import {
    IExternalLink,
    ITagList,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/battlecards.types';
import constants from 'components/common/Constants/constants';
import { IDataStoryUser } from 'components/Dashboards/Project/Components/DataStories/dataStories.types';
import { QueryResponse } from 'api/queries/activityCodesByVersion.query';

const { programActivity, recommendations, userActions } = colorsVars;

export type CardType = 'PROGRAMME_ACTIVITY' | 'KC_INSIGHT' | 'USER_GENERATED';

/**
 * mapping array of objects to fit for Select Component Options List
 * different map function - depends on how we get the Data
 * @param i (object)
 * @returns {{id, label: *, value}}
 */
const mapper = (i) => ({ id: i?.id, value: i.name, label: i.label ? i.label : i.name });
const assigneeMapper = (i: IDataStoryUser) => ({
    id: null,
    value: i.userName,
    label: `${i.lastName.length > 0 ? `${i.firstName} ${i.lastName}` : i.firstName}`,
    assignee: { ...i, id: null },
});
const versionMapper = (i) => ({
    id: i.id,
    value: i.versionName,
    label: i.versionDate
        ? `${i.versionName} (${moment(i.versionDate).format(constants.formats.date.default)})`
        : i.versionName,
    versionHashCode: i.versionHashCode,
});
const wbsMapper = (i) => ({
    id: i.id,
    value: i.activityId,
    label: i.label ? `${i.label} (${i.activityId})` : `${i.name} (${i.activityId})`,
});

export const activityIdMapper = (i) => ({
    id: i.id,
    value: i.activityId,
    label: i.activityId,
});

const activityCodeMapperUID = (
    item: QueryResponse,
): { value: { name: number; isExclude: boolean; alias: string | null }; label: string } => {
    const label = item.alias
        ? `${item.alias}: ${item.description} (~${item.activitiesCount} related activities)`
        : `${item.scope}: ${item.typeName}: ${item.description} (~${item.activitiesCount} related activities)`;
    return {
        value: { name: item.uid, isExclude: false, alias: item.alias },
        label,
    };
};

const milestoneFragnetMapper = (item) => ({
    id: item.id,
    label: `${item.name} (ID: ${
        item.activity_id.length > 20 ? item.activity_id.slice(0, 20) + '...' : item.activity_id
    }) (~${item.preceding_activities} related activities)`,
    value: item.task_version_hash_code,
});

export interface ICreateCardObjectReturn {
    id?: number | null;
    projectId?: number;
    contractId?: number;
    activityId?: number;
    category?: string;
    details?: string;
    mentionedList?: string[];
    startDate?: number;
    endDate?: number | null;
    externalLinks?: IExternalLink[];
    status?: string;
    tagList?: ITagList[];
    title?: string;
    relatedCardIds?: null;
    type?: CardType;
    assigneeUserName?: string;
    isImportant?: boolean;
}

/**
 * set version name options (array) accordingly to project and contract
 */
const getVersionNameOptions = ({ versionList }) => {
    if (versionList) {
        const tempVersionOptions = versionList.map((item) => ({
            id: item.versionDbId,
            sourceFile: item.sourceFile,
            versionDate: item.versionDate,
            versionName: item.versionName,
            versionHashCode: item.versionHashCode,
        }));
        return sortNumberArray(tempVersionOptions, 'versionDate', 'desc');
    }
    return [];
};

export const getDateRange = (value) => {
    return value.map((date, index) => {
        return index === 0 ? moment(date).startOf('day').valueOf() : moment(date).endOf('day').valueOf();
    });
};

/**
 * get background-image color for custom card
 * @param id number of lane id(status)
 * @returns {*}
 */
const getStatusColor = (id) => {
    const colors = {
        1: colorsVars.todo,
        2: colorsVars.inProgress,
        3: colorsVars.done,
    };

    return colors[id];
};

/**
 * sort Numbers value asc or desc => Returns New Array
 * array of numbers - should pass 'key' as null
 * array of objects - should pass 'key' as the property of the object on witch the sort apply on
 * example 1: sortNumberArray([1,2,3], null, 'desc') => [3,2,1]
 * example 2: sortNumberArray([3,2,1], null, 'asc') => [1,2,3]
 * example 3: sortNumberArray([{name:someName, number:1}, {name:someName, number:2}, {name:someName, number:3}], 'number', 'desc') => [{name:someName, number:3}, {name:someName, number:2}, {name:someName, number:1}]
 * @param arr array witch should be sorted
 * @param key the property that should be sorted by
 * @param sortOrder asc or desc
 * @returns {*}
 */
const sortNumberArray = (arr, key, sortOrder = 'asc') => {
    const sortedArr = [...arr];
    if (sortedArr.length > 0) {
        if (key === null) {
            return sortOrder === 'asc'
                ? sortedArr.sort((a, b) => (a > b ? 1 : -1))
                : sortedArr.sort((a, b) => (a < b ? 1 : -1));
        }

        if (sortedArr[0].hasOwnProperty(key)) {
            return sortOrder === 'asc'
                ? sortedArr.sort((a, b) => (a[key] > b[key] ? 1 : -1))
                : sortedArr.sort((a, b) => (a[key] < b[key] ? 1 : -1));
        }

        return sortedArr;
    }

    return sortedArr;
};

export const getStatusLabelByOriginalStatus = (originalStatus) => {
    switch (originalStatus) {
        case 'TODO':
            return 'To Do';
        case 'IN_PROGRESS':
            return 'In Progress';
        case 'DONE':
            return 'Done';
        default:
            return '';
    }
};

const colorByCardType = (typeName: CardType | undefined) => {
    if (typeName === 'PROGRAMME_ACTIVITY') {
        return programActivity;
    } else if (typeName === 'KC_INSIGHT') {
        return recommendations;
    }
    return userActions;
};

const initialLanesState = {
    lanes: [
        {
            id: '1',
            label: '(0)',
            style: {
                backgroundColor: 'initial',
                flex: '1',
                color: '#000000',
                borderRadius: '28px 28px 0 0',
                padding: '0',
            },
            cards: [] as any,
        },
        {
            id: '2',
            label: '(0)',
            style: {
                backgroundColor: 'initial',
                flex: '1',
                color: '#000000',
                borderRadius: '28px 28px 0 0',
                padding: '0',
            },
            cards: [] as any,
        },
        {
            id: '3',
            label: '(0)',
            style: {
                backgroundColor: 'initial',
                flex: '1',
                color: '#000000',
                borderRadius: '28px 28px 0 0',
                padding: '0',
            },
            cards: [] as any,
        },
    ],
};

const initialFiltersState = {
    filter: {
        filters: [],
    },
    paging: {
        page: 0,
        size: 50,
    },
    withDetails: true,
};

const initialCountersState = {
    toDo: {
        tda: '',
        milestone: '',
        kcInsight: '',
        userGenerated: '',
    },
    inProgress: {
        tda: '',
        milestone: '',
        kcInsight: '',
        userGenerated: '',
    },
    done: {
        tda: '',
        milestone: '',
        kcInsight: '',
        userGenerated: '',
    },
};

const prepareLanes = ({ cards, cardsData, isLoadMore }) => {
    const lanes = cloneDeep(cardsData.lanes);
    if (isLoadMore) {
        lanes[0].cards.push(
            ...orderBy(
                cards.filter((item) => item.laneId === '1'),
                ['rank', 'criticalityScore'],
                ['asc', 'desc'],
            ),
        );
        lanes[1].cards.push(
            ...orderBy(
                cards.filter((item) => item.laneId === '2'),
                ['rank', 'criticalityScore'],
                ['asc', 'desc'],
            ),
        );
        lanes[2].cards.push(
            ...orderBy(
                cards.filter((item) => item.laneId === '3'),
                ['rank', 'criticalityScore'],
                ['desc', 'desc'],
            ),
        );
    } else {
        lanes[0].cards = orderBy(
            cards.filter((item) => item.laneId === '1'),
            ['rank', 'criticalityScore'],
            ['asc', 'desc'],
        );
        lanes[1].cards = orderBy(
            cards.filter((item) => item.laneId === '2'),
            ['rank', 'criticalityScore'],
            ['asc', 'desc'],
        );
        lanes[2].cards = orderBy(
            cards.filter((item) => item.laneId === '3'),
            ['rank', 'criticalityScore'],
            ['desc', 'desc'],
        );
    }

    return lanes;
};

const prepareCards = ({ cards }) => {
    return cards.map((item) => {
        return {
            ...item,
            id: `${item.id}_${item.isVirtual}`,
            metadata: { isVirtual: item.isVirtual },
            laneId: item.status.id,
        };
    });
};

const getStatusNameByLaneId = (laneId) => {
    switch (laneId) {
        case '1':
            return 'TODO';
        case '2':
            return 'IN_PROGRESS';
        case '3':
            return 'DONE';
    }
};

export {
    mapper,
    assigneeMapper,
    versionMapper,
    wbsMapper,
    getStatusColor,
    getVersionNameOptions,
    initialLanesState,
    initialFiltersState,
    initialCountersState,
    activityCodeMapperUID,
    colorByCardType,
    prepareLanes,
    prepareCards,
    milestoneFragnetMapper,
    getStatusNameByLaneId,
};

export const statusOptions = [
    {
        id: '1',
        name: 'TODO',
        label: 'To Do',
    },
    {
        id: '2',
        name: 'IN_PROGRESS',
        label: 'In Progress',
    },
    {
        id: '3',
        name: 'DONE',
        label: 'Done',
    },
];
