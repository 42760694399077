import { useQuery } from 'react-query';
import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { QueryKeys } from 'api/queryKeys';
import { IFilter } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';

const queryFn = (params: QueryParams) => {
    return network.post(API_URL().FETCH_PROGRAM_DASHBOARD_MILESTONE, params).then((response) => {
        return response.data;
    });
};
export const useQueryMilestones = (params: QueryParams) => {
    return useQuery<QueryResponse, QueryError, QueryResponse, [QueryKeys, unknown]>({
        queryKey: ['getMilestone', params],
        queryFn: () => queryFn(params),
        enabled: params.id.length > 0 && params.id.filter((item) => item).length > 0,
    });
};

interface QueryParams {
    id: number[];
    filter: {
        filters: IFilter[];
    };
}

export interface QueryResponse {
    additionalData: {
        month: string;
        negativeDelayDrivers: number;
        numOfMilestones: number;
        positiveDelayDrivers: number;
    }[];
    info: IToolTip;
    milestones: {
        activityType: string;
        calculatedDate: number;
        calculatedDateStringFormat: string;
        criticalityScore: number;
        delayDriver: boolean;
        description: string;
        milestoneStatus: string;
        numOfPredecessors: number;
        numOfSuccessors: number;
        plannedActual: string;
        taskCode: string;
        toolTip: string;
        wbsCode: string;
        wbsLevel: number;
        wbsName: string;
    }[];
    nowDate: string;
    nowDateNotFormatted: number;
}
