import colorsVars from 'styles/colors.module.scss';
import { CustomizedStatusBar, IData } from 'components/common/CustomizedStatusBar/CustomizedStatusBar';

import { ITab } from 'components/common/CustomizedTabs/CustomizedTabs';
import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/relationships/relationships.module.scss';
import { Link, useLocation, useParams } from 'react-router-dom';
import { TooltipCellWithCopy } from 'components/common/Tables/Cells/tooltipCellWithCopy';
import React from 'react';
import {
    IRelationActivityResponse,
    ITableDataItem,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/queries/relationActivityQuery';
import { CustomizedTooltip } from 'components/common';

const { todoCardBackground, inProgressCardBackground, doneCardBackground } = colorsVars;

export type TABS_OPTIONS = 'ANCESTORS' | 'DESCENDANTS' | 'PREDECESSORS' | 'SUCCESSORS';

const getDataForStatusBar = (data: ITableDataItem[]): IData => {
    const obj: IData = {
        done: {
            label: 'Done',
            color: doneCardBackground,
            count: 0,
        },
        inProgress: {
            label: 'In Progress',
            color: inProgressCardBackground,
            count: 0,
        },

        todo: {
            label: 'To Do',
            color: todoCardBackground,
            count: 0,
        },
    };

    data.forEach((item) => {
        switch (item.activityStatus) {
            case 'To Do':
                obj.todo.count = obj.todo.count + 1;
                break;
            case 'In Progress':
                obj.inProgress.count = obj.inProgress.count + 1;
                break;
            case 'Done':
                obj.done.count = obj.done.count + 1;
                break;
        }
    });

    return obj;
};

export const generateTabs = ({
    data,
    activityType,
}: {
    data?: IRelationActivityResponse;
    activityType?: string;
}): ITab<TABS_OPTIONS>[] => {
    if (!data) return [];

    const ancestors: ITableDataItem[] = data.ancestors ?? [];
    const decedents: ITableDataItem[] = data.decedents ?? [];
    const predecessors: ITableDataItem[] = data.predeccessors ?? [];
    const successors: ITableDataItem[] = data.successors ?? [];

    if (activityType && activityType === 'WBS') {
        return [
            {
                value: 'ANCESTORS',
                label: (
                    <div className={classes.singleTab} data-testid={'wbs-ancestors-tab'}>
                        <CustomizedTooltip
                            tooltipContent={'WBS parent hierarchy'}
                            triggerElement={`WBS Ancestors (${ancestors.length})`}
                        />
                        <div className={classes.statusBarWrapper}>
                            <CustomizedStatusBar data={getDataForStatusBar(ancestors)} />
                        </div>
                    </div>
                ),
            },
            {
                value: 'DESCENDANTS',
                label: (
                    <div className={classes.singleTab} data-testid={'wbs-descendants-tab'}>
                        <CustomizedTooltip
                            tooltipContent={'WBS descendants hierarchy'}
                            triggerElement={`WBS Descendants (${decedents.length})`}
                        />
                        <div className={classes.statusBarWrapper}>
                            <CustomizedStatusBar data={getDataForStatusBar(decedents)} />
                        </div>
                    </div>
                ),
            },
        ];
    }

    return [
        {
            value: 'PREDECESSORS',
            label: (
                <div className={classes.singleTab} data-testid={'predecessors-tab'}>
                    {`Predecessors (${predecessors.length})`}
                    <div className={classes.statusBarWrapper}>
                        <CustomizedStatusBar data={getDataForStatusBar(predecessors)} />
                    </div>
                </div>
            ),
        },
        {
            value: 'SUCCESSORS',
            label: (
                <div className={classes.singleTab} data-testid={'successors-tab'}>
                    {`Successors (${successors.length})`}
                    <div className={classes.statusBarWrapper}>
                        <CustomizedStatusBar data={getDataForStatusBar(successors)} />
                    </div>
                </div>
            ),
        },
        {
            value: 'ANCESTORS',
            label: (
                <div className={classes.singleTab} data-testid={'wbs-ancestors-tab'}>
                    <CustomizedTooltip
                        tooltipContent={'WBS parent hierarchy'}
                        triggerElement={`WBS Ancestors (${ancestors.length})`}
                    />
                    <div className={classes.statusBarWrapper}>
                        <CustomizedStatusBar data={getDataForStatusBar(ancestors)} />
                    </div>
                </div>
            ),
        },
    ];
};

export const destructureData = (data) => {
    if (!data) return {};

    const ancestors: ITableDataItem[] = data.ancestors ?? [];
    const decedents: ITableDataItem[] = data.decedents ?? [];
    const predecessors: ITableDataItem[] = data.predeccessors ?? [];
    const successors: ITableDataItem[] = data.successors ?? [];

    return { ancestors, decedents, predecessors, successors };
};

export const ActivityCell = ({ row, value }) => {
    const { pathname, search } = useLocation();
    const { cardId }: { cardId: string } = useParams();
    const cleanPath = pathname.replace(`/card-${cardId}`, '');
    return row.original.id ? (
        <Link to={`${cleanPath}/card-${row.original.id}${row.original.isVirtual ? '-1' : ''}${search}`}>{value}</Link>
    ) : (
        <TooltipCellWithCopy text={value} showAlways={true} />
    );
};
