import PageContainer from 'components/common/PageContainer/PageContainer';
import { VersionWrapper } from 'components/common/PageContainer/versionWrapper';
import { VersionSection } from 'components/common/PageContainer/versionSection';
import { TreeProgramTopHeader } from 'components/common';
import { MainContent } from 'components/common/PageContainer/MainContent';
import { MilestoneFragnetFilter } from 'components/common/GlobalFilterComponent/components/MilestoneFragnetFilter/MilestoneFragnetFilter';
import { GlobalFilterButton } from 'components/common/GlobalFilterButton/GlobalFilterButton';
import { excellenceAppsConfig } from 'components/Dashboards/ExcellenceApps/excellenceAppsConfig';
import { GlobalFilterComponent } from 'components/common/GlobalFilterComponent/GlobalFilterComponent';
import { useFullStoryPageView } from 'hooks/useFullstoryPageView';
import { HeaderWrapper } from 'components/common/PageContainer/HeaderWrapper';
import { MainCardRoutingModal } from 'components/common/MainCardRoutingModal/mainCardRoutingModal';
import { FuturePotentialDelayDriversWrapper } from 'components/Dashboards/ExcellenceApps/FinishDatePredictionPage/components/FuturePotentialDelayDriversComponent/FuturePotentialDelayDriversWrapper';
import { ActivitiesPredecessorsWrapper } from 'components/Dashboards/ExcellenceApps/FinishDatePredictionPage/components/activityCompletionPlanComponent/activitiesPredecessorsWrapper';
import { OverallSummaryWrapper } from 'components/Dashboards/ExcellenceApps/FinishDatePredictionPage/components/OverallSummaryComponent/OverallSummaryWrapper';
export const FinishDatePredictionPage = () => {
    useFullStoryPageView({ pageName: excellenceAppsConfig.finishDatePrediction.title });

    return (
        <PageContainer isResponsive={true}>
            <HeaderWrapper>
                <VersionWrapper>
                    <VersionSection>
                        <TreeProgramTopHeader pageTitle={excellenceAppsConfig.finishDatePrediction.title} />
                    </VersionSection>
                    <GlobalFilterButton />
                </VersionWrapper>

                <GlobalFilterComponent defaultValues={{ milestoneFragnet: null }}>
                    <MilestoneFragnetFilter name={'milestoneFragnet'} />
                </GlobalFilterComponent>
            </HeaderWrapper>

            <MainContent>
                <OverallSummaryWrapper />
                <ActivitiesPredecessorsWrapper />
                <FuturePotentialDelayDriversWrapper />
            </MainContent>
            <MainCardRoutingModal
                queryKeys={['activityCompletionPlan', 'futurePotentialActivities', 'activityPrediction']}
            />
        </PageContainer>
    );
};
