import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import classes from 'components/Settings/Components/Tags/components/tags.module.scss';
import { CustomizedButton } from 'components/common';
import MainTable from 'components/common/Tables/MainTable/MainTable';
import { columns, defaultColumn, initialState } from 'components/Settings/Components/Tags/tags.utils';
import useUserHook from 'hooks/useUserHook';
import { useHistory, useLocation } from 'react-router-dom';
import { settingsConfig } from 'components/Settings/settingsConfig';
import { useMemo } from 'react';
import { useQueryGetStoredTags } from 'api/queries/getStoredTags.query';
import { useProjectStore } from 'store/project.store';

export const Tags = () => {
    const { ability, customerId } = useUserHook();
    const { project } = useProjectStore((store) => store.selectedProject);
    const {
        data: tags,
        isLoading,
        isFetching,
    } = useQueryGetStoredTags({ enabled: ability.can('view', 'ManageProjects'), customerId, projectId: project?.id });
    const history = useHistory();
    const { pathname } = useLocation();

    const handleNew = () => {
        history.push(`${pathname}/${settingsConfig.tagsNew.link}`);
    };

    const data = useMemo(() => {
        return tags || [];
    }, [tags]);

    return (
        <WidgetWithTitle
            title="Tags"
            titleComponents={[
                <div className={classes.titleActions} key={'createTag'}>
                    <CustomizedButton onClick={handleNew}>Create New Tag</CustomizedButton>
                </div>,
            ]}
        >
            <div className={classes.container}>
                <MainTable
                    columns={columns}
                    data={data}
                    initialState={initialState}
                    defaultColumn={defaultColumn}
                    isLoading={isLoading || isFetching}
                />
            </div>
        </WidgetWithTitle>
    );
};
