import React from 'react';
import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/ProgramLibraryTable/ProgramLibraryUploadFiles/tables/mainFilesListTable.module.scss';
import moment from 'moment-timezone';
import constants from 'components/common/Constants/constants';
import { CheckboxComponent } from 'components/common/CheckboxComponent/checkboxComponent';
import TextSearchInput from 'components/common/TextSearchInput/textSearchInput';
import { CustomizedFormErrorMessage } from 'components/common';
import { Details } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/programLibrary.types';
import { Control, Controller, FieldArrayWithId, FieldErrors, UseFormSetValue } from 'react-hook-form';
import { CustomizedDatePicker } from 'components/common/CustomizedDatePicker/CustomizedDatePicker';
import { ISelectedContract } from 'store/project.store';

type Props = {
    fields: FieldArrayWithId<Details, 'versions'>[];
    control: Control<Details>;
    setValue: UseFormSetValue<Details>;
    errors: FieldErrors<Details>;
    contract: ISelectedContract;
};

const PlaniswareTable = ({ fields, control, setValue, errors, contract }: Props) => {
    return (
        <div
            data-testid={'table-container'}
            className={`${classes.tableContainer} ${classes.plfTableContainer} ${classes.planisware}`}
        >
            <table className={classes.table}>
                <tbody>
                    <tr>
                        <th>Version</th>
                        <th>Date</th>
                        <th>Baseline / Re-baseline</th>
                    </tr>
                    {fields.map((row, index) => {
                        return (
                            <tr key={row.id}>
                                <td>
                                    <Controller
                                        render={({ field }) => (
                                            <TextSearchInput
                                                {...field}
                                                placeholder={''}
                                                isSearch={false}
                                                handleClear={() => {
                                                    setValue(`versions.${index}.versionName`, '');
                                                }}
                                            />
                                        )}
                                        name={`versions.${index}.versionName`}
                                        control={control}
                                        rules={{
                                            minLength: {
                                                value: 2,
                                                message: 'Version name is too short',
                                            },
                                        }}
                                    />
                                </td>
                                <td>
                                    <Controller
                                        render={({ field }) => (
                                            <CustomizedDatePicker
                                                value={moment(field.value).valueOf()}
                                                handleChange={(value) => {
                                                    setValue(
                                                        `versions.${index}.versionDate`,
                                                        moment(value).format(constants.formats.date.versionDate),
                                                    );
                                                    setValue(
                                                        `versions.${index}.versionName`,
                                                        `${contract?.title}-${moment(value).format(
                                                            constants.formats.date.dayMonthYear,
                                                        )}`,
                                                    );
                                                }}
                                                minDate={moment().subtract(20, 'year').valueOf()}
                                                maxDate={moment().valueOf()}
                                            />
                                        )}
                                        name={`versions.${index}.versionDate`}
                                        control={control}
                                    />
                                </td>
                                <td>
                                    <Controller
                                        render={({ field }) => <CheckboxComponent {...field} checked={field.value} />}
                                        name={`versions.${index}.isBaseline`}
                                        control={control}
                                    />
                                </td>
                            </tr>
                        );
                    })}
                    <tr>
                        <td colSpan={3}>
                            {errors.versions && (
                                <CustomizedFormErrorMessage text={'version name should be at least 2 characters'} />
                            )}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default PlaniswareTable;
