import React from 'react';
import Icon from '../Icons/icon';
import { SvgIconProps } from '@mui/material';
import classes from './LabelWithIcon.module.scss';
import { kebabCase } from 'lodash';

interface Props {
    icon?: string;
    text: string | React.ReactElement;
    size?: string;
    muiIcon?: React.ReactElement<SvgIconProps>;
    [rest: string]: any;
}

const LabelWithIcon = ({ icon = '', text, size = '2rem', muiIcon, ...rest }: Props) => (
    <div
        className={classes.labelWithIconContainer}
        data-track-id={typeof text === 'string' ? kebabCase(`menu-item-${text}`) : undefined}
        {...rest}
    >
        <div className={classes.iconWrapper}>{muiIcon ? muiIcon : <Icon name={icon} size={size} />}</div>
        <div>{text}</div>
    </div>
);

export default LabelWithIcon;
