import { IPortfolioTopHeroesComponentsList } from 'api/queries/getProjectScoreCardsSummary';
import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { useQuery } from 'react-query';
import { QueryKeys } from 'api/queryKeys';

export interface QueryResponse {
    componentsList: IPortfolioTopHeroesComponentsList;
    info?: IToolTip;
    name: string;
    numOfItems: number;
}

export const queryFn = (params) => {
    return network.post(API_URL().FETCH_PORTFOLIO_TOP_HEROES, params).then((response) => {
        return response.data;
    });
};
export const useQueryPortfolioTopHeroes = (params) => {
    return useQuery<QueryResponse, QueryError, QueryResponse, [QueryKeys, unknown]>({
        queryKey: ['portfolioTopHeroes', params],
        queryFn: () => queryFn(params),
    });
};
