import classes from 'components/Dashboards/Project/Components/VersionCompare/TableTab/tabs.module.scss';
import moment from 'moment-timezone';
import constants from 'components/common/Constants/constants';

const TableTab = ({ data }) => {
    return (
        <div className={classes.tab}>
            <div className={classes.title}>{data.summary.title}</div>
            <div className={classes.subTitle}>
                {moment(data.summary.startDate).format(constants.formats.date.default)} -{' '}
                {moment(data.summary.endDate).format(constants.formats.date.default)} total {data.summary.items} changes
                ({data.summary.percent}%)
            </div>
        </div>
    );
};
export default TableTab;
