import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import classes from 'components/common/CustomFilters/DurationFilter/DurationFilter.module.scss';
import TextSearchInput from 'components/common/TextSearchInput/textSearchInput';
import { Popover } from '@mui/material';
import { CustomizedFormErrorMessage } from 'components/common/CustomizedFormErrorMessage/CustomizedFormErrorMessage';
import { CustomizedButton } from 'components/common/CustomizeButton/CustomizedButton';
import { noop } from 'lodash';

interface IFields {
    from: number | null;
    to: number | null;
}

type IDurationValue = number[] | null;

interface IDurationFilter {
    value: IDurationValue;
    onSelection: (value: IDurationValue) => void;
}

export const DurationFilter = ({ value, onSelection }: IDurationFilter) => {
    const [inputLabel, setInputLabel] = useState<string>('');
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [menuOpen, setMenuOpen] = useState<boolean>(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        watch,
        reset,
    } = useForm<IFields>({
        defaultValues: {
            from: null,
            to: null,
        },
    });

    const fromData = watch('from');
    const toData = watch('to');

    useEffect(() => {
        if (value) {
            setInputLabel(`From: ${value[0]} To: ${value[1]} days`);
            reset({
                from: value[0],
                to: value[1],
            });
        } else {
            setInputLabel('');
            reset({
                from: null,
                to: null,
            });
        }
    }, [value]);

    // inputs validation
    useEffect(() => {
        if (fromData && fromData.toString().length > 4) {
            const sliced = fromData.toString().slice(0, -1);
            setValue('from', Number(sliced), { shouldDirty: true });
        }

        if (toData && toData.toString().length > 4) {
            const sliced = toData.toString().slice(0, -1);
            setValue('to', Number(sliced), { shouldDirty: true });
        }
    }, [fromData, toData]);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
        setMenuOpen(true);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
        setMenuOpen(false);
        reset({
            from: value ? value[0] : null,
            to: value ? value[1] : null,
        });
    };

    const handleClear = () => {
        setInputLabel('');
        reset({
            from: null,
            to: null,
        });
        onSelection(null);
    };

    const submitFrom = (data) => {
        onSelection([data.from, data.to]);
        setInputLabel(`From: ${data.from} To: ${data.to} days`);
        setMenuOpen(false);
    };

    return (
        <div data-testid={'duration-filter-container'} className={classes.durationFilterContainer}>
            <TextSearchInput
                id={'battlecards-duration'}
                placeholder={'Select Duration...'}
                value={inputLabel}
                handleClear={handleClear}
                onClick={handleMenuOpen}
                autoComplete={'off'}
                className={classes.inputWidth}
                onChange={noop}
            />

            <Popover
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                disableScrollLock
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                open={menuOpen}
            >
                <div className={classes.durationPopoverContentWrapper}>
                    <form
                        onSubmit={(e) => {
                            e.stopPropagation();
                            return handleSubmit(submitFrom)(e);
                        }}
                        data-testid={'duration-filter-form'}
                        className={classes.form}
                    >
                        <div className={classes.mainContent}>
                            <div className={classes.sectionWrapper}>
                                <div className={classes.section}>
                                    <label>From:</label>
                                    <div>
                                        <input
                                            type="number"
                                            data-testid={'from-value'}
                                            className={`${classes.input} ${errors.from ? classes.error : ''}`}
                                            {...register('from', {
                                                valueAsNumber: true,
                                                required: 'From is required',
                                                validate: (value) => {
                                                    if (value !== null && toData !== null) {
                                                        return value <= toData || 'From must be smaller then To';
                                                    }
                                                },
                                                min: { value: 0, message: 'From Number must be positive' },
                                                max: 9999,
                                            })}
                                        />
                                        <span className={classes.unit}>days</span>
                                    </div>
                                </div>
                                <div className={classes.section}>
                                    <label>To:</label>
                                    <div>
                                        <input
                                            type="number"
                                            data-testid={'to-value'}
                                            className={`${classes.input} ${errors.to ? classes.error : ''}`}
                                            {...register('to', {
                                                valueAsNumber: true,
                                                required: 'To is required',
                                                validate: (value) => {
                                                    if (value !== null && fromData !== null) {
                                                        return value >= fromData || 'To must be bigger then From';
                                                    }
                                                },
                                                min: { value: 0, message: 'To Number must be positive' },
                                                max: 9999,
                                            })}
                                        />
                                        <span className={classes.unit}>days</span>
                                    </div>
                                </div>
                            </div>

                            <div className={classes.errorsWrapper}>
                                {errors.from && <CustomizedFormErrorMessage text={errors.from.message} />}
                                {errors.to && <CustomizedFormErrorMessage text={errors.to.message} />}
                            </div>
                        </div>

                        <div className={classes.footer}>
                            <div className={classes.actionButtons}>
                                <CustomizedButton size={'large'} color={'secondary'} onClick={handlePopoverClose}>
                                    Cancel
                                </CustomizedButton>
                                <CustomizedButton type={'submit'} size={'large'} color={'primary'}>
                                    Apply
                                </CustomizedButton>
                            </div>
                        </div>
                    </form>
                </div>
            </Popover>
        </div>
    );
};
