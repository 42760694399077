import React from 'react';
import { Router, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { PublicRoutes } from 'components/App/routes/publicRoutes';
import AuthenticateLayout from 'components/AuthenticateLayout/authenticateLayout';
import useUserHook from 'hooks/useUserHook';
import ProtectedRoute from 'components/App/routes/ProtectedRoute';
import { useThemeStatusStore } from 'store/themeStatus.store';
import { SnackbarWrapper } from 'utilitys/SnackbarWrapper';

require('./app.scss');

/**
 *
 * App
 *
 * This component is the skeleton around the actual Views.
 */

// ================ theme ====================================================================================
useThemeStatusStore.getState().themeStatus
    ? document?.querySelector('body')?.setAttribute('data-theme', 'dark')
    : document?.querySelector('body')?.setAttribute('data-theme', 'light');
// ===========================================================================================================

export const history = createBrowserHistory();

const App = () => {
    const { email } = useUserHook();
    const auth = Boolean(email);

    return (
        <Router history={history}>
            <SnackbarWrapper>
                <Switch>
                    {PublicRoutes()}
                    <ProtectedRoute path="/" auth={auth} component={AuthenticateLayout} />
                </Switch>
            </SnackbarWrapper>
        </Router>
    );
};

export default App;
