import { orderBy } from 'lodash';
import { IClustersListItem } from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/ClusterRCFAnalysis.type';
import { QueryResponse } from 'api/queries/clustersData.query';

export const sortClusters = ({
    clustersList,
    sortAscending,
    sortByKey,
}: {
    clustersList: IClustersListItem[];
    sortAscending: boolean;
    sortByKey: (item: IClustersListItem) => string | number;
}): IClustersListItem[] => {
    if (!clustersList) return [];
    return orderBy(clustersList, [sortByKey], [sortAscending ? 'asc' : 'desc']);
};

interface IPrepareDataResponse {
    clusterStatisticsData: QueryResponse['statistics'] | null;
    durationDistributionData: QueryResponse['durationDistribution'] | null;
    RCFDurationData: QueryResponse['rcf']['duration'];
    RCFDurationRatioData: QueryResponse['rcf']['ratio'];
    ClusterActivitiesData: QueryResponse['data']['clusterActivities'];
}

export const prepareData = ({ clusterData }: { clusterData: QueryResponse | undefined }): IPrepareDataResponse => {
    let clusterStatisticsData: QueryResponse['statistics'] | null = null;
    let durationDistributionData: QueryResponse['durationDistribution'] | null = null;
    let RCFDurationData: QueryResponse['rcf']['duration'] = [];
    let RCFDurationRatioData: QueryResponse['rcf']['ratio'] = [];
    let ClusterActivitiesData: QueryResponse['data']['clusterActivities'] = [];

    if (clusterData?.id) {
        clusterStatisticsData = clusterData.statistics;
        durationDistributionData = clusterData.durationDistribution;
        ClusterActivitiesData = clusterData.data.clusterActivities;
        RCFDurationData = clusterData.rcf.duration;
        RCFDurationRatioData = clusterData.rcf.ratio;

        // put the median that we have in clusterStatisticsData in durationDistributionData if it's missing there
        if (durationDistributionData && !('median' in durationDistributionData.planned)) {
            durationDistributionData.planned.median = clusterStatisticsData?.tableData?.plannedDuration?.median;
            durationDistributionData.actual.median = clusterStatisticsData?.tableData?.actualDuration?.median;
        }
    }
    return {
        clusterStatisticsData,
        durationDistributionData,
        RCFDurationData,
        RCFDurationRatioData,
        ClusterActivitiesData,
    };
};

export const findClosestNumber = <T extends Record<string, any>>({
    data,
    target,
    key,
    initial,
}: {
    data: T[] | null;
    target: number;
    key: { [K in keyof T]: T[K] extends number ? K : never }[keyof T];
    initial: number;
}): number => {
    return data
        ? data
              .map((item) => item[key])
              .reduce((prev, curr) => {
                  return Math.abs(curr - target) < Math.abs(prev - target) ? curr : prev;
              }, 0)
        : initial;
};
