import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/RenderLibraryTable/rowOptionsCell.module.scss';
import React, { useState } from 'react';
import { CheckboxComponent } from 'components/common/CheckboxComponent/checkboxComponent';
import { CustomizedConfirmDeleteModal } from 'components/common';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { useQueryClient } from 'react-query';
import {
    useMutationDownLoadXerFile,
    useMutationRemoveXerFile,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/queries/useQueryProgramLibrary';
import { ActionMenuOk } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/RenderLibraryTable/actionMenuOk';
import { ActionMenuPending } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/RenderLibraryTable/actionMenuPending';
import { ActionMenuError } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/RenderLibraryTable/actionMenuError';
import useUserHook from 'hooks/useUserHook';
import useEventWithDimensions from 'hooks/useEventWithDimensions';
import { useProgramLibraryTableStore } from 'store/programLibraryTable.store';
import { RenameVersionModal } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/RenderLibraryTable/renameVersionModal/renameVersionModal';
import { useVersionStore } from 'store/version.store';
import { useBattleCardsStore } from 'store/battlecards.store';
import { initialFiltersState } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/helper';

export const RowOptionsCell = ({
    index,
    status,
    errorMessage,
    id,
    sourceFile,
    versionHashCode,
    isSelected,
    toggleRowSelected,
    versionName,
}) => {
    const isMultiple = useProgramLibraryTableStore((state) => state.isMultiple);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showRenameVersionModal, setShowRenameVersionModal] = useState<boolean>(false);

    const { handleSnackBar } = useCustomSnackBar();
    const queryClient = useQueryClient();
    const { ability } = useUserHook();
    const isAdmin = ability.can('all', 'Admin');
    const isFileDownload = ability.can('view', 'FileDownload');

    const { mutate: mutateRemoveXerFile, isLoading: isLoadingRemoveXerFile } = useMutationRemoveXerFile();
    const { mutate: mutateDownLoadXerFile, isLoading: isLoadingDownloadXerFile } = useMutationDownLoadXerFile();

    const { sendEventWithDimensions } = useEventWithDimensions();

    const { setVersion } = useVersionStore();
    const { setFilterData } = useBattleCardsStore();

    const handleDeleteFiles = () => {
        mutateRemoveXerFile(
            { ids: [id] },
            {
                onSuccess: () => {
                    handleSnackBar('File was deleted', 'success');
                    setVersion(null);
                    setFilterData(initialFiltersState);

                    sendEventWithDimensions({
                        category: 'Program Library',
                        action: 'Delete Version',
                        label: `version id: ${id}`,
                    });
                },
                onError: (error) => {
                    handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
                },
                onSettled: () => {
                    setShowModal(false);
                    return queryClient.invalidateQueries('getProjectVersions');
                },
            },
        );
    };
    const handleDownloadFile = () => {
        mutateDownLoadXerFile(
            { id: id, fileName: sourceFile },
            {
                onSuccess: () => {
                    handleSnackBar('File downloaded successfully', 'success');

                    sendEventWithDimensions({
                        category: 'Program Library',
                        action: 'Download file',
                        label: sourceFile,
                    });
                },
                onError: (error) => {
                    handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
                },
                onSettled: () => {
                    setShowModal(false);
                    return queryClient.invalidateQueries('getProjectVersions');
                },
            },
        );
    };

    return (
        <>
            {!isMultiple ? (
                <div className={classes.container}>
                    {status.toLowerCase() === 'ok' && (
                        <ActionMenuOk
                            id={id}
                            versionHashCode={versionHashCode}
                            status={status}
                            errorMessage={errorMessage}
                            index={index}
                            setShowModal={setShowModal}
                            onDownLoad={handleDownloadFile}
                            isLoading={isLoadingDownloadXerFile}
                            isFileDownload={isFileDownload}
                            setShowRenameVersionModal={setShowRenameVersionModal}
                        />
                    )}
                    {status.toLowerCase() === 'pending' && <ActionMenuPending className={classes.loaderTheme} />}
                    {status.toLowerCase() === 'error' && (
                        <ActionMenuError
                            index={index}
                            setShowModal={setShowModal}
                            isFileDownload={isFileDownload}
                            onDownLoad={handleDownloadFile}
                        />
                    )}
                </div>
            ) : (
                <CheckboxComponent
                    checked={isSelected}
                    disabled={!isAdmin && status.toLowerCase() === 'pending'}
                    onChange={() => toggleRowSelected(!isSelected)}
                />
            )}

            <CustomizedConfirmDeleteModal
                isShowModal={showModal}
                hideModal={() => {
                    setShowModal(false);
                }}
                text={
                    'Are you sure you want to delete this program version? Note all its associated cards will be deleted.'
                }
                onConfirm={handleDeleteFiles}
                isLoading={isLoadingRemoveXerFile}
            />

            <RenameVersionModal
                id={id}
                versionName={versionName}
                showRenameVersionModal={showRenameVersionModal}
                setShowRenameVersionModal={setShowRenameVersionModal}
            />
        </>
    );
};
