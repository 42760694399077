import classes from './constraintsWrapper.module.scss';
import { Constraints } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/Constraints/Constraints';
import { QueryResponse } from 'api/queries/getMainCard.query';

export const ConstraintsWrapper = ({ value }: { value: QueryResponse['activityConstraints'] }) => {
    return (
        <div className={classes.constraintsWrapperContainer}>
            <div className={classes.title}>Constraint: </div>
            <Constraints data={value} />
        </div>
    );
};
