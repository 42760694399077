import { useMutation } from 'react-query';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { network } from 'services/network';

const mutationFn = (params: QueryParams) => {
    const url = API_URL().CHANGE_ASSIGNEE;

    return network.post(url, params).then((response) => {
        return response.data;
    });
};
export const useMutationChangeAssignee = () => {
    return useMutation<string, QueryError, QueryParams>({
        mutationFn: (params) => mutationFn(params),
    });
};

interface QueryParams {
    cardId: string | number;
    isVirtual: boolean;
    username: string;
    versionId: number;
}
