import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import { useQueryState } from 'hooks/useQueryState';
import { useQueryGetIntegratedScheduleVersion } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/queries/useQueryIntegratedProgramDetails';
import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/components/inputPrograms.module.scss';
import MainTable from 'components/common/Tables/MainTable/MainTable';
import { columns } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/components/inputPrograms.utils';
import { useMemo } from 'react';
import Alert from '@mui/material/Alert';
import moment from 'moment-timezone';

export const InputPrograms = () => {
    const [metaDataId] = useQueryState('metaDataId');
    const { data, isLoading, isFetching } = useQueryGetIntegratedScheduleVersion({
        fileMetaDataId: Number(metaDataId),
    });

    const integratedProjects = useMemo(() => {
        return data ? data.integratedProjects : [];
    }, [data]);

    const list = integratedProjects.map((item) => moment(item.projectFileDate).format('YYYY-MM-DD'));

    const isDateError = !list.every((item) => item === list[0]);

    return (
        <WidgetWithTitle title={`Input Programs (${integratedProjects.length})`}>
            <div className={classes.container}>
                {isDateError && (
                    <Alert severity="warning" sx={{ fontSize: '1.6rem', borderRadius: 2, marginBottom: 4 }}>
                        Note you have selected multiple programs to upload as an integrated program, but the selected
                        programs have mismatched data dates. It's recommended to make sure data dates are aligned.
                    </Alert>
                )}
                <MainTable columns={columns} data={integratedProjects} isLoading={isLoading || isFetching} />
            </div>
        </WidgetWithTitle>
    );
};
