import { CustomizedModalBase } from 'components/common/CustomizedModal/CustomizedModalBase';
import React, { useMemo, useState } from 'react';
import classes from './ShareInsightModal.module.scss';
import { RadioComponent } from 'components/common/RadioComponent/radioComponent';
import { MyCustomSelect } from 'components/common/MyCustomSelect/myCustomSelect';
import { shortMenuListStyle } from 'components/common/MyCustomSelect/myCustomSelect.style';
import { CustomizedFormErrorMessage } from 'components/common/CustomizedFormErrorMessage/CustomizedFormErrorMessage';
import { CustomizedButton } from 'components/common/CustomizeButton/CustomizedButton';
import { useQueryGetCustomerUsersList } from 'components/Dashboards/Project/Components/DataStories/queries/dataStoriesQuery';
import { assigneeMapper } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/helper';
import { CustomizedTextArea } from 'components/common/CustomizedTextArea/CustomizedTextArea';
import { Controller, useForm } from 'react-hook-form';
import TextSearchInput from 'components/common/TextSearchInput/textSearchInput';
import useUserHook from 'hooks/useUserHook';
import { useProjectStore } from 'store/project.store';
import CopyValue from 'components/common/CopyValue/CopyValue';
import { CopyLinkWithText } from 'components/common/CopyValue/CopyLinkWithText';
import { useMutationShareInsight } from 'components/common/ShareInsightModal/queries/ShareInsightModalMutation';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import OverlayWithSpinner from 'components/common/OverlayWithSpinner/overlayWithSpinner';
import useEventWithDimensions from 'hooks/useEventWithDimensions';
import { orderBy } from 'lodash';

interface IShareInsightModalProps {
    title: string;
    link: string;
    isModalOpen: boolean;
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IFields {
    userValue: UserOption | null;
    userEmail: string;
    message: string;
    componentTitle: string;
    sentBy: string;
    isInviteUser: boolean;
}

interface UserOption extends ISelectOption<string> {
    assignee: { firstName: string; lastName: string; id: null; userName: string };
}

export const ShareInsightModal = ({ title, link, isModalOpen, setIsModalOpen }: IShareInsightModalProps) => {
    const { sendEventWithDimensions } = useEventWithDimensions();
    const { email } = useUserHook();
    const {
        selectedProject: { project, contract },
    } = useProjectStore();

    const componentTitle = location.pathname.includes('/dashboard/portfolio')
        ? title
        : `${title} of ${project?.title}/${contract?.title}`; // on portfolio pages DO NOT show project and contract

    const { handleSnackBar } = useCustomSnackBar();
    const [radioOption, setRadioOption] = useState<string>('existing');

    const hideModal = () => {
        reset();
        setIsModalOpen(false);
    };

    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
        reset,
    } = useForm<IFields>({
        defaultValues: {
            componentTitle: componentTitle,
            message: '',
            userEmail: '',
            sentBy: email,
        },
    });

    const { data: usersList, isLoading: shareLoading } = useQueryGetCustomerUsersList();
    const usersListOptions = useMemo<UserOption[]>(
        () =>
            usersList
                ? orderBy(usersList.map(assigneeMapper), [(item: any) => item['label'].toLowerCase()], ['asc'])
                : [],
        [usersList],
    );

    const { mutate: shareInsightMutate, isLoading: shareInsightLoading } = useMutationShareInsight();

    const controlProps = (item) => ({
        checked: radioOption === item,
        onChange: () => setRadioOption(item),
        value: item,
        name: 'radio-option',
    });

    const handleUserValueChange = (value: UserOption | null) => {
        setValue('userValue', value);
    };

    const handleShare = (data) => {
        const finalData = {
            componentTitle: data.componentTitle,
            sentBy: data.sentBy,
            message: data.message,
            isInviteUser: radioOption === 'new',
            link: link,
        };

        const sendToValue = radioOption === 'existing' ? data.userValue.value : data.userEmail;

        shareInsightMutate(
            { ...finalData, sendTo: sendToValue },
            {
                onSuccess: () => {
                    handleSnackBar('Email Successfully Sent', 'success');
                    hideModal();

                    sendEventWithDimensions({
                        category: 'Share',
                        action: 'Share via email',
                        label: `componentTitle: ${finalData.componentTitle}, sentBy: ${finalData.sentBy}, sendTo: ${sendToValue}, isInviteUser: ${finalData.isInviteUser}`,
                    });
                },
                onError: (error) => {
                    const errorMessage = error.response?.data.length > 0 ? error.response.data : 'Email Share Error';
                    handleSnackBar(`${errorMessage}`, 'error');
                },
            },
        );
    };

    return (
        <CustomizedModalBase isModalOpen={isModalOpen} handleModalClose={hideModal}>
            <form
                className={classes.shareInsightModalContainer}
                onSubmit={(e) => {
                    e.stopPropagation();
                    return handleSubmit(handleShare)(e);
                }}
            >
                {shareInsightLoading && <OverlayWithSpinner />}
                <div className={classes.title}>Share via Email</div>
                <div className={classes.optionContainer}>
                    <label>
                        <RadioComponent {...controlProps('existing')} /> Existing User
                    </label>
                    <label>
                        <RadioComponent {...controlProps('new')} /> Invite New User
                    </label>
                </div>
                <div className={classes.content}>
                    {radioOption === 'existing' && (
                        <div className={classes.selectorWrapper}>
                            <Controller
                                render={({ field }) => (
                                    <MyCustomSelect<UserOption>
                                        {...field}
                                        options={usersListOptions}
                                        onChange={handleUserValueChange}
                                        styles={shortMenuListStyle}
                                    />
                                )}
                                name="userValue"
                                control={control}
                                rules={{
                                    required: 'User is Required',
                                }}
                            />
                            {errors.userValue && <CustomizedFormErrorMessage text={errors.userValue.message} />}
                        </div>
                    )}

                    {radioOption === 'new' && (
                        <div className={classes.selectorWrapper}>
                            <Controller
                                render={({ field }) => (
                                    <TextSearchInput
                                        {...field}
                                        placeholder={'Enter Email'}
                                        isSearch={false}
                                        handleClear={() => {
                                            setValue('userEmail', '');
                                        }}
                                    />
                                )}
                                name="userEmail"
                                control={control}
                                rules={{
                                    required: 'User is Required',
                                    pattern: {
                                        value: /\S+@\S+\.\S+/,
                                        message: 'Entered value does not match email format',
                                    },
                                }}
                            />
                            {errors.userEmail && <CustomizedFormErrorMessage text={errors.userEmail.message} />}
                        </div>
                    )}

                    <div>Recipient will receive a link to this view. You can also add an optional message.</div>
                    <div className={classes.textAreaWrapper}>
                        <Controller
                            render={({ field }) => (
                                <CustomizedTextArea {...field} placeholder={'Enter optional message'} maxLength={400} />
                            )}
                            name="message"
                            control={control}
                        />
                    </div>
                </div>
                <div className={classes.footer}>
                    <div className={classes.copyValueWrapper}>
                        <CopyValue
                            trigger={CopyLinkWithText}
                            onHoverMessage={'Copy Link to clipboard.'}
                            value={link}
                            copySuccessMessage={'Link copied to clipboard'}
                        />
                    </div>
                    <div className={classes.container}>
                        <CustomizedButton size={'large'} color={'secondary'} onClick={hideModal}>
                            Cancel
                        </CustomizedButton>

                        <CustomizedButton size={'large'} color={'primary'} type={'submit'} isLoading={shareLoading}>
                            Share
                        </CustomizedButton>
                    </div>
                </div>
            </form>
        </CustomizedModalBase>
    );
};
