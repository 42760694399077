import {
    serializeData,
    serializeOptions,
} from 'components/Dashboards/Portfolio/PortfolioOverview/components/timelineAndDensity/timelineAndDensity.utils';
import { HeatmapChart } from 'components/common/charts/heatmapChart/heatmapChart';
import { useThemeStatusStore } from 'store/themeStatus.store';

import { IData } from 'api/queries/timelineAndDensity.query';

interface Props {
    mainData: IData[];
    data: IData[];
    isFirst?: boolean;
    height: number;
    callback?: (value: number | null) => void;
    todayLineDate?: number;
}

export const HeatmapWrapper = ({ mainData, data, isFirst = false, height, callback, todayLineDate }: Props) => {
    const { themeStatus } = useThemeStatusStore();
    const series = serializeData({ mainData, data });
    const options = serializeOptions({ mainData, themeStatus, showXaxisLabels: isFirst, callback, todayLineDate });
    return <HeatmapChart series={series} options={options} height={height} />;
};
