import { useEffect, useMemo, useState } from 'react';
import classes from 'components/Dashboards/Portfolio/phasePerformance/phasePerformanceBreakdown/phasePerformanceBreakdown.module.scss';
import { useQueryPhasePerformanceGetContracts } from 'components/Dashboards/Portfolio/phasePerformance/queries/useQueryPhasePerformance';
import {
    getFiltered,
    IPhasePerformanceBreakdownCsvData,
    isProjectExpected,
    phasePerformanceBreakdownCsvHeaders,
    prepareToCsv,
} from 'components/Dashboards/Portfolio/phasePerformance/phasePerformance.utils';
import { ProjectRow } from 'components/Dashboards/Portfolio/phasePerformance/phasePerformanceBreakdown/projectRow';
import { Header } from 'components/Dashboards/Portfolio/phasePerformance/phasePerformanceBreakdown/header';
import produce from 'immer';
import { LoaderContainer } from 'components/common';
import OverlayWithSpinner from 'components/common/OverlayWithSpinner/overlayWithSpinner';
import NoData from 'components/common/NoData/noData';
import { ErrorRow } from 'components/Dashboards/Portfolio/phasePerformance/phasePerformanceBreakdown/errorRow';
import {
    IPhasePerformanceContracts,
    IPhasePerformanceProps,
} from 'components/Dashboards/Portfolio/phasePerformance/phasePerformance.types';
import { ExportDropdown } from 'components/common/ExportDropdown/exportDropdown';
import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import { prepareVersionSelectTitle } from 'components/common/TreeProgramFilter/VersionSelect/versionSelect.utils';
import { AddWidget } from 'components/Dashboards/Project/Components/CustomDashboard/AddRemoveWidget/addWidget';
import { WidgetFilters } from 'components/Dashboards/Project/Components/CustomDashboard/WidgetFilters/widgetFilters';
import { useGlobalFiltersHook } from 'hooks/useGlobalFiltersHook';
import { getFiltersData } from 'components/common/GlobalFilterComponent/GlobalFilterComponent.utils';
import { ShareInsightButton } from 'components/common/ShareInsightButton/ShareInsightButton';
import { getStringWithoutHash } from 'utilitys/helpers/general';

export const PhasePerformanceBreakdown = ({
    templateId,
    selectedProject,
    selectedStatus,
    selectedGates,
    selectedTags,
    templateName,
    widgetId,
    localFilters,
    editNarrative = null,
    externalComponents = null,
    setSubTitleForExport,
}: IPhasePerformanceProps) => {
    const filters = useMemo(
        () =>
            getFiltersData({
                CONTRACT_PHASE_PERFORMANCE:
                    widgetId && localFilters
                        ? localFilters.selectedProject.map((item) => item.contract_id)
                        : selectedProject.map((item) => item.contract_id),
                STATUS_PHASE_PERFORMANCE:
                    widgetId && localFilters
                        ? localFilters.selectedStatus.map((item) => item.value)
                        : selectedStatus.map((item) => item.value),
                TAG_PHASE_PERFORMANCE:
                    widgetId && localFilters
                        ? localFilters.selectedTags.map((item) => item.value)
                        : selectedTags.map((item) => item.value),
            }),

        [widgetId, localFilters, selectedProject, selectedStatus, selectedTags],
    );
    const [dataToCsv, setDataToCsv] = useState<IPhasePerformanceBreakdownCsvData[]>([]);
    const { data: contractsData, isLoading } = useQueryPhasePerformanceGetContracts({
        templateId: widgetId && localFilters ? localFilters.templateId : templateId,
        filter: {
            filters,
        },
    });
    const [filtered, setFiltered] = useState<IPhasePerformanceContracts[]>([]);

    const { generateFilters: formattedFilter } = useGlobalFiltersHook({
        widgetId,
        filter: {
            filters: [
                { name: 'Projects', data: localFilters?.selectedProject.map((item) => item.name) },
                { name: 'Phase', data: localFilters?.selectedGates?.label ? [localFilters?.selectedGates?.label] : [] },
                { name: 'Status', data: localFilters?.selectedStatus.map((item) => item.label) },
                { name: 'Tags', data: localFilters?.selectedTags.map((item) => item.label) },
            ],
        },
    });

    // Prepare Data To CSV
    useEffect(() => {
        if (contractsData && setDataToCsv) {
            const dataToCsv = prepareToCsv({
                data: contractsData,
                selectedProject: localFilters?.selectedProject || selectedProject,
            });
            setDataToCsv(dataToCsv);
        }
    }, [contractsData, selectedProject, localFilters]);

    useEffect(() => {
        if (contractsData) {
            setFiltered(
                getFiltered({
                    contracts: contractsData.mapped_contracts,
                    selectedGates: localFilters?.selectedGates || selectedGates,
                }).filter((item) => !isProjectExpected(item)),
            );
        }
    }, [contractsData, selectedProject, selectedStatus, selectedGates, localFilters]);

    const toggleExpandRow = (index: number): void => {
        setFiltered(
            produce(filtered, (draft) => {
                draft[index].isExpand = !draft[index].isExpand;
                return draft;
            }),
        );
    };

    const toggleExpandAll = (): void => {
        setFiltered(
            produce(filtered, (draft) => {
                const toggle = draft[0].isExpand;
                draft.forEach((item) => (item.isExpand = !toggle));
                return draft;
            }),
        );
    };

    const isExpandAll: boolean = filtered.every((item) => item.isExpand);
    const id = 'phasePerformanceBreakdown';
    const route = `${getStringWithoutHash(window.location.href)}#${id}`;
    const title = `Phase Performance Breakdown for template ${localFilters?.templateName || templateName}`;
    const subTitle: string = prepareVersionSelectTitle();

    const tableInstance = {
        visibleColumns: phasePerformanceBreakdownCsvHeaders,
        sortedRows: dataToCsv.map((item) => ({
            values: item,
        })),
    };

    useEffect(() => {
        setSubTitleForExport && setSubTitleForExport({ widgetId, subTitle, title });
    }, [widgetId, subTitle]);

    return (
        <WidgetWithTitle
            title={title}
            tooltip={contractsData?.phase_performance_breakdown_info}
            id={id}
            titleComponents={[
                externalComponents && <div key={'externalComponents'}>{externalComponents}</div>,
                <AddWidget
                    key={'AddWidget'}
                    componentKey={'phasePerformanceBreakdown'}
                    title={title}
                    widgetId={widgetId}
                    route={route}
                    localFilters={{
                        templateId,
                        selectedProject,
                        selectedStatus,
                        selectedGates,
                        selectedTags,
                        templateName,
                    }}
                />,
                <ShareInsightButton key={'shareInsight'} title={title} link={route} />,
                <ExportDropdown
                    data={dataToCsv}
                    tableInstance={tableInstance}
                    key={'export'}
                    title={title}
                    subTitle={subTitle}
                />,
            ]}
            titleFilters={[
                editNarrative && <div key={'editNarrative'}>{editNarrative}</div>,
                widgetId && formattedFilter.length > 0 ? (
                    <WidgetFilters key={'widgetFilters'} widgetId={widgetId} filters={formattedFilter} />
                ) : null,
            ]}
        >
            <>
                <div className={classes.wrapper}>
                    <div className={classes.container}>
                        {isLoading && (
                            <LoaderContainer height={'500px'}>
                                <OverlayWithSpinner />
                            </LoaderContainer>
                        )}
                        {!isLoading && filtered.length === 0 && <NoData />}
                        {!isLoading && filtered.length > 0 && (
                            <>
                                <Header toggleExpandAll={toggleExpandAll} isExpandAll={isExpandAll} />
                                <div className={classes.rows}>
                                    {filtered.map((row, index) => {
                                        return Boolean(row.error) ? (
                                            <ErrorRow key={row.contract_id} row={row} templateId={templateId} />
                                        ) : (
                                            <ProjectRow
                                                key={row.contract_id}
                                                toggleExpandRow={toggleExpandRow}
                                                index={index}
                                                row={row}
                                            />
                                        );
                                    })}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </>
        </WidgetWithTitle>
    );
};
