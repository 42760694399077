import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { useQuery } from 'react-query';
import { IActivityCompletionList, TKeys } from 'components/Dashboards/ExcellenceApps/CompletionPlanPage/utils/helper';
import { QueryKeys } from 'api/queryKeys';
import { IFilter } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import { Pagination } from 'components/common/pagination/customPagination';
import { SortingState } from '@tanstack/react-table';
import { FilterNames } from 'components/common/GlobalFilterComponent/GlobalFilterComponent.utils';

const queryFn = (params: QueryParams) => {
    return network.post(API_URL().GET_ACTIVITY_COMPLETION_PLAN, params).then((response) => {
        return response.data;
    });
};
export const useQueryActivityCompletionPlan = (params: QueryParams) => {
    return useQuery<QueryResponse, QueryError, QueryResponse, [QueryKeys, unknown]>({
        queryKey: ['activityCompletionPlan', params],
        queryFn: () => queryFn(params),
        enabled:
            Boolean(params?.filter?.filters?.find((item) => item.name === FilterNames.FRAGNET_SEARCH)) &&
            Boolean(params.metaDataId),
    });
};

interface QueryParams {
    contractId: number | null | undefined;
    metaDataId: number | undefined;
    tabId: TKeys;
    filter: {
        filters: IFilter[] | undefined;
    };
    sortingRequestList: SortingState;
    paging: Pagination;
}

export interface QueryResponse {
    activity_completion_list: IActivityCompletionList[];
    tabId: TKeys;
    info: IToolTip;
    planned_finish_date: number;
    planned_finish_date_change: number | null;
    baseline_finish_date: number;
    criticality_schedule_adherence: {
        actualPercent: number;
        plannedPercent: number;
        scheduleAdherence: number;
    };
    completion_schedule_adherence: {
        actualPercent: number;
        plannedPercent: number;
        scheduleAdherence: number;
    };
    delaying_predecessors: number;
    blocking_predecessors: number;
    allCount: number;
    blokersCount: number;
    delaydCount: number;
    onScheduleCount: number;
    completedCount: number;
    numOfPages: number;
}
