import colorsVars from 'styles/colors.module.scss';
import { formatValueForGraph, roundNumber } from 'utilitys/helpers/general';

const { white, cardGraphFont, black, pointColorForGraph } = colorsVars;

interface SerializeDataProps {
    data: { percentile: number; value: number }[];
    themeStatus: boolean;
    yaxisName: string;
}

export const serializeData = ({
    data,
    themeStatus,
    yaxisName,
}: SerializeDataProps): ApexAxisChartSeries | ApexNonAxisChartSeries | undefined => {
    return [
        {
            name: yaxisName,
            data: data.map((item) => ({
                y: item.value,
                x: item.percentile,
                fillColor: pointColorForGraph,
                strokeColor: pointColorForGraph,
                width: 5,
            })),
            color: themeStatus ? white : cardGraphFont,
        },
    ];
};

interface SerializeOptionsProps {
    data: { percentile: number; value: number }[];
    themeStatus: boolean;
    yaxisName: string;
    isAnnotation: boolean;
}

export const serializeOptions = ({
    data,
    themeStatus,
    yaxisName,
    isAnnotation,
}: SerializeOptionsProps): ApexCharts.ApexOptions => {
    const categories = data.map((item) => item.percentile);
    const tooltipLabels = data.map((item) => `${roundNumber(item.percentile, 2)}, ${roundNumber(item.value, 2)}`);

    return {
        grid: {
            xaxis: {
                lines: {
                    show: true,
                },
            },
        },
        chart: {
            background: themeStatus ? black : white,
            foreColor: cardGraphFont,
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: false,
            },
        },
        xaxis: {
            type: 'numeric',
            categories: categories,
            min: 0,
            max: 100,
            tickAmount: 3,
            labels: {
                formatter: (value) => {
                    return formatValueForGraph(Number(value));
                },
            },
            title: {
                text: 'Percentile',
                offsetX: 0,
                offsetY: 0,
                style: {
                    fontSize: '1.4rem',
                    fontWeight: 400,
                },
            },
        },
        stroke: {
            width: 3,
        },
        yaxis: {
            min: 0,
            tickAmount: 6,
            labels: {
                style: {
                    fontSize: '1.2rem',
                },
                formatter: (value) => {
                    return formatValueForGraph(value);
                },
            },
            title: {
                text: yaxisName,
                style: {
                    fontSize: '1.4rem',
                    fontWeight: 400,
                },
            },
        },
        markers: {
            size: 7,
        },
        tooltip: {
            theme: themeStatus ? 'dark' : 'light',
            y: {
                formatter: function (value, { dataPointIndex }) {
                    return tooltipLabels[dataPointIndex];
                },
            },
        },
        annotations: {
            yaxis: isAnnotation
                ? [
                      {
                          y: 1,
                          strokeDashArray: 4,
                          borderColor: themeStatus ? white : black,
                          label: {
                              position: 'center',
                              borderColor: 'transparent',
                              offsetY: 30,
                              offsetX: 130,
                              style: {
                                  color: themeStatus ? white : black,
                                  fontWeight: 'bold',
                                  background: themeStatus ? black : white,
                              },
                              text: 'On Time',
                          },
                      },
                  ]
                : [],
        },
    };
};
