import classes from 'components/Dashboards/Project/Components/CustomDashboard/ExternalComponents/narrative.module.scss';
import 'components/Dashboards/Project/Components/CustomDashboard/ExternalComponents/editNarrative.css';
import { CustomizedButton } from 'components/common';
import React, { useState } from 'react';
import RichTextArea from 'components/common/richTextArea/richTextArea';
import { IEditNarrative } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import { sortBy } from 'lodash';
import { useQueryAssigneeList } from 'api/queries/assigneeList.query';

/**
 * Editor for editing narrative description,  part  of Narrative.
 * @param {string} value - narrative description text
 * @param {() => void}  handleCancel - function cancel edit.
 * @param {(description: string) => void} handleWidgetDescription - function saves description.
 * @returns  the Widget JSX Component
 * */

export const userMentionMapper = (i) => ({
    text: `${i.firstName} ${i.lastName}`,
    value: `${i.firstName} ${i.lastName}`,
    url: i.userName,
});

export const EditNarrative = ({ value, handleCancel, handleWidgetDescription }: IEditNarrative) => {
    const [description, setDescription] = useState<string>('');
    const onEditorDescription = (value) => {
        setDescription(value);
    };
    const { data: assigneeList } = useQueryAssigneeList();

    return (
        <div className={classes.narrativeContainer}>
            <div className={`narrative-text-editor`}>
                <RichTextArea
                    onEditorDescription={onEditorDescription}
                    existingData={value}
                    editorContentHeight={250}
                    isAutoFocus={true}
                    mention={{
                        separator: ' ',
                        trigger: '@',
                        suggestions:
                            sortBy(assigneeList?.map(userMentionMapper), [(o) => o.text.toLowerCase()], ['asc']) || [],
                    }}
                />
            </div>

            <div className={classes.footer}>
                <div className={classes.container}>
                    <div className={classes.clear}>
                        <CustomizedButton
                            size={'large'}
                            color={'secondary'}
                            onClick={() => handleWidgetDescription('')}
                        >
                            Clear Narrative
                        </CustomizedButton>
                    </div>

                    <CustomizedButton size={'large'} color={'secondary'} onClick={handleCancel}>
                        Cancel
                    </CustomizedButton>

                    <CustomizedButton
                        size={'large'}
                        color={'primary'}
                        onClick={() => handleWidgetDescription(description)}
                    >
                        Save
                    </CustomizedButton>
                </div>
            </div>
        </div>
    );
};
