import { useQuery } from 'react-query';
import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { QueryKeys } from 'api/queryKeys';

const queryFn = () => {
    return network.get(API_URL().FETCH_PROGRAM_GENERAL_HIERARCHY_LIST).then((response) => {
        return response.data;
    });
};

export const useQueryGetProgramGeneralHierarchyList = (params) => {
    return useQuery<QueryResponse, QueryError, QueryResponse, [QueryKeys, unknown]>({
        queryKey: ['GetProgramGeneralHierarchyList', params],
        queryFn: () => queryFn(),
        cacheTime: 0,
    });
};

export interface QueryResponse {
    children: QueryResponse[];
    id: number;
    name: string;
    parentUUID: string | null;
    type: string;
    uuid: string;
}
