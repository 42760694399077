import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/SubProgramPreferences/components/subProgramPreferences.module.scss';
import { TagList } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/SubProgramPreferences/TagList/tagList';
import { OverdueAndUrgentDateThreshold } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/SubProgramPreferences/OverdueAndUrgentDateThreshold/overdueAndUrgentDateThreshold';
import { ImportantActivities } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/SubProgramPreferences/ImportantActivities/ImportantActivities';
import { AliasPreferences } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/SubProgramPreferences/AliasPreferences/aliasPreferences';
import { useQueryGetSubProgramPreferenceInfo } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/SubProgramPreferences/queries/useQuerySubProgramPreferences';
import { ExcelExport } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/SubProgramPreferences/ExcelExport/ExcelExport';
import { RefreshFinishDatePrediction } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/SubProgramPreferences/RefreshPrediction/RefreshFinishDatePrediction';

export const SubProgramPreferences = () => {
    const { data } = useQueryGetSubProgramPreferenceInfo();

    return (
        <>
            <WidgetWithTitle title={'Sub Program Preferences'} tooltip={data}>
                <div className={classes.formContainer}>
                    <p className={classes.note}>
                        You will be prompted to confirm in case you make any changes to the sub program preferences.
                    </p>
                    <div className={classes.inputWrapper}>
                        <TagList />
                    </div>
                    <div className={classes.inputWrapper}>
                        <OverdueAndUrgentDateThreshold />
                    </div>
                    <div className={classes.inputWrapper}>
                        <ImportantActivities />
                    </div>
                    <div className={classes.inputWrapper}>
                        <ExcelExport />
                    </div>
                    <div className={classes.inputWrapper}>
                        <RefreshFinishDatePrediction />
                    </div>
                </div>
            </WidgetWithTitle>

            <AliasPreferences />
        </>
    );
};
