import { useQuery } from 'react-query';
import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';

const getVersionList = (params: QueryParams) => {
    return network.post(API_URL().GET_VERSION_DETAILS, params).then((response) => response.data);
};

export const useQueryVersionList = (params: QueryParams) => {
    return useQuery<string[], QueryError, QueryResponse[]>({
        queryKey: ['versionList', params],
        queryFn: () => getVersionList(params),
        enabled: params.hashList.length > 0,
        cacheTime: 0,
    });
};

interface QueryParams {
    hashList: string[];
}
export interface QueryResponse {
    contractId: number;
    contractName: string;
    contractUuid: string;
    maxLevel: number;
    projectId: number;
    projectName: string;
    projectUuid: string;
    sourceFile: string;
    versionDate: number;
    versionDbId: number;
    versionHashCode: number;
    versionName: string;
    versionUniqueId: number;
}
