import Chart from 'react-apexcharts';
import classes from 'components/common/charts/lineChart/lineChart.module.scss';
import OverlayWithSpinner from 'components/common/OverlayWithSpinner/overlayWithSpinner';
import NoData from 'components/common/NoData/noData';

interface Props {
    options: ApexCharts.ApexOptions;
    series: ApexAxisChartSeries | ApexNonAxisChartSeries | undefined;
    isFetching: boolean;
    noData: boolean;
    height: number | undefined;
}

export const BarChart = ({ isFetching = false, noData, options, series, height = 600 }: Props) => {
    return (
        <div className={classes.container}>
            {isFetching && <OverlayWithSpinner />}
            {noData && <NoData />}
            {!noData && <Chart options={options} series={series} type="bar" height={height} />}
        </div>
    );
};
