const constants = {
    formats: {
        date: {
            default: 'DD MMM YYYY',
            defaultForInput: 'DD/MM/YYYY',
            defaultWithTime: 'DD MMM YYYY HH:mm',
            yearMonthDay: 'YYYY-MM-DD',
            dayMonthYear: 'DD-MM-YYYY',
            fullDateWithMilliSeconds: 'MMMM Do YYYY, h:mm:ss a',
            versionDate: 'YYYY-MM-DD HH:mm:ss',
            fullMonthAndYear: 'MMMM YYYY',
            monthAndYear: 'MMM yyyy',
            weekAndMonth: 'DD MMM',
        },
    },
};

export default constants;
