import moment from 'moment-timezone';
import colorsVars from 'styles/colors.module.scss';
import { roundNumber } from 'utilitys/helpers/general';
import constants from 'components/common/Constants/constants';
import { QueryResponse } from 'api/queries/heatMapActivityResources.query';
const { redStatusColor, statusSummaryOrange } = colorsVars;

const getIndexByDataSetText = ({ dataSet, text }: { dataSet: any; text: string }) => {
    return dataSet.findIndex((x) => x.text.includes(text));
};

const getXAxios = ({ gap, graphType }: { gap?: number; graphType: string }) =>
    gap !== undefined && gap < 10 && graphType === 'Actual' ? '+150' : '-15';

const getAnnotation = ({
    value,
    index,
    gap,
    color,
    graphType,
    dataSetIndex,
}: {
    value?: number;
    index: number;
    gap?: number;
    color?: string;
    graphType: 'Planned' | 'Actual';
    dataSetIndex: number;
}) => {
    let text = '';

    if (graphType === 'Actual') {
        text = gap && value ? `${graphType}: ${value}% Gap: ${gap}%` : `${graphType}: ${value}%`;
    }
    if (graphType === 'Planned') {
        text = `${graphType}: ${value}%`;
    }

    return value
        ? [
              {
                  id: 'high-star',
                  type: 'circle',
                  x: `$dataset.${dataSetIndex}.set.${index}.x`,
                  y: `$dataset.${dataSetIndex}.set.${index}.y`,
                  radius: '12',
                  color,
              },
              {
                  id: 'label',
                  type: 'text',
                  text,
                  fillcolor: color,
                  rotate: '90',
                  fontSize: '18',
                  x: `$dataset.${dataSetIndex}.set.${index}.x${getXAxios({ graphType, gap })}`,
                  y: `$dataset.${dataSetIndex}.set.${index}.y-25`,
              },
          ]
        : [];
};

export const getGapColor = (value: number, defaultColor: string) => {
    if (!value) return defaultColor;
    if (value > 10) return redStatusColor;
    if (value >= 1 && value < 10) return statusSummaryOrange;
    return defaultColor;
};

export const getCriticalAnalisysAnnotations = ({ annotations, dataSet }: { annotations: string; dataSet: any }) => {
    const annotationsDate = moment(annotations).format(constants.formats.date.default);
    const plannedIndex = getIndexByDataSetText({ dataSet: dataSet[0].data, text: annotationsDate });
    const actualIndex = getIndexByDataSetText({ dataSet: dataSet[1].data, text: annotationsDate });
    const plannedValue = roundNumber(dataSet[0].data[plannedIndex]?.value);
    const actualValue = roundNumber(dataSet[1].data[actualIndex]?.value);

    const gap = roundNumber(plannedValue - actualValue);

    const plannedAnnotation = getAnnotation({
        value: plannedValue,
        index: plannedIndex,
        color: dataSet[0].color,
        graphType: 'Planned',
        dataSetIndex: 0,
    });
    const defaultAnnotationColor = dataSet[1].color;
    const actualAnnotation = getAnnotation({
        value: actualValue,
        index: actualIndex,
        gap,
        color: getGapColor(actualValue, defaultAnnotationColor),
        graphType: 'Actual',
        dataSetIndex: 1,
    });

    return {
        groups: [
            {
                id: 'anchor-highlight',
                items: [...plannedAnnotation, ...actualAnnotation],
            },
        ],
    };
};

export const morefilterItems: ISelectOption<string>[] = [
    { value: 'Late Finish Date', label: 'Late Finish Date' },
    { value: 'Late Start Date', label: 'Late Start Date' },
    { value: 'Overrun Duration', label: 'Overrun Duration' },
    { value: 'Milestones', label: 'Milestones' },
];

/**
 * this sort is putting all null in the end of the list
 * @param rowA
 * @param rowB
 */
export const sortFloat = (rowA, rowB) => {
    if (rowA.original.float === null) return 1;
    if (rowB.original.float === null) return -1;
    return rowA.original.float - rowB.original.float;
};

export const defaultHeatMapOption: ISelectOption<HeatMapOptionsValue> = {
    id: 1,
    value: 'heatMapActivityStarts',
    label: 'Activity Starts',
};

export type HeatMapOptionsValue =
    | 'heatMapActivityStarts'
    | 'heatMapActivityDuration'
    | 'heatMapActivityStartOrFinish'
    | 'heatMapActivityResources';

export const heatMapOptions: ISelectOption<HeatMapOptionsValue>[] = [
    {
        value: 'heatMapActivityStarts',
        label: 'Activity Starts',
    },
    {
        value: 'heatMapActivityDuration',
        label: 'Activities in Progress',
    },
    {
        value: 'heatMapActivityStartOrFinish',
        label: 'Activity Start and Finish',
    },
    {
        value: 'heatMapActivityResources',
        label: 'Activity Resources',
    },
];

export const heatMapInitialData: QueryResponse = {
    info: { infoList: '', link: null },
    heatMapItemResponseList: [],
    startProjectDate: 0,
    endProjectDate: 0,
};
