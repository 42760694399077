import classes from 'components/common/informationBoxes/informationBoxes.module.scss';
import React, { PropsWithChildren } from 'react';

interface Props extends PropsWithChildren {
    className?: string;
}

export const InformationBoxes = ({ children, className }: Props) => {
    return <div data-testid={'information-box-row'} className={`${classes.row} ${className ?? ''}`}>{children}</div>;
};

InformationBoxes.Box = ({ children, className }: Props) => {
    return <div data-testid={'information-box-item'} className={`${classes.item} ${className}`}>{children}</div>;
};
