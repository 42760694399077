import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/RecommendationCard/CardContent/CardDetails/CardDetails.module.scss';
import { userMentionMapper } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/MainCard.utils';
import InlineSelect from 'components/common/InlineSelect/InlineSelect';
import { Control, Controller, FieldErrors, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { AssociatedDocuments } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/AssociatedDocuments/AssociatedDocuments';
import { ActionDueDates } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/ActionDueDates/ActionDueDates';
import SwitchComponent from 'components/common/Switch/Switch';
import { CustomizedTooltip } from 'components/common';
import Comments from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/comments/comments';
import { QueryResponse as CardResponse } from 'api/queries/getMainCard.query';
import { replaceTextToLinks } from 'utilitys/helpers/general';
import { IFields } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/RecommendationCard/RecommendationCard.utils.types';
import { AssigneeAvatarWithInlineSelect } from 'components/common/AssigneeAvatarWithInlineSelect/AssigneeAvatarWithInlineSelect';
import { QueryResponse as IBoardInitResponse } from 'api/queries/getBoardInitLite.query';

interface ICardDetails {
    boardInit: IBoardInitResponse | undefined;
    setValue: UseFormSetValue<IFields>;
    watch: UseFormWatch<IFields>;
    control: Control<IFields, IFields>;
    errors: FieldErrors<IFields>;
    data: CardResponse;
    queryKeys?: string[];
}

export const CardDetails = ({ boardInit, setValue, watch, control, errors, data, queryKeys = [] }: ICardDetails) => {
    const handleAssigneeSelect = (value) => {
        setValue('assignee', value, { shouldDirty: true });
    };

    const assigneeValue = watch('assignee');

    return (
        <div className={classes.cardDetailsContainer}>
            <div className={classes.leftSection}>
                <div className={classes.detailsWrapper}>
                    <div className={classes.title}>Details:</div>
                    <div className={classes.details}>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: replaceTextToLinks(data?.details, ['Related Cards']),
                            }}
                            id={'cardModalDetails'}
                        />
                    </div>
                </div>

                <Comments
                    id={data.id}
                    isVirtual={data?.isVirtual}
                    card={data}
                    assigneeOptions={boardInit?.assignee.map(userMentionMapper) || []}
                    queryKeys={queryKeys}
                />
            </div>

            <div className={classes.rightSection}>
                <div className={classes.selectWrapper}>
                    <div className={classes.title}>Assignee *:</div>
                    <div className={classes.content}>
                        <div className={classes.assigneeWrapper}>
                            <div className={classes.inlineSelectWrapper}>
                                <Controller
                                    render={({ field }) => (
                                        <AssigneeAvatarWithInlineSelect
                                            {...field}
                                            assigneeValue={assigneeValue}
                                            onSelection={handleAssigneeSelect}
                                        />
                                    )}
                                    name={`assignee`}
                                    control={control}
                                    rules={{
                                        required: 'Assignee is Required',
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={classes.selectWrapper}>
                    <div className={classes.title}>Important:</div>
                    <div className={classes.content}>
                        <CustomizedTooltip
                            triggerElement={
                                <Controller
                                    render={({ field }) => (
                                        <SwitchComponent checked={field.value} onChange={field.onChange} />
                                    )}
                                    name={`isImportant`}
                                    control={control}
                                />
                            }
                            tooltipContent={'This setting effects Priority Matrix'}
                        />
                    </div>
                </div>

                <div className={classes.selectWrapper}>
                    <div className={classes.title}>Category *:</div>
                    <div className={classes.content}>
                        <Controller
                            render={({ field }) => (
                                <InlineSelect
                                    {...field}
                                    options={[]}
                                    onSelection={field.onChange}
                                    id={'cardModalCategory'}
                                    isDisabled={true}
                                />
                            )}
                            name={`category`}
                            control={control}
                        />
                    </div>
                </div>

                <ActionDueDates control={control} watch={watch} setValue={setValue} errors={errors} />
                <AssociatedDocuments watch={watch} setValue={setValue} />
            </div>
        </div>
    );
};
