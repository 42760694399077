import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import classes from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/WhiskerPlot/Whiskerplot.module.scss';
import { ExportDropdown } from 'components/common/ExportDropdown/exportDropdown';
import { useThemeStatusStore } from 'store/themeStatus.store';
import {
    serializeData,
    serializeOptions,
} from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/WhiskerPlot/WhiskerPlot.utils';
import { BoxChart } from 'components/common/charts/boxChart/boxChart';

type WhiskerPlotProps = {
    plotData?: TWhiskerPlotData;
    title?: string;
    tooltipArg?: IToolTip;
    subTitle: string;
};

export type TWhiskerPlotData = null | {
    planned: whiskerPlotSeries;
    actual: whiskerPlotSeries;
};

type whiskerPlotSeries = {
    min?: number;
    firstQuartile?: number;
    median?: number;
    mean?: number;
    thirdQuartile?: number;
    max?: number;
    outliers?: number[];
};

const WhiskerPlot = ({ plotData, tooltipArg, subTitle }: WhiskerPlotProps) => {
    const { themeStatus } = useThemeStatusStore();
    const series = serializeData({ data: plotData, themeStatus });
    const options = serializeOptions({ themeStatus });

    const title = 'Cluster Duration Distribution';

    return (
        <WidgetWithTitle
            title={title}
            tooltip={tooltipArg}
            titleComponents={[<ExportDropdown key={'export'} title={title} subTitle={subTitle} />]}
        >
            <div className={classes.boxWrapper}>
                {plotData?.planned && (
                    <BoxChart series={series} options={options} noData={false} isFetching={false} height={430} />
                )}
                {!plotData?.planned && <p className={classes.text}>Waiting for data...</p>}
            </div>
        </WidgetWithTitle>
    );
};

export default WhiskerPlot;
