import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { useMutation } from 'react-query';

const mutationFn = (params: QueryParams) => {
    return network.post(API_URL().CREATE_CONTRACT, params).then((response) => {
        return response.data;
    });
};
export const useMutationCreateContract = () => {
    return useMutation<string, QueryError, QueryParams>({
        mutationFn: (params) => mutationFn(params),
    });
};

interface QueryParams {
    contractName: string;
    projectId: number;
}
