import { CreateCard } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/CreateCard/CreateCard';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useQueryGetCard } from 'api/queries/getMainCard.query';
import { UserGeneratedCard } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/UserGeneratedCard/UserGeneratedCard';
import { RecommendationCard } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/RecommendationCard/RecommendationCard';
import { ProgramActivityCard } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/ProgramActivityCard/ProgramActivityCard';
import { noop } from 'lodash';
import { IInitialCard } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/CreateCard/CreateCard.types';
import { useVersionStore } from 'store/version.store';
import { mainCardModalStyles } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/MainCard.utils';
import { CustomizedModalBase } from 'components/common';
import classes from './MainCard.module.scss';
import OverlayWithSpinner from 'components/common/OverlayWithSpinner/overlayWithSpinner';
import React, { useState } from 'react';
import { projectConfig } from 'components/Dashboards/Project/ProjectWrapper/projectConfig';

interface IMainCardProps {
    saveCardCallback?: (value?: number) => void;
    deleteCardCallback?: (value?: number) => void;
    initialCard?: IInitialCard;
    queryKeys?: string[];
}

export const MainCard = ({
    saveCardCallback = noop,
    deleteCardCallback = noop,
    initialCard,
    queryKeys = [],
}: IMainCardProps) => {
    const [showModal, setShowModal] = useState(true);
    const { pathname, search } = useLocation();
    const history = useHistory();

    const { cardId } = useParams<{ cardId: string }>();
    const currentVersion = useVersionStore((state) => state.version);

    const {
        data,
        refetch: refetchCardData,
        isLoading,
    } = useQueryGetCard({
        id: cardId,
        isVirtual: false,
        versionId: window.location.href.indexOf(projectConfig.pinboards.link) === -1 ? currentVersion?.id : undefined,
    });

    const handleModalClose = () => {
        setShowModal(false);
        const pathWithoutParams = pathname.slice(0, pathname.lastIndexOf('/'));

        history.push({
            pathname: pathWithoutParams,
            search: search,
        });
    };

    return (
        <CustomizedModalBase
            modalStyles={mainCardModalStyles}
            isModalOpen={showModal}
            handleModalClose={handleModalClose}
        >
            <div className={classes.mainCardContainer}>
                {isLoading && <OverlayWithSpinner />}
                {cardId === 'newCard' && <CreateCard saveCardCallback={saveCardCallback} initialCard={initialCard} />}
                {data?.type.name === 'USER_GENERATED' && (
                    <UserGeneratedCard
                        data={data}
                        refetchCardData={refetchCardData}
                        saveCardCallback={saveCardCallback}
                        deleteCardCallback={deleteCardCallback}
                        queryKeys={queryKeys}
                    />
                )}
                {data?.type.name === 'KC_INSIGHT' && (
                    <RecommendationCard
                        data={data}
                        refetchCardData={refetchCardData}
                        saveCardCallback={saveCardCallback}
                        deleteCardCallback={deleteCardCallback}
                        queryKeys={queryKeys}
                    />
                )}
                {data?.type.name === 'PROGRAMME_ACTIVITY' && (
                    <ProgramActivityCard
                        data={data}
                        queryKeys={queryKeys}
                        refetchCardData={refetchCardData}
                        saveCardCallback={saveCardCallback}
                    />
                )}
            </div>
        </CustomizedModalBase>
    );
};
