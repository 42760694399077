import { formatValueForGraph } from 'utilitys/helpers/general';
import colorsVars from 'styles/colors.module.scss';
import { TWhiskerPlotData } from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/WhiskerPlot/WhiskerPlot';
const { white, cardGraphFont, black } = colorsVars;

interface SerializeDataProps {
    data: TWhiskerPlotData | undefined;
    themeStatus: boolean;
}

export const serializeData = ({
    data,
    themeStatus,
}: SerializeDataProps): ApexAxisChartSeries | ApexNonAxisChartSeries | undefined => {
    if (!data) return [];

    return [
        {
            type: 'boxPlot',
            data: [
                {
                    x: 'Planned',
                    y: [
                        data.planned.min,
                        data.planned.firstQuartile,
                        data.planned.median,
                        data.planned.thirdQuartile,
                        data.planned.max,
                    ],
                },
                {
                    x: 'Completed',
                    y: [
                        data.actual.min,
                        data.actual.firstQuartile,
                        data.actual.median,
                        data.actual.thirdQuartile,
                        data.actual.max,
                    ],
                },
            ],
        },
        {
            name: 'outliers',
            type: 'scatter',
            data: [
                {
                    x: 'Planned',
                    y: data.planned.outliers,
                },
                {
                    x: 'Completed',
                    y: data.actual.outliers,
                },
            ],
            color: themeStatus ? white : 'transparent',
        },
    ];
};

interface SerializeOptionsProps {
    themeStatus: boolean;
}

export const serializeOptions = ({ themeStatus }: SerializeOptionsProps): ApexCharts.ApexOptions => {
    return {
        chart: {
            background: themeStatus ? black : white,
            foreColor: cardGraphFont,
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: false,
            },
        },
        stroke: {
            colors: [themeStatus ? white : cardGraphFont],
        },
        plotOptions: {
            bar: {
                columnWidth: 60,
            },
            boxPlot: {
                colors: {
                    upper: '#36b5d8',
                    lower: '#8bd4e9',
                },
            },
        },
        xaxis: {
            type: 'category',
            labels: {
                style: {
                    fontSize: '1.6rem',
                    colors: themeStatus ? white : black,
                    fontWeight: 500,
                },
            },
        },
        yaxis: {
            labels: {
                style: {
                    fontSize: '1.2rem',
                },
                formatter: (value) => {
                    return formatValueForGraph(value);
                },
            },
            title: {
                text: 'Time (days)',
                style: {
                    fontSize: '1.4rem',
                    fontWeight: 400,
                },
            },
        },
        tooltip: {
            theme: themeStatus ? 'dark' : 'light',
        },
        legend: {
            show: false,
        },
        markers: {
            size: 3,
        },
    };
};
