import { QueryParams, useQueryGetCommonResources } from 'api/queries/getCommonResources.query';
import { sortAlphabeticalArray } from 'utilitys/helpers/general';
import { useUserStore } from 'store/user.store';
import { useProjectStore } from 'store/project.store';

const commonResourcesKey: QueryParams['objectsList'] = ['users'];

const assigneeMapper = (i) => ({
    id: null,
    value: i.userName,
    label: `${i.lastName.length > 0 ? `${i.firstName} ${i.lastName}` : i.firstName}`,
    assignee: { ...i, id: null },
});

const currentAssigneeMapper = (i) => ({
    id: null,
    value: i.userName,
    label: `${i.lastName.length > 0 ? `${i.firstName} ${i.lastName}` : i.firstName} (Current User)`,
    assignee: { ...i, id: null },
});

export interface IAssigneeOptionsList {
    id: number | null;
    value: string;
    label: string;
    assignee: {
        userName: string;
        firstName: string;
        lastName: string;
        imageName: string;
        id: number | null;
    };
}

const unAssignedOption = {
    id: null,
    value: 'Unassigned',
    label: 'Unassigned',
    assignee: {
        id: null,
        userName: 'Unassigned',
        firstName: 'Unassigned',
        lastName: '',
        imageName: '',
    },
};

export const useAssigneeOptionsList = (): IAssigneeOptionsList[] => {
    const user = useUserStore((state) => state.user);
    const {
        selectedProject: { project },
    } = useProjectStore();
    const { data } = useQueryGetCommonResources({ objectsList: commonResourcesKey, projectId: project?.id });

    const currentUser = data?.users?.find((i) => i.userName === user?.username);

    const defaultAssigneeOptions = currentUser
        ? [currentAssigneeMapper(currentUser), unAssignedOption]
        : [unAssignedOption];

    return data?.users
        ? [...defaultAssigneeOptions, ...sortAlphabeticalArray(data.users.map(assigneeMapper), 'label', 'asc')]
        : [];
};
