import { useEffect, useMemo } from 'react';
import classes from 'components/Dashboards/Portfolio/phasePerformance/components/filtersWrapper.module.scss';
import { TopHeader } from 'components/Dashboards/Portfolio/phasePerformance/topHeader';
import { first, orderBy, sortBy } from 'lodash';
import {
    getName,
    projectsMapper,
    statusOptions,
    templateMapper,
} from 'components/Dashboards/Portfolio/phasePerformance/phasePerformance.utils';

import { MyCustomSelect } from 'components/common';
import { InputOption, ValueContainer } from 'components/common/MyCustomSelect/myCustomSelect';
import {
    IPhasePerformanceProject,
    IProjectStatus,
} from 'components/Dashboards/Portfolio/phasePerformance/phasePerformance.types';
import { usePhasePerformanceStore } from 'store/phasePerformance.store';
import {
    useQueryPhasePerformanceGetTemplateRelatedProjects,
    useQueryPhasePerformanceGetTemplates,
} from 'components/Dashboards/Portfolio/phasePerformance/queries/useQueryPhasePerformance';
import { generatePath, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useBasePath } from 'hooks/useBasePath';
import useUserHook from 'hooks/useUserHook';
import { useQueryGetStoredTags } from 'api/queries/getStoredTags.query';
import { useProjectStore } from 'store/project.store';

export const FiltersWrapper = () => {
    const history = useHistory();
    const { path } = useRouteMatch();
    const basePath = useBasePath();
    const { customerId } = useUserHook();
    const template = usePhasePerformanceStore((store) => store.template);
    const { templateId: templateIdFromParams } = useParams<{ templateId?: string }>();
    const { data: templatesData } = useQueryPhasePerformanceGetTemplates();
    const { data: projectsData } = useQueryPhasePerformanceGetTemplateRelatedProjects({ templateId: template?.id });
    const selectedProjects = usePhasePerformanceStore((store) => store.selectedProjects);
    const projectsOptions = usePhasePerformanceStore((store) => store.projectsOptions);
    const gatesOptions = usePhasePerformanceStore((store) => store.gatesOptions);
    const selectedTags = usePhasePerformanceStore((store) => store.selectedTags);
    const selectedGates = usePhasePerformanceStore((store) => store.selectedGates);
    const selectedStatus = usePhasePerformanceStore((store) => store.selectedStatus);
    const { update } = usePhasePerformanceStore();

    const { project } = useProjectStore((store) => store.selectedProject);
    const { data: tagsData } = useQueryGetStoredTags({ enabled: true, customerId, projectId: project?.id });

    const handleSelectedProjects = (item: readonly ISelectOption<number>[]) => {
        update({
            selectedProjects: projectsOptions.filter((project) =>
                item.map((item) => item.value).includes(project.contract_id),
            ),
        });
    };

    const handleSelectTemplate = (item?: ISelectOption<number> | null) => {
        item ? history.push({ pathname: generatePath(path, { templateId: item.value }) }) : history.push(basePath);
    };

    const tagsOptions =
        tagsData?.map((item) => {
            return {
                id: item.tagId,
                label: item.name,
                value: item.tagId,
            };
        }) || [];

    useEffect(() => {
        if (templatesData) {
            const sortedTemplates = sortBy(templatesData.templates, 'name').map(templateMapper);
            const selectedTemplate = templateIdFromParams
                ? sortedTemplates.find((item) => item.value === Number(templateIdFromParams))
                : first(sortedTemplates);

            update({ template: selectedTemplate || null });
            handleSelectTemplate(selectedTemplate);
        }
    }, [templatesData, templateIdFromParams]);

    useEffect(() => {
        if (projectsData && projectsData.length > 0) {
            const projects = orderBy(projectsData, 'project_name').map(
                (project): IPhasePerformanceProject => ({
                    contract_id: project.contract_id,
                    name: getName(project),
                }),
            );
            const gates = projectsData[0].phases.map((item): ISelectOption<number> => {
                return {
                    id: item.id,
                    value: item.sequence,
                    label: `${item.start_gate} → ${item.finish_gate}`,
                };
            });
            update({ selectedProjects: projects, projectsOptions: projects, gatesOptions: gates });
        } else {
            update({ selectedProjects: [], projectsOptions: [], gatesOptions: [] });
        }
    }, [projectsData]);

    const options = useMemo(() => {
        if (templatesData && templatesData.templates) {
            return orderBy(
                templatesData.templates.map(templateMapper),
                [(item: any) => item['label'].toLowerCase()],
                ['asc'],
            );
        }
        return [];
    }, [templatesData]);

    return (
        <div className={classes.filtersWrapper}>
            <TopHeader
                template={template}
                templateOptions={options}
                info={templatesData?.info}
                handleSelectTemplate={handleSelectTemplate}
            />
            <div className={classes.filters}>
                Filters:
                <MyCustomSelect<ISelectOption<number>, true>
                    value={selectedProjects.map(projectsMapper)}
                    options={projectsOptions.map(projectsMapper)}
                    onChange={(value) => handleSelectedProjects(value)}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    isMulti={true}
                    id={'phase_performance_select_projects'}
                    placeholder={'Select Projects...'}
                    components={{
                        Option: InputOption,
                        ValueContainer: ValueContainer,
                    }}
                />
                <MyCustomSelect<ISelectOption<IProjectStatus>, true>
                    value={selectedStatus}
                    options={statusOptions}
                    onChange={(value) => update({ selectedStatus: value })}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    isMulti={true}
                    id={'phase_performance_select_status'}
                    placeholder={'Select Status...'}
                    components={{
                        Option: InputOption,
                        ValueContainer: ValueContainer,
                    }}
                />
                <MyCustomSelect<ISelectOption<number>, true>
                    value={selectedTags}
                    options={tagsOptions}
                    onChange={(value) => update({ selectedTags: value })}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={true}
                    isMulti={true}
                    id={'phase_performance_select_tags'}
                    placeholder={'Select Tags...'}
                />
                <MyCustomSelect<ISelectOption<number>>
                    value={selectedGates}
                    options={gatesOptions}
                    onChange={(value) => update({ selectedGates: value })}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    id={'phase_performance_select_phase'}
                    placeholder={'Select Phase...'}
                    isClearable={true}
                />
            </div>
        </div>
    );
};
