import colorsVars from 'styles/colors.module.scss';
import { ISerializeHeatmapReturn } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramCriticalAnalysis/components/CriticalAnalysisHeatMapComponent/RenderCriticalAnalysisHeatMap.utils';

const calcValue = (value: number) => Math.ceil((value * 1000) / 10);

const tooltip = ({ tooltipLabel, numOfDelayDrivers, numOfTasksPerMonth, value }) => {
    return `<div style="display: flex; flex-direction: column; gap: 4px">
                <div>${tooltipLabel}</div>
                <div>Total Score: ${value}</div>
                <div>${numOfTasksPerMonth === null ? '0' : numOfTasksPerMonth} tasks</div> 
                <div>${numOfDelayDrivers === null ? '0' : numOfDelayDrivers} delay drivers</div>
             </div>   `;
};

const colors = [
    colorsVars.color1,
    colorsVars.color2,
    colorsVars.color3,
    colorsVars.color4,
    colorsVars.color5,
    colorsVars.color6,
    colorsVars.color7,
    colorsVars.color8,
    colorsVars.color9,
    colorsVars.color10,
];

export const serializeData = (data: ISerializeHeatmapReturn[]) => {
    return [
        {
            name: 'rowid',
            data: data.map((item) => {
                return {
                    x: item.label,
                    y: calcValue(item.value),
                };
            }),
        },
    ];
};

interface SerializeOptionsProps {
    data: ISerializeHeatmapReturn[];
    themeStatus: boolean;
    handleClick: (value: string) => void;
    isWeekResolution: boolean;
}

const getCategories = (data: ISerializeHeatmapReturn[], isWeekResolution: boolean) => {
    if (isWeekResolution) {
        return data.map((item) => {
            const day = item.label.split(' ')[0];
            const month = item.label.split(' ')[1];
            const year = item.label.split(' ')[2];
            return [day, month, year];
        });
    }

    return data.map((item) => {
        const month = item.label.split(' ')[0];
        const year = item.label.split(' ')[1];
        return [month, year];
    });
};

const getTickAmount = (dataLength: number) => {
    const num = dataLength / 20;
    return dataLength / num;
};

export const serializeOptions = ({
    data,
    themeStatus,
    handleClick,
    isWeekResolution,
}: SerializeOptionsProps): ApexCharts.ApexOptions => {
    const categories = getCategories(data, isWeekResolution);

    return {
        chart: {
            type: 'heatmap',
            background: themeStatus ? '#000000' : '#ffffff',
            foreColor: themeStatus ? '#FEFEFE' : '#000000',
            toolbar: {
                show: false,
            },
            events: {
                dataPointSelection: (event, chartContext, { dataPointIndex }) => {
                    handleClick(data[dataPointIndex].id);
                },
            },
        },
        xaxis: {
            type: 'category',
            categories: categories,
            tickAmount: getTickAmount(data.length),
            labels: {
                rotate: 0,
            },
            tooltip: {
                enabled: false,
            },
        },
        yaxis: {
            show: false,
        },
        stroke: {
            width: 0.5,
        },
        plotOptions: {
            heatmap: {
                enableShades: false,
                colorScale: {
                    ranges: [
                        {
                            color: colors[0],
                            from: 0,
                            to: 10,
                        },
                        {
                            color: colors[1],
                            from: 11,
                            to: 20,
                        },
                        {
                            color: colors[2],
                            from: 21,
                            to: 30,
                        },
                        {
                            color: colors[3],
                            from: 31,
                            to: 40,
                        },
                        {
                            color: colors[4],
                            from: 41,
                            to: 50,
                        },
                        {
                            color: colors[5],
                            from: 51,
                            to: 60,
                        },
                        {
                            color: colors[6],
                            from: 61,
                            to: 70,
                        },
                        {
                            color: colors[7],
                            from: 71,
                            to: 80,
                        },
                        {
                            color: colors[8],
                            from: 81,
                            to: 90,
                        },
                        {
                            color: colors[9],
                            from: 91,
                            to: 100,
                        },
                    ],
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
        tooltip: {
            theme: themeStatus ? 'dark' : 'light',
            y: {
                title: {
                    formatter: () => '',
                },
                formatter: function (value, { dataPointIndex }) {
                    return tooltip({
                        tooltipLabel: data[dataPointIndex].tooltipLabel,
                        numOfDelayDrivers: data[dataPointIndex].numOfDelayDrivers,
                        numOfTasksPerMonth: data[dataPointIndex].numOfTasksPerMonth,
                        value: value,
                    });
                },
            },
        },
    };
};
