import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import React, { useEffect } from 'react';
import classes from './widgetCardsWrapper.module.scss';
import OverlayWithSpinner from 'components/common/OverlayWithSpinner/overlayWithSpinner';
import { ExportDropdown } from 'components/common/ExportDropdown/exportDropdown';
import { IWidgetCardsWrapperProps } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import { AddWidget } from 'components/Dashboards/Project/Components/CustomDashboard/AddRemoveWidget/addWidget';
import { WidgetFilters } from 'components/Dashboards/Project/Components/CustomDashboard/WidgetFilters/widgetFilters';
import { useGlobalFiltersHook } from 'hooks/useGlobalFiltersHook';
import useCustomPageSubTitle from 'hooks/useCustomPageSubTitle';
import { ShareInsightButton } from 'components/common/ShareInsightButton/ShareInsightButton';
import { getStringWithoutHash } from 'utilitys/helpers/general';

export const WidgetCardsWrapper = ({
    projectId,
    contractId,
    widgetId,
    filter,
    title,
    componentKey,
    tooltipText,
    children,
    isLoading = false,
    id,
    latestVersionId,
    externalComponents = null,
    editNarrative = null,
    setSubTitleForExport,
}: IWidgetCardsWrapperProps) => {
    const { generateFilters: formatedFilter } = useGlobalFiltersHook({ widgetId, filter, latestVersionId });
    const route = `${getStringWithoutHash(window.location.href)}#${id}`;
    const subTitle = useCustomPageSubTitle({ projectId, contractId, latestVersionId });

    useEffect(() => {
        setSubTitleForExport && setSubTitleForExport({ widgetId, subTitle, title });
    }, [widgetId, subTitle, title]);
    return (
        <WidgetWithTitle
            title={title}
            tooltip={tooltipText}
            id={id}
            titleComponents={[
                externalComponents && <div key={'externalComponents'}>{externalComponents}</div>,
                <AddWidget
                    key={'AddWidget'}
                    title={title}
                    componentKey={componentKey}
                    projectId={projectId}
                    contractId={contractId}
                    widgetId={widgetId}
                    route={route}
                    filters={filter?.filters}
                />,
                <ShareInsightButton key={'shareInsight'} title={title} link={route} />,
                <ExportDropdown title={title} key={'export'} subTitle={subTitle} />,
            ]}
            titleFilters={[
                editNarrative && <div key={'editNarrative'}>{editNarrative}</div>,
                widgetId && formatedFilter.length > 0 ? (
                    <WidgetFilters key={'widgetFilters'} widgetId={widgetId} filters={formatedFilter} />
                ) : null,
            ]}
        >
            <div className={classes.widgetCardsWrapper}>{isLoading ? <OverlayWithSpinner /> : children}</div>
        </WidgetWithTitle>
    );
};
