import classes from './LatestUpdateCell.module.scss';

export const LatestUpdateCell = ({ lastVersionDate, baselineVersionDate }: Props) => {
    return (
        <div className={classes.latestUpdateContainer}>
            <div>
                <span>{`${lastVersionDate}`}</span>
            </div>
            <div className={classes.baselineRow}>
                <span>Baseline: </span> <span>{`${baselineVersionDate}`}</span>
            </div>
        </div>
    );
};

interface Props {
    lastVersionDate: string;
    baselineVersionDate: string;
}
