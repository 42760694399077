import { useThemeStatusStore } from 'store/themeStatus.store';
import classes from './criticalAnalysisHeatMap.module.scss';
import { ISerializeHeatmapReturn } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramCriticalAnalysis/components/CriticalAnalysisHeatMapComponent/RenderCriticalAnalysisHeatMap.utils';
import {
    serializeData,
    serializeOptions,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/CommonComponents/CriticalAnalysisHeatMap/criticalAnalysisHeatMap.utils';
import { HeatmapChart } from 'components/common/charts/heatmapChart/heatmapChart';

interface Props {
    data: ISerializeHeatmapReturn[];
    handleClick: (value: string) => void;
    resolutionValue: ISelectOption<boolean>;
    noData: boolean;
    isFetching: boolean;
}

const CriticalAnalysisHeatMap = ({ data, handleClick, resolutionValue, noData, isFetching }: Props) => {
    const { themeStatus } = useThemeStatusStore();

    const series = serializeData(data);
    const options = serializeOptions({
        data,
        themeStatus,
        handleClick,
        isWeekResolution: resolutionValue.value,
    });

    return (
        <div className={classes.criticalAnalysisHeatMapContainer}>
            <HeatmapChart series={series} options={options} noData={noData} isFetching={isFetching} height={180} />
        </div>
    );
};

export default CriticalAnalysisHeatMap;
