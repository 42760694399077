import classes from 'components/common/charts/boxChart/boxChart.module.scss';
import OverlayWithSpinner from 'components/common/OverlayWithSpinner/overlayWithSpinner';
import NoData from 'components/common/NoData/noData';
import Chart from 'react-apexcharts';
import { memo } from 'react';

interface Props {
    options: ApexCharts.ApexOptions;
    series: ApexAxisChartSeries | ApexNonAxisChartSeries | undefined;
    isFetching?: boolean;
    noData?: boolean;
    height?: number;
    className?: string;
}

export const BoxChart = memo(
    ({ isFetching = false, noData = false, options, series, height = 600, className = '' }: Props) => {
        return (
            <div className={`${classes.container} ${className}`} data-testid={'box-chart-container'}>
                {isFetching && <OverlayWithSpinner />}
                {noData && <NoData />}
                {!noData && <Chart options={options} series={series} type="boxPlot" height={height} />}
            </div>
        );
    },
);
