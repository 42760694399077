import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import { CustomizedRoundIcon, CustomizedTooltip } from 'components/common';
import React, { useEffect, useMemo, useState } from 'react';
import { ExportDropdown } from 'components/common/ExportDropdown/exportDropdown';
import { ITableWidgetWrapperProps } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import {
    FilterNames,
    getActiveFilterCount,
    getFiltersData,
} from 'components/common/GlobalFilterComponent/GlobalFilterComponent.utils';
import { AddWidget } from 'components/Dashboards/Project/Components/CustomDashboard/AddRemoveWidget/addWidget';
import { WidgetFilters } from 'components/Dashboards/Project/Components/CustomDashboard/WidgetFilters/widgetFilters';
import NoData from 'components/common/NoData/noData';

import { useGlobalFiltersHook } from 'hooks/useGlobalFiltersHook';
import useCustomPageSubTitle from 'hooks/useCustomPageSubTitle';
import { columnsV8, initialState, serializeColumnsVisibility } from 'components/Dashboards/widgets/keyActivities/utils';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import Icon from 'components/common/Icons/icon';
import { CustomTableColumnsModal } from 'components/common/Modals/CustomTableColumns/customTableColumnsModal';
import useTableCurrentState from 'hooks/useTableCurrentState';
import { ShareInsightButton } from 'components/common/ShareInsightButton/ShareInsightButton';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { useMutationSetWidgetColumns } from 'components/Dashboards/Project/Components/CustomDashboard/queries/useQuerySelectedTableColumns';
import { Link } from 'react-router-dom';
import { projectConfig } from 'components/Dashboards/Project/ProjectWrapper/projectConfig';
import {
    useQueryGetKeyActivities,
    useQueryGetKeyActivitiesTags,
} from 'components/Dashboards/widgets/keyActivities/queries/useQueryKeyActivities';
import { Filter } from 'components/Dashboards/widgets/keyActivities/components/filter';
import classes from 'components/Dashboards/widgets/keyActivities/keyActivities.module.scss';
import { useForm, useWatch } from 'react-hook-form';
import {
    statusOptions,
    getNextDateCalculation,
} from 'components/Dashboards/widgets/keyActivities/components/filter.utils';
import { useGlobalFilterDataStore } from 'store/globalFilterData.store';
import { TableV8 } from 'components/common/Tables/TableV8/tableV8';
import { useReactTableState } from 'hooks/useReactTableState';
import { getStringWithoutHash } from 'utilitys/helpers/general';

const KeyActivitiesWidget = ({
    projectId,
    contractId,
    widgetId,
    latestVersionId,
    compareVersionId,
    filter,
    globalFilterData,
    externalComponents = null,
    editNarrative = null,
    setSubTitleForExport,
    localFilters,
}: ITableWidgetWrapperProps) => {
    const { handleSnackBar } = useCustomSnackBar();
    const elementId = 'key-activities';
    const id = 'key-activities';
    const route = `${getStringWithoutHash(window.location.href)}#${elementId}`;
    const subTitle = useCustomPageSubTitle({ projectId, contractId, latestVersionId });
    const componentKey = 'keyActivities';
    const updatedInitialState = useTableCurrentState({ initialState, componentKey, widgetId });
    const defaultValues = useGlobalFilterDataStore((store) => store.defaultValues);
    const activeFilterCount = getActiveFilterCount(globalFilterData, Object.keys(defaultValues));
    const isGlobalFilterEmpty = activeFilterCount === 0;
    const [modalColumnsOpen, setModalColumnsOpen] = useState<boolean>(false);
    const { data: tagsFromBe } = useQueryGetKeyActivitiesTags({
        contractId,
    });

    const { data, isLoading, isFetching } = useQueryGetKeyActivities({
        contractId: contractId,
        latestVersionId: latestVersionId,
        compareVersionId: compareVersionId,
        filterData: {
            filters: [],
        },
        tagIdList: widgetId
            ? localFilters?.tags?.map((item) => item.value) || []
            : tagsFromBe?.map((item) => item.tagId) || [],
    });

    const activeData = useMemo(() => {
        if (data) {
            const current = data.periodItemsList.find((item) => item.id === 'prev');
            return current ? current.itemsList : [];
        }
        return [];
    }, [data]);

    const { mutate: mutateSetWidgetColumns } = useMutationSetWidgetColumns();
    const title = 'Key Activities';

    const setWidgetColumns = (widgetId: string) => {
        if (tableInstance) {
            mutateSetWidgetColumns(
                { columns: { hiddenColumns: tableInstance.state.hiddenColumns, columnOrder }, widgetId },
                {
                    onError: (error) => {
                        handleSnackBar(error?.response?.data || 'Something went wrong ', 'error');
                    },
                },
            );
        }
    };
    useEffect(() => {
        setSubTitleForExport && setSubTitleForExport({ widgetId, subTitle, title });
    }, [widgetId, subTitle, title]);

    const { control, setValue } = useForm<{
        name: string;
        status: ISelectOption<string>[];
        moreFilter: ISelectOption<string>[];
        nextDate: ISelectOption<string>;
        tags: ISelectOption<number>[];
    }>({
        defaultValues: {
            tags: [],
            name: '',
            status: statusOptions.filter((item) => item.id !== 3),
            moreFilter: [],
            nextDate: {
                label: 'ALL',
                value: '-1',
            },
        },
    });

    const formValues = useWatch({ control });

    const filteredData = useMemo(() => {
        const { name, status, moreFilter, nextDate } = widgetId ? localFilters : formValues;
        const moreFiltersArray = moreFilter.map((item) => item.value);
        const statusArray = status?.map((item) => item.value);

        if (activeData && isGlobalFilterEmpty) {
            return activeData
                .filter((item) => statusArray?.includes(item.latestVersion.cardBoardViewResponse.status.name))
                .filter((item) =>
                    item.latestVersion.cardBoardViewResponse.title.toLowerCase().includes(name.toLowerCase()),
                )
                .filter((item) =>
                    moreFiltersArray.includes('LateFinishDate') ? item.latestVersion.finishVariance > 0 : true,
                )
                .filter((item) =>
                    moreFiltersArray.includes('LateStartDate') ? item.latestVersion.startVariance > 0 : true,
                )
                .filter((item) =>
                    moreFiltersArray.includes('CriticalPath')
                        ? item.latestVersion.cardBoardViewResponse.cardIndicators.isCriticalPath.isIndicatorOn
                        : true,
                )
                .filter((item) =>
                    moreFiltersArray.includes('Milestones')
                        ? item.latestVersion.cardBoardViewResponse.taskActivityType === 'Milestone'
                        : true,
                )
                .filter((item) =>
                    nextDate.value !== '-1'
                        ? getNextDateCalculation(
                              nextDate.value,
                              item.latestVersion.startDate,
                              item.latestVersion.finishDate,
                          )
                        : true,
                );
        }

        return [];
    }, [activeData, formValues, localFilters, widgetId, isGlobalFilterEmpty]);

    const formattedLocalFilters = useMemo(() => {
        return widgetId && localFilters
            ? getFiltersData({
                  Tags: localFilters?.tags?.map((item) => item.label),
                  Name: localFilters?.name,
                  [FilterNames.OPTIONS_STATUS]: localFilters?.status?.map((item) => item.label) || [],
                  'More Filters': localFilters?.moreFilter?.map((item) => item.label) || [],
                  [FilterNames.OVERRUN_RISK_SELECTED_PERIOD]: localFilters?.nextDate?.label,
              })
            : [];
    }, [widgetId, localFilters]);

    const mergedFilters = useMemo(() => {
        return widgetId && filter?.filters
            ? {
                  filters: [...filter?.filters, ...formattedLocalFilters],
              }
            : undefined;
    }, [widgetId, filter, formattedLocalFilters]);

    const { generateFilters: formattedFilter } = useGlobalFiltersHook({
        widgetId,
        filter: mergedFilters,
        latestVersionId,
    });

    const noDataText = !isGlobalFilterEmpty ? (
        <NoData>Not applicable with the applied filter</NoData>
    ) : tagsFromBe?.length === 0 ? (
        <NoData>
            Select existing tag from list, or open{' '}
            <Link to={`/dashboard/project/${projectConfig.battlecards.link}`}>{projectConfig.battlecards.title}</Link>{' '}
            to define a tag which identifies the key activities.
        </NoData>
    ) : undefined;

    const {
        sorting,
        setSorting,
        columnFilters,
        setColumnFilters,
        columnVisibility,
        setColumnVisibility,
        columnOrder,
        setColumnOrder,
    } = useReactTableState();

    useEffect(() => {
        updatedInitialState && setColumnOrder(updatedInitialState.columnOrder ?? []);
        updatedInitialState && setSorting(updatedInitialState.sortBy ?? []);
        updatedInitialState &&
            setColumnVisibility(
                serializeColumnsVisibility({ columns: columnsV8, hiddenColumns: updatedInitialState.hiddenColumns }) ??
                    {},
            );
    }, [updatedInitialState]);

    const tableInstance = useMemo(() => {
        const hiddenColumns = Object.keys(columnVisibility).reduce((acc: string[], item: string) => {
            columnVisibility[item] === false && acc.push(item);
            return acc;
        }, []);

        return {
            setColumnOrder,
            setHiddenColumns: (hiddenColumns) =>
                setColumnVisibility(serializeColumnsVisibility({ columns: columnsV8, hiddenColumns })),
            state: {
                hiddenColumns,
            },
            allColumns: columnOrder
                .map((item) => {
                    const column = columnsV8.find((column) => column.id === item);
                    if (!column?.id) {
                        return null;
                    }

                    return {
                        ...column,
                        isVisible: !hiddenColumns.includes(column.id),
                    };
                })
                .filter((x) => x),
        };
    }, [columnVisibility, columnOrder]);

    const tableInstanceForExport = useMemo(() => {
        const columns = columnsV8.map((item) => ({
            id: item.id,
            Header: typeof item.header === 'string' ? item.header : item.meta?.columnLabel,
        }));

        const visibleColumnsOnly = columns.filter((item) => {
            return item.id && columnVisibility[item.id];
        });

        return {
            visibleColumns: visibleColumnsOnly,
            sortedRows: filteredData.map((item) => ({
                values: {
                    ...item.latestVersion,
                    activity: item.latestVersion.activityName,
                    activityType: item.latestVersion.cardBoardViewResponse.taskActivityType,
                    criticalPath: item.latestVersion?.cardBoardViewResponse?.cardIndicators.isCriticalPath.isIndicatorOn
                        ? 'Yes'
                        : 'No',
                    projectWeekFinish: item.latestVersion.cardBoardViewResponse.projectWeekFinish,
                    status: item.latestVersion.cardBoardViewResponse.status.label,
                    submittedDurationComplete: item.latestVersion.cardBoardViewResponse.submittedDurationComplete,
                },
            })),
        };
    }, [filteredData, columnVisibility]);

    return (
        <WidgetWithTitle
            title={`${title} (${filteredData.length})`}
            tooltip={data?.info}
            id={id}
            titleFilters={[
                editNarrative && <div key={'editNarrative'}>{editNarrative}</div>,
                widgetId && formattedFilter.length > 0 ? (
                    <WidgetFilters key={'widgetFilters'} widgetId={widgetId} filters={formattedFilter} />
                ) : (
                    <Filter key={'filters'} contractId={contractId} control={control} setValue={setValue} />
                ),
            ]}
            titleComponents={[
                externalComponents && <div key={'externalComponents'}>{externalComponents}</div>,
                <CustomizedTooltip
                    key={'tableColumns'}
                    tooltipPosition={TooltipPosition.Top}
                    tooltipContent={'Customize table columns'}
                    triggerElement={
                        <CustomizedRoundIcon enableHover={true} onClick={() => setModalColumnsOpen(true)}>
                            <Icon name={'tune-filter'} />
                        </CustomizedRoundIcon>
                    }
                />,
                <AddWidget
                    key={'AddWidget'}
                    componentKey={componentKey}
                    title={title}
                    projectId={projectId}
                    contractId={contractId}
                    elementId={elementId}
                    widgetId={widgetId}
                    route={route}
                    localFilters={formValues}
                    callBack={setWidgetColumns}
                />,
                <ShareInsightButton key={'shareInsight'} title={title} link={route} />,
                <ExportDropdown
                    title={title}
                    key={'export'}
                    subTitle={subTitle}
                    data={filteredData}
                    tableInstance={tableInstanceForExport}
                />,
            ]}
        >
            {updatedInitialState && (
                <div data-testid={'key-activities-container'} className={classes.container}>
                    <TableV8
                        data={filteredData}
                        columns={columnsV8}
                        noData={noDataText}
                        isLoading={isLoading || isFetching}
                        sorting={sorting}
                        setSorting={setSorting}
                        columnFilters={columnFilters}
                        setColumnFilters={setColumnFilters}
                        columnVisibility={columnVisibility}
                        setColumnVisibility={setColumnVisibility}
                        columnOrder={columnOrder}
                        setColumnOrder={setColumnOrder}
                        manualSorting={false}
                    />
                </div>
            )}

            <CustomTableColumnsModal
                onHideModal={() => setModalColumnsOpen(false)}
                showModal={modalColumnsOpen}
                widgetId={widgetId}
                componentKey={componentKey}
                tableInstance={tableInstance}
                initialState={initialState}
                columns={columnsV8}
                onChangeOrder={setColumnOrder}
                onChangeVisibility={setColumnVisibility}
            />
        </WidgetWithTitle>
    );
};
export default React.memo(KeyActivitiesWidget);
