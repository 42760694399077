import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { useQuery } from 'react-query';
import { QueryKeys } from 'api/queryKeys';
import qs from 'qs';

const queryFn = ({ projectId }: QueryParams) => {
    const querystring = qs.stringify({ projectId }, { addQueryPrefix: true, skipNulls: true });
    const url = `${API_URL().FETCH_PROGRAM_GET_SUB_PROGRAM_GET_TAG_LIST_INIT}${querystring}`;
    return network.get(url).then((response) => {
        return response.data;
    });
};

export const useQueryGetTagListInit = (params: QueryParams) => {
    return useQuery<QueryResponse, QueryError, QueryResponse, [QueryKeys]>({
        queryKey: ['getTagListInit'],
        queryFn: () => queryFn(params),
        enabled: Boolean(params.projectId),
    });
};

interface QueryParams {
    projectId?: number;
}

interface QueryResponse {
    tags: { id: number; name: string }[];
}
