import classes from 'components/Dashboards/ExcellenceApps/LogicChecker/components/copyQualitySettingsModal.module.scss';
import {
    ConfirmSaveModal,
    CustomizedButton,
    CustomizedFormErrorMessage,
    CustomizedModalBase,
    MyCustomSelect,
} from 'components/common';
import { Controller, useForm } from 'react-hook-form';
import { shortMenuListStyle } from 'components/common/MyCustomSelect/myCustomSelect.style';
import { useProjectStore } from 'store/project.store';
import { useMutationCopyQualitySettings } from 'components/Dashboards/ExcellenceApps/LogicChecker/queries/useQueryLogicChecker';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { HeaderBreadcrumbs } from 'components/common/HeaderBreadcrumbs/HeaderBreadcrumbs';
import React, { useState } from 'react';
import { MenuListSelectAll, ValueContainer } from 'components/common/MyCustomSelect/myCustomSelect';
import {
    QueryResponse,
    useQueryGetProgramGeneralHierarchyList,
} from 'api/queries/getProgramGeneralHierarchyList.query';

interface ICopyQualitySettingsForm {
    contracts: ISelectOption<number>[];
}

const getAllContracts = (projectHierarchyList?: QueryResponse) => {
    const options: ISelectOption<number>[] = [];
    if (!projectHierarchyList) return options;
    projectHierarchyList.children.forEach((project) => {
        project.children.forEach((contract) => {
            options.push({
                label: `${project.name} > ${contract.name}`,
                value: contract.id,
            });
        });
    });
    return options;
};

export const CopyQualitySettingsModal = ({ showModal, onHideModal }) => {
    const [confirmModal, setConfirmModal] = useState<boolean>(false);
    const { handleSnackBar } = useCustomSnackBar();
    const { mutate, isLoading } = useMutationCopyQualitySettings();
    const { data: projectHierarchyList } = useQueryGetProgramGeneralHierarchyList('TreeProgramFilter');
    const {
        selectedProject: { contract, project },
    } = useProjectStore();

    const projectsOptions = getAllContracts(projectHierarchyList);

    const {
        watch,
        handleSubmit,
        control,
        formState: { errors },
        reset,
    } = useForm<ICopyQualitySettingsForm>({
        defaultValues: {
            contracts: [],
        },
    });

    const closeModal = () => {
        onHideModal();
        reset();
    };

    const handleSave = () => {
        if (!contract) return false;
        const value = watch();
        const payload = { fromContractId: contract.id, toContractIds: value.contracts.map((item) => item.value) };
        mutate(payload, {
            onSuccess: () => {
                handleSnackBar('Successfully saved!', 'success');
            },
            onError: (error) => {
                handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
            },
            onSettled: () => {
                closeModal();
            },
        });
    };

    return (
        <>
            <CustomizedModalBase isModalOpen={showModal} handleModalClose={closeModal}>
                <div className={classes.mainContainer}>
                    <form onSubmit={handleSubmit(() => setConfirmModal(true))} autoComplete="off">
                        <div className={classes.title}>Copy Quality Settings</div>
                        <div className={classes.subTitle}>
                            Copy the Quality Settings of the current program to other programs
                        </div>

                        <div className={classes.content}>
                            <div className={classes.selectContainer}>
                                <div className={classes.selectTitle}>Current:</div>
                                <div className={`${classes.selectWrapper} ${classes.projectWrapper}`}>
                                    <HeaderBreadcrumbs>
                                        <div className={classes.project}>{project?.title}</div>
                                        <div className={classes.project}>{contract?.title}</div>
                                    </HeaderBreadcrumbs>
                                </div>
                            </div>
                            <div className={classes.selectContainer}>
                                <div className={classes.selectTitle}>Copy to:</div>
                                <div className={classes.selectWrapper}>
                                    <Controller
                                        render={({ field }) => (
                                            <MyCustomSelect<ISelectOption<number>, true>
                                                {...field}
                                                options={projectsOptions}
                                                isClearable={true}
                                                isMulti
                                                styles={shortMenuListStyle}
                                                components={{
                                                    MenuList: MenuListSelectAll,
                                                    ValueContainer: ValueContainer,
                                                }}
                                            />
                                        )}
                                        name={'contracts'}
                                        control={control}
                                        rules={{
                                            validate: {
                                                required: (value) =>
                                                    value.length > 0 || 'Must select at least one contract',
                                            },
                                        }}
                                    />
                                    {errors.contracts && <CustomizedFormErrorMessage text={errors.contracts.message} />}
                                </div>
                            </div>
                        </div>

                        <div className={classes.footer}>
                            <div className={classes.container}>
                                <CustomizedButton size={'large'} color={'secondary'} onClick={closeModal}>
                                    Cancel
                                </CustomizedButton>

                                <CustomizedButton type={'submit'} size={'large'} color={'primary'}>
                                    Copy
                                </CustomizedButton>
                            </div>
                        </div>
                    </form>
                </div>
            </CustomizedModalBase>
            <ConfirmSaveModal
                isShowModal={confirmModal}
                onHideModal={() => setConfirmModal(false)}
                onConfirm={() => {
                    setConfirmModal(false);
                    handleSave();
                }}
                actionButtonLabel={'Yes'}
                isLoading={isLoading}
            >
                Are you sure you want to copy these settings?
            </ConfirmSaveModal>
        </>
    );
};
