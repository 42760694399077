import { MyCustomSelect, OptionsWithAssignee } from 'components/common/MyCustomSelect/myCustomSelect';
import React from 'react';
import { IAssigneeOptionsList, useAssigneeOptionsList } from 'hooks/useAssigneeOptionsList';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const AssigneeSelect = React.forwardRef<any, any>(({ ...rest }, ref) => {
    const options = useAssigneeOptionsList();
    return (
        <MyCustomSelect<IAssigneeOptionsList>
            {...rest}
            options={options}
            components={{
                Option: OptionsWithAssignee,
            }}
        />
    );
});
