import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { useQuery } from 'react-query';
import {
    IClustersDataBySearchWordResponse,
    IClustersListResponse,
} from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/ClusterRCFAnalysis.type';

const getList = (params) => {
    return network
        .post(API_URL().FETCH_PROGRAM_DASHBOARD_RCF_CLUSTERS_LIST, {
            paFileMetaDataIds: params.idList,
            projectId: params.projectId,
            clusterType: params.compare,
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const getClustersDataBySearchWord = (params) => {
    return network
        .post(API_URL().FETCH_PROGRAM_DASHBOARD_RCF_CLUSTER_DATA_SEARCH_WORD, {
            customerId: params.customerId,
            paFileMetaDataIds: params.idList,
            projectId: params.projectId,
            query: params.searchWord,
            clusterType: params.compare,
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const getClustersSearchWordsList = (params) => {
    const url = `${API_URL().FETCH_PROGRAM_DASHBOARD_RCF_CLUSTER_DATA_SEARCH_WORDS_LIST}?customerId=${params}`;

    return network
        .post(url)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const useQueryClustersList = (params) => {
    return useQuery<IClustersListResponse, QueryError>(['clustersList', params], () => getList(params), {
        enabled: params.idList.length > 0 && Boolean(params.compare),
        staleTime: 300000,
    });
};

const useQueryClustersDataBySearchWord = (params) => {
    return useQuery<IClustersDataBySearchWordResponse, QueryError>(
        ['clustersDataBySearchWord', params],
        () => getClustersDataBySearchWord(params),
        {
            enabled:
                Boolean(params.searchWord) &&
                Boolean(params.customerId) &&
                Boolean(params.idList) &&
                Boolean(params.compare),
            staleTime: 300000,
        },
    );
};

interface IClustersSearchWordsListResponse {
    clusterName: string;
    id: number;
}

const useQueryClustersSearchWordsList = (params) => {
    return useQuery<IClustersSearchWordsListResponse[], QueryError>(
        ['clustersSearchWordsList', params],
        () => getClustersSearchWordsList(params),
        {
            enabled: Boolean(params),
            staleTime: 300000,
        },
    );
};

export { useQueryClustersList, useQueryClustersDataBySearchWord, useQueryClustersSearchWordsList };
