import { useQuery } from 'react-query';
import { QueryKeys } from 'api/queryKeys';
import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { IFilter } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';

const queryFn = (params: QueryParams): Promise<QueryResponse> => {
    const newParams = {
        actualMetaDataId: params.latestVersion,
        baselineMetaDataId: params.comparedVersion,
        filter: params.filter,
    };

    return network.post(API_URL().FETCH_PROGRAM_DASHBOARD_CRITICALITY_WEIGHT, newParams).then((response) => {
        return response.data;
    });
};

export const useQueryGetCriticalityWeightScurveGraph = (params: QueryParams) => {
    return useQuery<QueryResponse, QueryError, QueryResponse, (QueryKeys | QueryParams)[]>({
        queryKey: ['getCriticalityWeightScurveGraph', params],
        queryFn: () => queryFn(params),
        enabled: params.id.length > 0 && Boolean(params.comparedVersion) && Boolean(params.latestVersion),
    });
};

interface Data {
    text: string;
    label: number;
    value: number;
}

interface GraphLine {
    title: string | null;
    seriesname: null;
    data: Data[];
}

export interface QueryResponse {
    nowDate: string;
    plannedGraphLine: GraphLine;
    actualGraphLine: GraphLine;
    forecatsGraphLine: GraphLine;
    info: IToolTip;
}

interface QueryParams {
    latestVersion: number | undefined;
    comparedVersion: number | undefined;
    filter: {
        filters: IFilter[] | undefined;
    };
    id: number[][];
}
