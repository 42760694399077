import React from 'react';
import { DraggableListItem } from './draggableListItem';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { IDraggableListProps } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import classes from 'components/Dashboards/Project/Components/CustomDashboard/EditDashboardWidgetsModal/draggableList.module.scss';
import { hashmap } from 'components/Dashboards/Project/Components/CustomDashboard/utils/hashmap';
import { getPermissionsToViewComponent } from 'components/Dashboards/Project/Components/CustomDashboard/customDashboard.utils';
import useUserHook from 'hooks/useUserHook';
import { useQueryGetProgramGeneralHierarchyList } from 'api/queries/getProgramGeneralHierarchyList.query';
/**
 * Draggable List of widgets.
 * @param {IWidget[]}  items - widgets list of specific dashboard
 * @param {OnDragEndResponder}  onDragEnd - handler for drag & drop widget.
 * @param {(number) => void}  onRemoveItem - remove the widget from list handler.
 * @returns  the Widget JSX Component
 * */
export const DraggableList = React.memo(({ items, onDragEnd, onRemoveItem }: IDraggableListProps) => {
    const { ability } = useUserHook();
    const { data: projectHierarchyList } = useQueryGetProgramGeneralHierarchyList('Dashboards');

    const isDisableOrderAll = items.some((item) => {
        const componentKey = item.componentKey;
        const projectId = item.projectId;
        const componentObj = Boolean(componentKey) ? hashmap()[componentKey] : null;
        const permission = componentObj ? componentObj.permission : undefined;

        const { isHavePermissions } = getPermissionsToViewComponent({
            projectHierarchyList,
            projectId,
            permission: permission !== undefined ? ability.can('view', permission) : undefined,
        });

        return !isHavePermissions;
    });

    return (
        <div>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable-list">
                    {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps} className={classes.draggableList}>
                            {items.map((item, index) => (
                                <DraggableListItem
                                    item={item}
                                    index={index}
                                    key={`${item.id}-${index} `}
                                    onRemoveItem={onRemoveItem}
                                    isDisableOrderAll={isDisableOrderAll}
                                />
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    );
});
