import moment from 'moment-timezone';
import constants from 'components/common/Constants/constants';
import { IHeatMapData } from 'api/queries/heatMapActivityResources.query';

export const resolutionOptions: ISelectOption<boolean>[] = [
    {
        value: false,
        label: 'Month',
    },
    {
        value: true,
        label: 'Week',
    },
];

export interface ISerializeHeatmapReturn {
    id: string;
    value: number;
    numOfTasksPerMonth: number;
    numOfDelayDrivers: number;
    tooltipLabel: string;
    label: string;
    date: number;
}

export const serializeHeatmap = (data: IHeatMapData[], resolutionValue: boolean): ISerializeHeatmapReturn[] => {
    return data.map((item, index) => {
        return {
            id: `key-${index}`,
            value: item.percentile,
            numOfTasksPerMonth: item.numOfTasksPerMonth,
            numOfDelayDrivers: item.numOfDelayDrivers,
            tooltipLabel: !resolutionValue
                ? moment(item.startDate).format(constants.formats.date.monthAndYear)
                : `${moment(item.startDate).format(constants.formats.date.weekAndMonth)} - ${moment(item.endDate).format(constants.formats.date.weekAndMonth)} ${moment(item.endDate).year()}`,
            label: !resolutionValue
                ? moment(item.startDate).format(constants.formats.date.monthAndYear)
                : `${moment(item.startDate).format(constants.formats.date.weekAndMonth)} ${moment(item.endDate).year()}`,
            date: item.endDate,
        };
    });
};
