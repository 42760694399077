import { useMutation } from 'react-query';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { network } from 'services/network';
import qs from 'qs';

const mutationFn = ({ customerId, name, projectId }: QueryParams) => {
    const querystring = qs.stringify({ customerId, name, projectId }, { addQueryPrefix: true, skipNulls: true });
    const url = `${API_URL().CREATE_TAG}${querystring}`;
    return network.post(url).then((response) => {
        return response.data;
    });
};

export const useMutationCreateTag = () => {
    return useMutation<string, QueryError, QueryParams>({
        mutationFn: (params) => mutationFn(params),
    });
};

interface QueryParams {
    customerId: number;
    name: string;
    projectId: number;
}
