import { useQuery } from 'react-query';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { network } from 'services/network';
import { QueryKeys } from 'api/queryKeys';

const queryFn = (params: QueryParams) => {
    return network
        .post(API_URL().GET_SELECT_OPTIONS, { objectsList: params.objectsList, projectId: params.projectId })
        .then((response) => {
            return response.data;
        });
};

export const useQueryGetCommonResources = (params: QueryParams) => {
    return useQuery<QueryResponse, QueryError, QueryResponse, [QueryKeys, unknown]>({
        queryKey: ['getCommonResources', params],
        queryFn: () => queryFn(params),
        staleTime: 1000 * 60 * 5,
    });
};

export interface QueryParams {
    objectsList: (keyof QueryResponse)[];
    projectId?: number;
}

export type IUser = {
    firstName: string;
    id: null;
    lastName: string;
    userName: string;
    imageName: string;
};

export type ITags = {
    id: number;
    name: string;
};

export interface QueryResponse {
    users?: IUser[];
    tags?: ITags;
}
