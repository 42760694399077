import { GanttConfig, ProjectModelConfig } from '@bryntum/gantt';

export const ganttConfig: Partial<GanttConfig> = {
    columns: [
        { type: 'name', field: 'name', width: 300 },
        {
            type: 'startdate',
            field: 'startDate',
            width: 120,
            text: 'Start Date',
        },
        {
            type: 'enddate',
            field: 'endDate',
            width: 120,
            text: 'End Date',
        },
    ],
    viewPreset: 'year',
    barMargin: 10,
    destroyStore: true,
    displaySchedulingIssueResolutionPopup: false,
};

export const ganttConfigSharedResourses: Partial<GanttConfig> = {
    columns: [
        { type: 'name', field: 'name', width: 500 },
        {
            type: 'startdate',
            field: 'startDate',
            width: 120,
            text: 'Start Date',
        },
        {
            type: 'enddate',
            field: 'endDate',
            width: 120,
            text: 'End Date',
        },
        { field: 'activityResourses', width: 240, text: 'Resources (Units)' },
    ],
    viewPreset: 'year',
    barMargin: 10,
    destroyStore: true,
    displaySchedulingIssueResolutionPopup: false,
};

export const projectConfig: Partial<ProjectModelConfig> = {
    taskStore: {
        autoTree: true,
        transformFlatData: true,
        sorters: [{ field: 'startDate', ascending: true }],
    },
};
