import { Option } from 'components/common/ListWrapper/option';
import React from 'react';

interface IListWrapperProps<T> {
    options: ISelectOption<T>[];
    onChange: (filterValue: ISelectOption<T>) => void;
    value?: ISelectOption<T>;
}

export const List = React.forwardRef(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    <T,>({ options, onChange, value }: IListWrapperProps<T>, ref: React.LegacyRef<HTMLDivElement>) => {
        return (
            <>
                {options.map((option) => (
                    <Option key={option.label} option={option} onChange={onChange} value={value} />
                ))}
            </>
        );
    },
);
