import classes from './AssigneeAvatarWithInlineSelect.module.scss';
import { AssigneeAvatar } from 'components/common/AssigneeAvatar/AssigneeAvatar';
import React, { useEffect, useState } from 'react';
import { ISelectOptionOrData } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/battlecards.types';
import { IAssigneeWithExtraData } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/ProgramActivityCard/ProgramActivityCard.types';
import useDetectClickOutSideComponentHook from 'components/common/CustomHook/DetectClickOutSideComponentHook/useDetectClickOutSideComponentHook';
import { AssigneeSelect } from 'components/common/assigneeSelect';

interface IOption extends ISelectOption<string> {
    username?: string;
}

interface AssigneeAvatarWithInlineSelectProps {
    value: ISelectOptionOrData<string> | null;
    assigneeValue: IAssigneeWithExtraData | null;
    onSelection: (value: IOption) => void;
    isDisabled?: boolean;
    isError?: boolean;
}

export const AssigneeAvatarWithInlineSelect = React.forwardRef(
    (
        { value, assigneeValue, onSelection, isError, isDisabled = false }: AssigneeAvatarWithInlineSelectProps,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ref: any,
    ) => {
        const [menuOpen, setMenuOpen] = useState(false);
        const [editMode, setEditMode] = useState(false);
        const {
            ref: clickOutSidRef,
            isComponentVisible,
            setIsComponentVisible,
        } = useDetectClickOutSideComponentHook(menuOpen);

        useEffect(() => {
            if (!isComponentVisible) {
                setEditMode(false);
                setMenuOpen(false);
            }
        }, [isComponentVisible]);

        const toggleEditMode = () => {
            setEditMode(!editMode);
            setMenuOpen(!menuOpen);
            setIsComponentVisible(true);
        };

        const handleSelection = (value) => {
            setEditMode(false);
            setMenuOpen(false);
            onSelection(value);
        };

        return (
            <div
                data-testid={'assignee-avatar-inline'}
                className={classes.assigneeWrapper}
                ref={clickOutSidRef}
                onClick={toggleEditMode}
            >
                {editMode || !value ? (
                    <div className={classes.inlineSelectWrapper} data-testid={'assignee-avatar-inline-edit-mode'}>
                        <AssigneeSelect
                            value={value}
                            onChange={handleSelection}
                            menuIsOpen={menuOpen}
                            isError={isError}
                            autoFocus={menuOpen}
                            name="assignee-select"
                            inputId="assignee-select"
                        />
                    </div>
                ) : (
                    <div
                        className={`${classes.inlineValue} ${!isDisabled && classes.editable}`}
                        onClick={toggleEditMode}
                        data-testid={'assignee-avatar-inline-inline-mode'}
                    >
                        {assigneeValue && assigneeValue.assignee && (
                            <AssigneeAvatar assignee={assigneeValue.assignee} />
                        )}
                        <span title={value?.label}>{value?.label}</span>
                    </div>
                )}
            </div>
        );
    },
);
