import { generatePath, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useProjectStore } from 'store/project.store';
import { searchInTree, UuidExtensionName } from 'components/common/TreeCrumbs/treeCrumbs.utils';
import { useQueryGetProgramGeneralHierarchyList } from 'api/queries/getProgramGeneralHierarchyList.query';

export const useProgramLibraryPageHook = () => {
    const { data: projectHierarchyList } = useQueryGetProgramGeneralHierarchyList('TreeProgramFilter');
    const { projectId, contractId } = useParams<{ projectId: string; contractId: string }>();
    const [dirty, setDirty] = useState<boolean>(false);
    const history = useHistory();
    const { path } = useRouteMatch();
    const {
        setSelectedProject,
        selectedProject: { project, contract },
    } = useProjectStore();

    /**
     * set selected project and contract when pasting a copied url
     */
    useEffect(() => {
        const projectUUID = `${projectId}-${UuidExtensionName.project}`;
        const contractUUID = `${contractId}-${UuidExtensionName.contract}`;
        if (projectHierarchyList && project && project.uuid !== projectUUID) {
            const contractNode = contractId ? searchInTree(projectHierarchyList, contractUUID) : null;
            const projectNode = projectId ? searchInTree(projectHierarchyList, projectUUID) : null;
            if (projectNode && !dirty) {
                setSelectedProject({
                    project: {
                        id: projectNode.id,
                        title: projectNode.name,
                        uuid: projectNode.uuid,
                    },
                    contract: contractNode
                        ? {
                              id: contractNode.id,
                              title: contractNode.name,
                              uuid: contractNode.uuid,
                          }
                        : null,
                });
                setDirty(true);
            }
        }
    }, [projectHierarchyList, projectId, contractId, project, dirty]);

    /**
     * put projectId and contractId in url based on change in selectedProject project and contract
     */
    useEffect(() => {
        if (projectHierarchyList) {
            const newLocation = generatePath(path, { projectId: project?.id, contractId: contract?.id });
            history.push(newLocation);
        }
    }, [projectHierarchyList, project, contract]);
};
