import { useMutation } from 'react-query';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { network } from 'services/network';
import qs from 'qs';

const mutationFn = ({ customerId, oldName, newName, projectId }: QueryParams) => {
    const querystring = qs.stringify(
        { customerId, oldName, newName, projectId },
        { addQueryPrefix: true, skipNulls: true },
    );
    const url = `${API_URL().UPDATE_TAG}${querystring}`;

    return network.post(url).then((response) => {
        return response.data;
    });
};

export const useMutationUpdateTag = () => {
    return useMutation<string, QueryError, QueryParams>({
        mutationFn: (params) => mutationFn(params),
    });
};

interface QueryParams {
    customerId: number;
    oldName: string;
    newName: string;
    projectId: number;
}
