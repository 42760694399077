import { Unionize } from 'utilitys/helpers/TsGeneral';
import { create, StateCreator } from 'zustand';
import { TKeys } from 'components/Dashboards/ExcellenceApps/CompletionPlanPage/utils/helper';
import {
    activityTypeOptions,
    IActivityTypeOptions,
    statusOptions,
} from 'components/Dashboards/ExcellenceApps/CompletionPlanPage/utils/utils';

interface Props {
    activityTypeFilter: readonly IActivityTypeOptions[];
    statusFilter: readonly ISelectOption<string>[];
    isCriticalPath: boolean;
    submittedCompletionFilter: number[];
    activeTab: TKeys;
    isDelayDriver: boolean;
    update: (value: Unionize<Omit<Props, 'update'>>) => void;
}

const state: StateCreator<Props> = (set) => ({
    activityTypeFilter: activityTypeOptions,
    statusFilter: statusOptions,
    isCriticalPath: false,
    submittedCompletionFilter: [0, 100],
    activeTab: 'blocking',
    isDelayDriver: false,
    update: (value) => set({ ...value }),
});

export const useActivitiesPredecessorsStore = create(state);
