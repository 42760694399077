import { useMutation } from 'react-query';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { network } from 'services/network';
import { ICreateCardObjectReturn } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/helper';
import { QueryResponse as CardResponse } from 'api/queries/getMainCard.query';
import qs from 'qs';

const createEditCard = (params) => {
    const url = API_URL().FETCH_PROGRAM_SCOPE_TO_GO_CREATE_EDIT_CARD;

    //Change API Link
    return network
        .post(url, params)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const deleteCard = ({ cardId, versionId }: { cardId: number; versionId?: number }) => {
    const queryString = qs.stringify({ cardId, versionId }, { addQueryPrefix: true, skipNulls: true });
    const url = `${API_URL().FETCH_PROGRAM_SCOPE_TO_GO_GET_DELETE_CARD}${queryString}`;

    return network
        .get(url)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const useMutationCreateEditCard = () => {
    return useMutation<CardResponse, QueryError, ICreateCardObjectReturn>((params) => createEditCard(params));
};

export const useMutationDeleteCard = () => {
    return useMutation<string, QueryError, { cardId: number; versionId?: number }>(({ cardId, versionId }) =>
        deleteCard({ cardId, versionId }),
    );
};
