import moment from 'moment-timezone';
import constants from 'components/common/Constants/constants';
import { IHighLevelProgramResponse } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramProgressOverview/queries/progressOverviewQuery';

const normalizeDate = (data) => (data ? moment(data).format(constants.formats.date.yearMonthDay) : 'N/A');

export const serialize = (data: IHighLevelProgramResponse['data']): IDataLollipop[] => {
    return data
        .map((item, index: number) => {
            let start: Date = new Date(item.actualStartDate || item.plannedStartDate);
            let end: Date = new Date(item.actualEndDate || item.plannedEndDate);
            return {
                actualEndDate: normalizeDate(item.actualEndDate),
                actualStartDate: normalizeDate(item.actualStartDate),
                color: item.color,
                durationComplete: item['duration%Complete'],
                end: end,
                level: item.level,
                plannedEndDate: normalizeDate(item.plannedEndDate),
                plannedStartDate: normalizeDate(item.plannedStartDate),
                start: start,
                taskCode: item.taskCode,
                title: item.description,
                y: index,
            };
        })
        .filter((d) => d.start && d.end);
};
