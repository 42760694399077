import moment from 'moment-timezone';
import constants from 'components/common/Constants/constants';

export const formatDate = (date: number | null | undefined) => {
    if (!date) {
        return 'N/A';
    }
    return moment(date).format(constants.formats.date.default);
};
export const formatVarianceString = (variance: number | null) => {
    if (!variance) {
        return '';
    }
    return ` (${variance >= 0 ? '+' : ''}${variance.toLocaleString()}d)`;
};

export const displayProbability = (probability: number) => {
    return Math.floor(probability * 100);
};
