import { IStatus } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/battlecards.types';
import produce from 'immer';
import {
    IStatusExtraProps,
    SelectOptions,
} from 'components/common/CustomizedStatusSelect/CustomizedStatusSelect.types';

export interface ISetStatusOptionsByCategory {
    options: IStatus[];
    category: string | null;
    isProgramActivity: boolean;
}

export const setStatusOptionsByCategory = ({
    options,
    category,
    isProgramActivity,
}: ISetStatusOptionsByCategory): IStatus[] => {
    if (isProgramActivity && category && category.toLowerCase().includes('milestone')) {
        return produce(options, (draft) => {
            return draft.filter((item) => item.name !== 'IN_PROGRESS');
        });
    }

    return [...options];
};

export interface IAddAPropsToSelectOption {
    options: IStatus[];
    isProgramActivity: boolean;
    originalStatus?: IStatus;
}

export const addAPropsToSelectOption = ({
    options,
    isProgramActivity,
    originalStatus,
}: IAddAPropsToSelectOption): SelectOptions<IStatusExtraProps>[] => {
    return produce(options, (draft) => {
        return draft.map((item) => {
            return { ...item, originalStatus: originalStatus?.name, isProgramActivity: isProgramActivity };
        });
    });
};
