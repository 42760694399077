import classes from './AddProjectModal.module.scss';
import { CustomizedButton, CustomizedFormErrorMessage, CustomizedModalBase, MyCustomSelect } from 'components/common';
import useEventWithDimensions from 'hooks/useEventWithDimensions';
import useUserHook from 'hooks/useUserHook';
import {
    IAllUserGroups,
    useQueryGetAllUserGroups,
} from 'components/Settings/Components/UserGroups/queries/userGroupsQuery';
import { Controller, useForm } from 'react-hook-form';

import { shortMenuListStyle } from 'components/common/MyCustomSelect/myCustomSelect.style';
import TextSearchInput from 'components/common/TextSearchInput/textSearchInput';
import { useMemo } from 'react';
import { orderBy } from 'lodash';

const groupsMapper = (item: string) => ({ value: item, label: item });
const groupsMapperBe = (item: IAllUserGroups) => ({
    value: item.group_name,
    label: item.group_name,
});

interface IAddProjectModalForm {
    initialContractName: string;
    projectName: string;
    userGroups: ISelectOption<string>[];
}

export const AddProjectModal = ({ showModal, onHideModal, handleSave, isLoadingCreateProject }) => {
    const { ability, userGroupList } = useUserHook();
    const { data: managementUserGroups } = useQueryGetAllUserGroups({
        enabled: ability.can('view', 'UserManagement'),
        isProjectCreation: true,
    });
    const { sendEventWithDimensions } = useEventWithDimensions();
    const {
        handleSubmit,
        setValue,
        control,
        formState: { errors },
        reset,
    } = useForm<IAddProjectModalForm>({
        defaultValues: {
            projectName: '',
            initialContractName: '',
            userGroups: [],
        },
    });

    const handleSaveUseForm = (value) => {
        const label: string[] = [];
        label.push(`Project: ${value.projectName}`);
        label.push(`Sub-program: ${value.initialContractName === '' ? 'default' : value.initialContractName}`);
        label.push(`User Groups: ${value.userGroups.join(', ')}`);
        sendEventWithDimensions({
            category: 'Project/Sub-program',
            action: 'Create',
            label: label.join('; '),
        });
        handleSave({ ...value, userGroups: value.userGroups.map((item) => item.label) });
        closeModal();
    };

    /**
     * get userGroups from management only if user has Admin or UserManagment in his privilegeMap property
     * if not - get the list from current user userGroupList property
     * */
    const userGroupsOptions = useMemo<ISelectOption<string>[]>(() => {
        const options = managementUserGroups?.map(groupsMapperBe) || userGroupList?.map(groupsMapper) || [];
        return orderBy(options, [(item: any) => item['label'].toLowerCase()], ['asc']);
    }, [managementUserGroups]);

    const closeModal = () => {
        reset();
        onHideModal();
    };

    return (
        <CustomizedModalBase isModalOpen={showModal} handleModalClose={closeModal}>
            <div className={classes.addProjectModalContainer}>
                <form onSubmit={handleSubmit(handleSaveUseForm)} autoComplete="off">
                    <div className={classes.title}>Add Project</div>
                    <div className={classes.inputTitle}>Name *</div>
                    <div className={classes.inputWrapper}>
                        <Controller
                            render={({ field }) => (
                                <TextSearchInput
                                    {...field}
                                    placeholder={'Project name...'}
                                    isSearch={false}
                                    maxLength={30}
                                    handleClear={() => {
                                        setValue('projectName', '');
                                    }}
                                />
                            )}
                            name={`projectName`}
                            control={control}
                            rules={{
                                required: 'Project Name is Required',
                                minLength: {
                                    value: 2,
                                    message: 'Project Name is too short',
                                },
                                pattern: {
                                    value: /^(?!\s+$).*/,
                                    message: 'Name cannot be empty',
                                },
                            }}
                        />
                    </div>

                    {errors.projectName && <CustomizedFormErrorMessage text={errors.projectName.message} />}
                    <div className={classes.inputTitle}>Initial Sub-Program name</div>

                    <div className={classes.inputWrapper}>
                        <Controller
                            render={({ field }) => (
                                <TextSearchInput
                                    {...field}
                                    placeholder={'Project initial Sub-Program name...'}
                                    isSearch={false}
                                    maxLength={100}
                                    handleClear={() => {
                                        setValue('initialContractName', '');
                                    }}
                                />
                            )}
                            name={`initialContractName`}
                            control={control}
                            rules={{
                                minLength: {
                                    value: 2,
                                    message: 'Initial Sub-Program Name is too short',
                                },
                            }}
                        />
                    </div>

                    {errors.initialContractName && (
                        <CustomizedFormErrorMessage text={errors.initialContractName.message} />
                    )}
                    <div className={classes.inputTitle}>Select who can access this project (User Groups)</div>

                    <div className={classes.inputWrapper}>
                        <Controller
                            render={({ field }) => (
                                <MyCustomSelect<ISelectOption<string>, true>
                                    {...field}
                                    options={userGroupsOptions}
                                    isClearable={true}
                                    isMulti
                                    styles={shortMenuListStyle}
                                />
                            )}
                            name={'userGroups'}
                            control={control}
                            rules={{
                                validate: {
                                    required: (value) => value.length > 0 || 'Must select at least one user group',
                                },
                            }}
                        />
                    </div>

                    {errors.userGroups && <CustomizedFormErrorMessage text={errors.userGroups.message} />}
                    <div className={classes.footer}>
                        <div className={classes.container}>
                            <CustomizedButton size={'large'} color={'secondary'} onClick={closeModal}>
                                Cancel
                            </CustomizedButton>

                            <CustomizedButton
                                type={'submit'}
                                size={'large'}
                                color={'primary'}
                                isLoading={isLoadingCreateProject}
                                disabled={isLoadingCreateProject}
                            >
                                Save
                            </CustomizedButton>
                        </div>
                    </div>
                </form>
            </div>
        </CustomizedModalBase>
    );
};
