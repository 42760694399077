import qs from 'qs';
import { LocalFilters } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramStatusSummaryReport/PrioritiesMatrixSection/PrioritiesMatrixWrapper/prioritiesMatrixComponent';

export const BATTLECARDS_FILTERS = {
    7: ['URGENT_7_DAYS', 'NOT_URGENT_7_DAYS'],
    14: ['URGENT_14_DAYS', 'NOT_URGENT_14_DAYST'],
    30: ['URGENT_30_DAYS', 'NOT_URGENT_30_DAYS'],
    90: ['URGENT_90_DAYS', 'NOT_URGENT_90_DAYST'],
};
export const MATRIX_COMPONENTS = {
    doNowComponent: {
        urgent: true,
        important: true,
    },
    doNextComponent: {
        urgent: false,
        important: true,
    },
    prioritizeComponent: {
        urgent: true,
        important: false,
    },
    laterComponent: {
        urgent: false,
        important: false,
    },
};

export const PRIORITIES_MATRIX_COMPONENT_HEIGHT = '743px';
export const PRIORITIES_MATRIX_FILTERS: ISelectOption<string>[] = [
    {
        label: '7D',
        value: '7',
    },
    {
        label: '14D',
        value: '14',
    },
    {
        label: '30D',
        value: '30',
    },
    {
        label: '3M',
        value: '90',
    },
];

export const initialMatrixFilter = '30';

export const getTagsQueryFilter = ({ localFilters }: { localFilters: LocalFilters }) => {
    const tagsQuery = qs.parse(window.location.search, { ignoreQueryPrefix: true })['tags'];
    if (localFilters?.tagsQuery?.tags || localFilters?.tagsQuery?.excludeTags) {
        return localFilters?.tagsQuery;
    } else if (tagsQuery && Array.isArray(tagsQuery)) {
        const tags: string[] = [];
        const excludeTags: string[] = [];
        tagsQuery.forEach((item) => {
            if (item.value.isExclude === 'true') {
                excludeTags.push(item.value.name);
            } else {
                tags.push(item.value.name);
            }
        });

        return {
            tags: tags?.length > 0 ? tags.toString() : null,
            excludeTags: excludeTags?.length > 0 ? excludeTags.toString() : null,
        };
    }

    return { tags: null, excludeTags: null };
};
