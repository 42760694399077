import { ITableWidgetWrapperProps } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import React, { useMemo, useRef, useState } from 'react';
import useCustomPageSubTitle from 'hooks/useCustomPageSubTitle';
import useTableCurrentState from 'hooks/useTableCurrentState';
import { columns, initialState } from 'components/Dashboards/widgets/myTrackedActivities/utils';
import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import { CustomizedRoundIcon, CustomizedTooltip } from 'components/common';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import Icon from 'components/common/Icons/icon';
import { ShareInsightButton } from 'components/common/ShareInsightButton/ShareInsightButton';
import { ExportDropdown } from 'components/common/ExportDropdown/exportDropdown';
import NoData from 'components/common/NoData/noData';
import classes from 'components/Dashboards/Project/Components/VersionCompare/style/Table.module.scss';
import { CustomTableColumnsModal } from 'components/common/Modals/CustomTableColumns/customTableColumnsModal';
import MainTable from 'components/common/Tables/MainTable/MainTable';
import { useQuerySummaryTrackedActivities } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramStatusSummaryReport/TableSection/SummaryTrackedActivities/useQuerySummaryTrackedActivities';
import { Link } from 'react-router-dom';
import { projectConfig } from 'components/Dashboards/Project/ProjectWrapper/projectConfig';
import { getStringWithoutHash } from 'utilitys/helpers/general';

export const MyTrackedActivitiesWidget = ({
    projectId,
    contractId,
    latestVersionId,
    compareVersionId,
}: ITableWidgetWrapperProps) => {
    const tableRef = useRef(null);
    const elementId = 'summary-tracked-activities';
    const id = 'summary-tracked-activities';
    const route = `${getStringWithoutHash(window.location.href)}#${elementId}`;
    const subTitle = useCustomPageSubTitle({ projectId, contractId, latestVersionId });
    const componentKey = 'myTrackedActivities';
    const updatedInitialState = useTableCurrentState({ initialState, componentKey });
    const [modalColumnsOpen, setModalColumnsOpen] = useState<boolean>(false);

    const { data, isLoading } = useQuerySummaryTrackedActivities({
        projectId: projectId,
        contractId: contractId,
        latestVersionId: latestVersionId,
        compareVersionId: compareVersionId,
        filterData: {
            filters: [],
        },
    });

    const title = 'My Tracked Activities';

    const activeData = useMemo(() => {
        if (data) {
            const current = data.periodItemsList.find((item) => item.id === 'all');
            return current ? current.itemsList : [];
        }
        return [];
    }, [data]);

    return (
        <WidgetWithTitle
            title={title}
            tooltip={data?.info}
            id={id}
            titleComponents={[
                <CustomizedTooltip
                    key={'tableColumns'}
                    tooltipPosition={TooltipPosition.Top}
                    tooltipContent={'Customize table columns'}
                    triggerElement={
                        <CustomizedRoundIcon enableHover={true} onClick={() => setModalColumnsOpen(true)}>
                            <Icon name={'tune-filter'} />
                        </CustomizedRoundIcon>
                    }
                />,
                <ShareInsightButton title={title} link={route} />,
                <ExportDropdown title={title} key={'export'} subTitle={subTitle} />,
            ]}
        >
            <div className={classes.tableWrapperContainer}>
                {updatedInitialState !== undefined && (
                    <MainTable
                        getInstanceCallback={(instance) => (tableRef.current = instance)}
                        data={activeData}
                        columns={columns}
                        initialState={updatedInitialState}
                        maxHeight={500}
                        noData={
                            <NoData>
                                No tracked activities added yet. Find and add via{' '}
                                <Link to={`/dashboard/project/${projectConfig.battlecards.link}`}>
                                    {projectConfig.battlecards.title}
                                </Link>
                            </NoData>
                        }
                        isLoading={isLoading}
                    />
                )}
            </div>

            {tableRef?.current && (
                <CustomTableColumnsModal
                    onHideModal={() => setModalColumnsOpen(false)}
                    showModal={modalColumnsOpen}
                    componentKey={componentKey}
                    tableInstance={tableRef.current}
                    initialState={initialState}
                    columns={columns}
                />
            )}
        </WidgetWithTitle>
    );
};
