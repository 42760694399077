import React from 'react';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import { CustomizedTooltip } from 'components/common';
import { MuiIcon } from 'components/common/muiIcon/muiIcon';
import classes from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/components/taskActivitiesTitle.module.scss';
import colorsVars from 'styles/colors.module.scss';
const { warning } = colorsVars;

interface Props {
    title: string;
    showTotalActivities: boolean;
    filteredRowsLength: number;
}

export const TaskActivitiesTitle = ({ title, showTotalActivities, filteredRowsLength }: Props) => {
    return (
        <div className={classes.container}>
            <div>{title}</div>
            {showTotalActivities ? (
                <CustomizedTooltip
                    triggerElement={
                        <div className={classes.icon}>
                            <MuiIcon icon={'errorOutline'} fontSize={'3.2rem'} color={warning} />
                        </div>
                    }
                    tooltipContent={`Showing first ${filteredRowsLength} activities. To see full list click on 'View in Project Activities’.`}
                    tooltipPosition={TooltipPosition.Bottom}
                />
            ) : null}
        </div>
    );
};
