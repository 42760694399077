import { useQuery } from 'react-query';
import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { QueryKeys } from 'api/queryKeys';

const queryFn = () => {
    return network.get(API_URL().GET_TAGS_ASSIGNED_TO_CURRENT_CUSTOMER_SUB_PROGRAMS).then((response) => {
        return response.data;
    });
};

export const useQueryGetTagsAssignedToCurrentCustomerSubPrograms = () => {
    return useQuery<QueryResponse[], QueryError, QueryResponse[], [QueryKeys]>({
        queryKey: ['getTagsAssignedToCurrentCustomerSubPrograms'],
        queryFn: () => queryFn(),
    });
};

interface QueryResponse {
    id: number;
    name: string;
}
