import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { useQuery } from 'react-query';
import { QueryKeys } from 'api/queryKeys';

const queryFn = (params) => {
    return network.post(API_URL().FETCH_PROJECT_SCORE_CARDS_SUMMARY, params).then((response) => {
        return response.data;
    });
};

export interface IPortfolioTopHeroesItems {
    name: string;
    order: number;
    value: number;
}

export interface IPortfolioTopHeroesComponentsList {
    [key: string]: IPortfolioTopHeroesItems[];
}

export interface IGetProjectScoreCardsSummary {
    componentsList: IPortfolioTopHeroesComponentsList;
    info?: IToolTip;
    name?: string;
}

export const useQueryProjectScoreCardsSummary = (params) => {
    return useQuery<IGetProjectScoreCardsSummary, QueryError, IGetProjectScoreCardsSummary, [QueryKeys, unknown]>({
        queryKey: ['projectScoreCardsSummary', params],
        queryFn: () => queryFn(params),
    });
};
