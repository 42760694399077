import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import classes from './timelineAndDensity.module.scss';
import moment from 'moment-timezone';
import { useEffect, useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';
import { projectConfig } from 'components/Dashboards/Project/ProjectWrapper/projectConfig';
import NoData from 'components/common/NoData/noData';
import OverlayWithSpinner from 'components/common/OverlayWithSpinner/overlayWithSpinner';
import constants from 'components/common/Constants/constants';
import { AddWidget } from 'components/Dashboards/Project/Components/CustomDashboard/AddRemoveWidget/addWidget';
import { ShareInsightButton } from 'components/common/ShareInsightButton/ShareInsightButton';
import { ExportDropdown } from 'components/common/ExportDropdown/exportDropdown';
import { IPortfolioProps } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import { portfolioConfig } from 'components/Dashboards/Portfolio/portfolioConfig';
import { prepareVersionSelectTitle } from 'components/common/TreeProgramFilter/VersionSelect/versionSelect.utils';
import { ColorTextByValue } from 'components/common/colorTextByValue/colorTextByValue';
import { useGlobalFilterDataStore } from 'store/globalFilterData.store';
import { FilterNames, getFiltersData } from 'components/common/GlobalFilterComponent/GlobalFilterComponent.utils';
import { HeatmapWrapper } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/CommonComponents/CriticalAnalysisHeatMap/heatmapWrapper/heatmapWrapper';
import { useQueryTimelineAndDensity } from 'api/queries/timelineAndDensity.query';
import { getStringWithoutHash } from 'utilitys/helpers/general';

const timelineAndDensityId = 'timeline-and-density';

export const TimelineAndDensity = ({
    widgetId,
    externalComponents = null,
    editNarrative = null,
    setSubTitleForExport,
    filter,
}: IPortfolioProps) => {
    const globalFilterData = useGlobalFilterDataStore((state) => state.globalFilterData);
    const filters = useMemo(
        () =>
            widgetId
                ? filter?.filters || []
                : getFiltersData({
                      [FilterNames.TAG_PORTFOLIO_BOARD]: globalFilterData?.programTags?.map((item) => item.value),
                  }),

        [filter, globalFilterData],
    );
    const route = `${getStringWithoutHash(window.location.href)}#${timelineAndDensityId}`;
    const { data, isLoading } = useQueryTimelineAndDensity({
        filter: {
            filters,
        },
    });

    const todayLineRef = useRef<HTMLDivElement | null>(null);
    const mapContainerRef = useRef<HTMLDivElement | null>(null);

    const todayLineDate = moment().valueOf();

    const setTodayLine = (position: number | null) => {
        if (typeof position === 'number' && todayLineRef.current && mapContainerRef.current) {
            todayLineRef.current.style.left = `${position}px`;
            todayLineRef.current.style.height = `${mapContainerRef.current.offsetHeight - 60}px`;
            todayLineRef.current.style.display = 'block';
        }
    };

    const title = 'Portfolio Timeline & Density';
    const componentKey = 'timelineAndDensity';

    const subTitleForExport = `${portfolioConfig.portfolioOverview.title}, ${prepareVersionSelectTitle()}`;

    useEffect(() => {
        setSubTitleForExport && setSubTitleForExport({ widgetId, subTitle: subTitleForExport, title });
    }, [widgetId, subTitleForExport, title]);

    return (
        <WidgetWithTitle
            id={'portfolioTimelineAndDensity'}
            title={title}
            tooltip={data?.info}
            titleComponents={[
                externalComponents && <div key={'externalComponents'}>{externalComponents}</div>,
                <AddWidget
                    key={'AddWidget'}
                    componentKey={componentKey}
                    title={title}
                    widgetId={widgetId}
                    route={route}
                    projectId={null}
                    contractId={null}
                    filters={filters}
                />,
                <ShareInsightButton key={'shareInsight'} title={title} link={route} />,
                <ExportDropdown title={title} key={'export'} subTitle={subTitleForExport} />,
            ]}
            titleFilters={[editNarrative && <div key={'editNarrative'}>{editNarrative}</div>]}
        >
            {data && data.heatMapPortfolioProjectResponse.length === 0 ? (
                <NoData />
            ) : (
                <div className={classes.timelineAndDensityContainer}>
                    {isLoading && <OverlayWithSpinner />}
                    <div className={classes.titleContainer}>
                        {data?.heatMapPortfolioProjectResponse.map((item, index) => {
                            const first = index === 0;

                            return (
                                <div className={classes.title} style={{ height: first ? 170 : 120 }} key={index}>
                                    {item.versionHashCode ? (
                                        <>
                                            <Link
                                                data-testid={'link-lable'}
                                                to={`/dashboard/project/${projectConfig.criticalityAnalysis.link}/${item.versionHashCode}`}
                                                className={classes.linkLabel}
                                            >
                                                {item.label}
                                            </Link>
                                            <div>
                                                Submitted Completion:{' '}
                                                <span className={classes.submittedCompletionValue}>
                                                    {item.completionPercent}%
                                                </span>
                                            </div>
                                            <div>
                                                Schedule Performance (Criticality Weighted):{' '}
                                                <ColorTextByValue value={item.spiCriticality} />
                                            </div>
                                            <div>
                                                Schedule Performance (Completion Weighted):{' '}
                                                <ColorTextByValue value={item.spiMilestone} />
                                            </div>
                                        </>
                                    ) : (
                                        <div className={classes.label}>{item.label}</div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                    <div className={classes.mapContainer} ref={mapContainerRef}>
                        <div className={classes.todayLine} ref={todayLineRef}>
                            <div className={classes.dateBox}>
                                {moment(todayLineDate).format(constants.formats.date.monthAndYear)}
                            </div>
                        </div>
                        {data &&
                            data.heatMapPortfolioProjectResponse.map((item, index) => {
                                const first = index === 0;
                                return (
                                    <div key={index} className={classes.mapWrapper}>
                                        <HeatmapWrapper
                                            mainData={data.heatMapPortfolioProjectResponse[0].data}
                                            data={data.heatMapPortfolioProjectResponse[index].data}
                                            isFirst={first}
                                            height={first ? 147 : 72}
                                            callback={setTodayLine}
                                            todayLineDate={todayLineDate}
                                        />

                                        {data.heatMapPortfolioProjectResponse[index].baselineData.length !== 0 && (
                                            <div className={classes.baseline}>
                                                <HeatmapWrapper
                                                    mainData={data.heatMapPortfolioProjectResponse[0].data}
                                                    data={data.heatMapPortfolioProjectResponse[index].baselineData}
                                                    height={10}
                                                />
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                    </div>
                </div>
            )}
        </WidgetWithTitle>
    );
};
