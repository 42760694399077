import { Route, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import moment from 'moment-timezone';
import { useQueryClient } from 'react-query';
import { getCheck } from 'components/Dashboards/ExcellenceApps/LogicChecker/helper';
import useHashmapConverter from 'hooks/useHashmapConverter';
import {
    useMutationSetAssociateCardWithLogicChecker,
    useQueryGetLogicCheckerCategoryResults,
} from 'components/Dashboards/ExcellenceApps/LogicChecker/queries/useQueryLogicChecker';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import constants from 'components/common/Constants/constants';
import { MainCard } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/MainCard';
import { IInitialCard } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/CreateCard/CreateCard.types';
import { useLogicCheckerFilterHook } from 'components/Dashboards/ExcellenceApps/LogicChecker/hooks/useLogicCheckerFilterHook';

export const ValidationPageCardModal = () => {
    const { path } = useRouteMatch();
    const queryClient = useQueryClient();
    const { mutate: setAssociateCardMutate } = useMutationSetAssociateCardWithLogicChecker();
    const { pathname } = useLocation();
    const { handleSnackBar } = useCustomSnackBar();
    const { id: versionId, category, check } = useParams<{ id: string; category: string; check: string }>();
    const { idList, versionList } = useHashmapConverter(versionId);
    const fileMetaDataId = idList.length > 0 ? parseInt(idList.join(',')) : undefined;
    const filters = useLogicCheckerFilterHook();
    const { data: logicCheckerData } = useQueryGetLogicCheckerCategoryResults({
        fileMetaDataId,
        filter: {
            filters,
        },
    });
    const parsedCategory = category ? category.replace('_', '/') : '';
    const parsedCheck = check ? check.replace('_', '/') : '';
    const pickedCheck = getCheck({
        categoriesSummaryRows: logicCheckerData?.categoriesSummaryRows,
        parsedCategory,
        parsedCheck,
    });

    const refetchTableData = (id) => {
        if (pickedCheck?.associatedCard.cardId) return queryClient.invalidateQueries('getLogicCheckerCategoryResults');

        const params = {
            metaDataId: fileMetaDataId,
            checkId: pickedCheck?.validationId,
            cardId: id,
        };
        setAssociateCardMutate(params, {
            onSuccess: () => {
                return queryClient.invalidateQueries('getLogicCheckerCategoryResults');
            },
            onError: (error) => {
                handleSnackBar(error.response.data || 'Something went wrong', 'error');
            },
        });
    };
    const versionName = `${versionList[0]?.versionName} (${moment(versionList[0]?.versionDate).format(
        constants.formats.date.default,
    )})`;
    const initialCard: IInitialCard = {
        category: { id: 14, name: 'QUALITY', label: 'Quality' },
        details: `Quality issues found in version ${versionName}: <a href="${pathname.slice(
            0,
            pathname.lastIndexOf('/'),
        )}" target="_self">${parsedCheck}</a>`,
        startDate: moment().valueOf(),
        endDate: moment().add(7, 'days').valueOf(),
        title: `Review & fix quality issues: ${parsedCheck}`,
    };
    return (
        <Route
            path={`${path}/card-:cardId`}
            render={() => (
                <MainCard
                    saveCardCallback={refetchTableData}
                    deleteCardCallback={refetchTableData}
                    initialCard={initialCard}
                />
            )}
        />
    );
};
