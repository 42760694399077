import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { network } from 'services/network';
import { useMutation } from 'react-query';
import { IFilter } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import { TKeys } from 'components/Dashboards/ExcellenceApps/CompletionPlanPage/utils/helper';
import { SortingState } from '@tanstack/react-table';
import { Pagination } from 'components/common/pagination/customPagination';

const mutationFn = (params: QueryParams) => {
    const url = API_URL().EXPORT_COMPLETION_PLAN_CSV;

    return network.post(url, params.params).then((response) => {
        const blob = new Blob([response.data], {
            type: 'text/plain',
        });
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = `${params.fileName}.csv`;
        a.click();

        return true;
    });
};
export const useMutationExportCompletionPlanToCsv = () => {
    return useMutation<unknown, QueryError, QueryParams>({ mutationFn: (params) => mutationFn(params) });
};

interface QueryParams {
    params: {
        contractId: number | null | undefined;
        metaDataId: number | undefined;
        tabId: TKeys;
        filter: {
            filters: IFilter[];
        };
        sortingRequestList: SortingState;
        paging: Pagination;
    };
    fileName: string;
}
