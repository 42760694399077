import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { useInfiniteQuery } from 'react-query';
import { QueryKeys } from 'api/queryKeys';
import { IFilter } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';

const queryFn = ({ filter, pageParam, projectId }) => {
    const params = {
        filter: { filters: filter.filter.filters },
        paging: pageParam,
        projectId,
    };

    return network.post(API_URL().FETCH_PROJECT_DATA_STORIES, params).then((response) => {
        return response.data;
    });
};

export const useQueryGetDataStories = (params: QueryParams) => {
    return useInfiniteQuery<QueryResponse, QueryError, QueryResponse, [QueryKeys, unknown]>({
        queryKey: ['dataStories', params],
        queryFn: () => queryFn(params),
        enabled: Boolean(params.projectId),
        getNextPageParam: (lastPage, allPages) => {
            if (lastPage.dataStory.length === 0) {
                return undefined;
            }
            const page = allPages.length * 4;
            const size = page + 4;
            return { page, size };
        },
    });
};

interface QueryParams {
    filter: {
        filter: {
            filters: IFilter[];
        };
    };
    pageParam: { page: number; size: number };
    projectId: number | undefined;
}

export interface QueryResponse {
    tags: {
        id: number;
        name: string;
    }[];
    dataStory: {
        id: number;
        createdDate: number;
        title: string;
        description: string;
        embeddedCode: string;
        imageLink: string | null;
        createdBy: string;
        createdByUsername: string;
        tagslist: {
            id: number;
            name: string;
        }[];
        imageName: string | null;
        contractName: string;
        mentionedList: string[];
    }[];
    startDate: number | null;
    endDate: number | null;
    token: string | null;
}
