import { useThemeStatusStore } from 'store/themeStatus.store';
import { LineChart } from 'components/common/charts/lineChart/lineChart';
import { GraphPoints } from 'api/queries/getProjectStatusScoreCards.query';
import {
    serializeData,
    serializeOptions,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramStatusSummaryReport/ScorecardsSection/QualityCard/qualityCard.utils';
interface Props {
    data: [
        {
            name: string;
            graphPoints: GraphPoints[];
        },
    ];
    xaxisTitle: string;
    yaxisTitle: string;
    yAxisMaxValue: number | undefined;
}

export const QualityCardGraph = ({ data, xaxisTitle, yaxisTitle, yAxisMaxValue }: Props) => {
    const { themeStatus } = useThemeStatusStore();

    const series = serializeData({ data: data[0], themeStatus });
    const options = serializeOptions({ data: data[0], themeStatus, xaxisTitle, yaxisTitle, yAxisMaxValue });

    return <LineChart series={series} options={options} noData={false} isFetching={false} height={300} />;
};
