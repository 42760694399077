import React from 'react';
import classes from './CustomizedTabs.module.scss';
import { kebabCase } from 'lodash';

export interface ITab<T> {
    value: T;
    label: React.ReactNode;
}

export interface ICustomizedTabsProps<T> {
    tabs: ITab<T>[];
    activeTab: T;
    setActiveTab: (value: T) => void;
    containerClass?: string;
    wrapperClass?: string;
}

export const CustomizedTabs = <T extends string>({
    tabs,
    activeTab,
    setActiveTab,
    containerClass,
    wrapperClass,
}: ICustomizedTabsProps<T>) => {
    return tabs.length > 0 ? (
        <div data-testid={'customized-tabs'} className={`${classes.tabsContainer} ${containerClass ?? ''}`}>
            {tabs.map((item, index) => (
                <div
                    data-testid={'tab number' + index}
                    data-track-id={kebabCase(`tab-${item.value}`)}
                    className={`${classes.tabWrapper} ${activeTab === item.value ? classes.active : ''} ${wrapperClass ?? ''}`}
                    key={index}
                    onClick={() => {
                        item.value && setActiveTab(item.value);
                    }}
                >
                    {item.label}
                </div>
            ))}
        </div>
    ) : null;
};
