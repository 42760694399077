import moment from 'moment-timezone';
import constants from 'components/common/Constants/constants';
import { TooltipCell } from 'components/common/Tables/Cells/tooltipCell';

export const columns = [
    {
        Header: 'Version ID',
        accessor: 'projectFileId',
        width: 160,
        fixedSize: true,
    },
    {
        Header: 'Version Name',
        accessor: 'projectFileName',
        Cell: ({ value }) => {
            return <TooltipCell value={value} />;
        },
    },
    {
        Header: 'Version Date',
        accessor: 'projectFileDate',
        Cell: ({ value }) => {
            return moment(value).format(constants.formats.date.default);
        },
        width: 200,
        fixedSize: true,
    },
    {
        Header: 'Critical Path',
        accessor: 'criticalActivityDefinition',
        width: 220,
        fixedSize: true,
    },
    {
        Header: 'Source File',
        accessor: 'sourceFile',
        Cell: ({ value }) => {
            return <TooltipCell value={value} />;
        },
    },
];
