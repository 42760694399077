import { useQuery } from 'react-query';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { network } from 'services/network';

const getRelationActivity = (params) => {
    const url = `${API_URL().FETCH_PROGRAM_SCOPE_TO_GO_ACTIVITY_RELATIONS}?taskId=${params.taskId}`;

    return network
        .get(url)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const useQueryGetRelationActivity = (params) => {
    return useQuery<IRelationActivityResponse, QueryError, IRelationActivityResponse>(
        ['relationActivity ', params],
        () => getRelationActivity(params),
        {
            enabled: Boolean(params.taskId),
        },
    );
};

export interface ITableDataItem {
    id: number;
    activityCode: string;
    activityName: string;
    startDate: number;
    finishDate: number;
    activityStatus: 'To Do' | 'In Progress' | 'Done' | null;
    lateStart: number;
    lateFinish: number;
    durationOverun: number;
    criticalityScore: number;
    delayDriver: boolean;
    float: number | null;
    lag: number | null;
    type: string | null;
}

export interface IRelationActivityResponse {
    activityId: string;
    activityName: string;
    activityStatus: string;
    activityType: string;
    startDate: number;
    finishDate: number;
    projectWeekStart: number;
    projectWeekFinish: number;
    criticalPath: boolean;
    criticalityScore: number;
    wbsLevel: number;
    predeccessors: ITableDataItem[] | null;
    successors: ITableDataItem[] | null;
    ancestors: ITableDataItem[] | null;
    decedents: ITableDataItem[] | null;
    precedingActivities: number;
    succeedingActivites: number;
    precedingRisk: string;
    succeedingRisk: string;
    activityCode: {
        alias: string | null;
        description: string;
        id: number;
        name: string;
        scope: string;
        typeName: string;
        typeUid: number;
        uid: number;
    }[];
    resourceData:
        | { resourceName: string; resourceType: string; resourceUnit: number; resourceMeasure: string }[]
        | null;
    activityConstraints: {
        constraintName: string;
        constraintDate: number | null;
        constraintGroup: string | null;
    };
}
