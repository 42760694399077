import { ConfirmSaveModal, MyCustomSelect } from 'components/common';
import { useEffect, useState } from 'react';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import {
    defaultOverdueAndUrgentDateThreshold,
    TDefaultOverdueAndUrgentDateThreshold,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/SubProgramPreferences/subProgramPreferences.utils';
import {
    useMutationSetOverdueAndUrgentDateThreshold,
    useQueryOverdueAndUrgentDateThreshold,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/SubProgramPreferences/queries/useQuerySubProgramPreferences';
import { useProjectStore } from 'store/project.store';
import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/SubProgramPreferences/components/subProgramPreferences.module.scss';

export const OverdueAndUrgentDateThreshold = () => {
    const {
        selectedProject: { contract },
    } = useProjectStore();
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const [selectedValue, setSelectedValue] = useState<ISelectOption<TDefaultOverdueAndUrgentDateThreshold> | null>(
        null,
    );
    const { handleSnackBar } = useCustomSnackBar();
    const { data, refetch } = useQueryOverdueAndUrgentDateThreshold({ subProgramId: contract?.id });
    const mutation = useMutationSetOverdueAndUrgentDateThreshold();

    useEffect(() => {
        if (data) {
            setSelectedValue(defaultOverdueAndUrgentDateThreshold.find((item) => item.value === data) || null);
        }
    }, [data]);

    const handleHideModal = () => {
        setShowConfirmModal(false);
        setSelectedValue(defaultOverdueAndUrgentDateThreshold.find((item) => item.value === data) || null);
    };

    const handleOverdueAndUrgentDateThreshold = (
        value: ISelectOption<TDefaultOverdueAndUrgentDateThreshold> | null,
    ) => {
        if (value?.value !== selectedValue?.value) {
            setShowConfirmModal(true);
            setSelectedValue(defaultOverdueAndUrgentDateThreshold.find((item) => item.value === value?.value) || null);
        }
    };

    const handleSave = () => {
        setShowConfirmModal(false);
        mutation.mutate(
            { subProgramId: contract?.id, option: selectedValue?.value },
            {
                onSuccess: () => handleSnackBar('Overdue & Urgent Date Threshold has changed', 'success'),
                onError: (error) => {
                    handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
                },
                onSettled: () => refetch(),
            },
        );
    };
    return (
        <>
            <span className={classes.title}>Overdue & Urgent Date Threshold:</span>
            <MyCustomSelect<ISelectOption<TDefaultOverdueAndUrgentDateThreshold>>
                id={'sub-program-preferences-date-threshold'}
                value={selectedValue}
                options={defaultOverdueAndUrgentDateThreshold}
                onChange={handleOverdueAndUrgentDateThreshold}
                isSearchable={false}
            />
            <ConfirmSaveModal
                isShowModal={showConfirmModal}
                onHideModal={handleHideModal}
                onConfirm={handleSave}
                isLoading={mutation.isLoading}
            >
                You have made changes. Would you like to save them?
            </ConfirmSaveModal>
        </>
    );
};
