import { useQuery } from 'react-query';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { network } from 'services/network';
import { QueryKeys } from 'api/queryKeys';

export const queryFn = ({ latestVersion }: Pick<QueryParams, 'latestVersion'>) => {
    const url = `${API_URL().FETCH_PROGRAM_DASHBOARD_GET_ACTIVITY_CODES}?versionId=${latestVersion}`;
    return network.get(url).then((response) => {
        return response.data;
    });
};
export const useQueryActivityCodesByVersion = (params: QueryParams) => {
    return useQuery<QueryResponse[], QueryError, QueryResponse[], [QueryKeys, unknown]>({
        queryKey: ['activityCodesByVersion', params],
        queryFn: () => queryFn({ latestVersion: params.latestVersion as number }),
        enabled: Boolean(params.latestVersion),
    });
};

export const useQueryActivityCodesByVersionAndWidgetId = (params: QueryParams) => {
    return useQuery<QueryResponse[], QueryError, QueryResponse[], [QueryKeys, unknown]>({
        queryKey: ['activityCodesForWidget', params],
        queryFn: () => queryFn(params),
        enabled: Boolean(params.latestVersion) && Boolean(params.widgetId) && Boolean(params.hasActivityCode),
    });
};

export interface QueryResponse {
    alias: string | null;
    description: string;
    id: number;
    name: string;
    scope: string;
    typeName: string;
    typeUid: number;
    uid: number;
    activitiesCount: number;
    activtyCodeValueId: string;
}

interface QueryParams {
    latestVersion: number | undefined;
    widgetId: string | undefined;
    hasActivityCode: boolean | undefined;
}
