import classes from './crossVersionsAnalysisGraph.module.scss';
import { MyCustomSelect } from 'components/common';
import { InputOption, ValueContainer } from 'components/common/MyCustomSelect/myCustomSelect';
import { RadioComponent } from 'components/common/RadioComponent/radioComponent';
import { useThemeStatusStore } from 'store/themeStatus.store';
import { MuiIcon } from 'components/common/muiIcon/muiIcon';
import {
    serializeData,
    serializeOptions,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramCrossVersions/CrossVersionsAnalysisIntensity/crossVersionsAnalysisGraph.utils';
import { LineChart } from 'components/common/charts/lineChart/lineChart';
import React, { useEffect, useState } from 'react';
import { GraphData } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramCrossVersions/components/programCrossVersions';

interface SelectedOptions extends ISelectOption<string> {
    note?: string;
}

interface Props {
    data: GraphData;
    handleSingle: (event: React.ChangeEvent<HTMLInputElement>) => void;
    options: ISelectOption<string>[];
    selectedOptions: SelectedOptions | SelectedOptions[];
    handleItemSelection: (value: ISelectOption<string> | ISelectOption<string>[]) => void;
    singleLine: SingleLineOptions;
}

export type SingleLineOptions =
    | 'Pre-defined Combinations (Normalized values)'
    | 'Multi Parameters (Normalized values)'
    | 'Single Parameter (Actual value)';

const CrossVersionsAnalysisGraph = ({
    data,
    handleSingle,
    options,
    selectedOptions,
    handleItemSelection,
    singleLine,
}: Props) => {
    const [lineChartRender, setLineChartRender] = useState(true);
    const { themeStatus } = useThemeStatusStore();
    const isMultiple = singleLine === 'Multi Parameters (Normalized values)';
    const isNote = !Array.isArray(selectedOptions) && selectedOptions.note;
    const handleSelectOptions = (value) => {
        handleItemSelection(value);
    };

    const controlProps = (item: SingleLineOptions) => ({
        checked: singleLine === item,
        onChange: handleSingle,
        value: item,
        name: 'show-option',
    });

    const series = serializeData(data.dataset);
    const graphOptions = serializeOptions({
        series,
        themeStatus,
    });

    useEffect(() => {
        setLineChartRender(false);
        setTimeout(() => {
            setLineChartRender(true);
        }, 0);
    }, [singleLine]);

    return (
        <div className={classes.CrossVersionsAnalysisGraphContainer}>
            <div className={classes.selectParametersWrapper}>
                <div className={classes.radiosRow}>
                    <span>Show: </span>
                    <div className={classes.radioContainer}>
                        <label>
                            <RadioComponent {...controlProps('Pre-defined Combinations (Normalized values)')} />
                            Pre-defined Combinations (Normalized values)
                        </label>
                        <label>
                            <RadioComponent {...controlProps('Multi Parameters (Normalized values)')} />
                            Multi Parameters (Normalized values)
                        </label>
                        <label>
                            <RadioComponent {...controlProps('Single Parameter (Actual value)')} />
                            Single Parameter (Actual value)
                        </label>
                    </div>
                </div>
                <div data-testid={'select-row'} className={classes.selectRow}>
                    <MyCustomSelect<ISelectOption<string>, typeof isMultiple>
                        value={selectedOptions}
                        options={options}
                        onChange={handleSelectOptions}
                        closeMenuOnSelect={!isMultiple}
                        hideSelectedOptions={!isMultiple}
                        isMulti={isMultiple}
                        placeholder={'Select Items...'}
                        components={
                            isMultiple
                                ? {
                                      Option: InputOption,
                                      ValueContainer: ValueContainer,
                                  }
                                : {}
                        }
                    />
                </div>
                {isNote && (
                    <div className={classes.lookForContainer}>
                        <MuiIcon icon={'report'} color={'#4c9bfa'} className={classes.reportIcon} />
                        {isNote}
                    </div>
                )}
            </div>

            {lineChartRender && <LineChart series={series} options={graphOptions} height={760} />}
        </div>
    );
};

export default CrossVersionsAnalysisGraph;
