import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { useQuery } from 'react-query';
import { QueryKeys } from 'api/queryKeys';

const getAssigneeList = () => {
    return network.post(API_URL().GET_ASSIGNEE_LIST).then((response) => response.data);
};
export const useQueryAssigneeList = () => {
    return useQuery<QueryResponse[], QueryError, QueryResponse, [QueryKeys]>({
        queryKey: ['assigneeList'],
        queryFn: () => getAssigneeList(),
    });
};

interface QueryResponse {
    firstName: string;
    id: null;
    lastName: string;
    userName: string;
}
