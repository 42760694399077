import { useQuery } from 'react-query';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { network } from 'services/network';
import {
    IActivityCode,
    IActivityType,
    IAssignee,
    ICardImportant,
    ICardType,
    ICardUrgent,
    ICategory,
    IProjectContractVersions,
    IStatus,
    IStoredFilters,
    ITagList,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/battlecards.types';
import { QueryKeys } from 'api/queryKeys';
import qs from 'qs';

const queryFn = ({ projectId }: QueryParams) => {
    const querystring = qs.stringify({ projectId }, { addQueryPrefix: true, skipNulls: true });
    const url = `${API_URL().FETCH_PROGRAM_SCOPE_TO_GO_GET_CARD_INIT}${querystring}`;
    return network.get(url).then((response) => {
        return response.data;
    });
};

export const useQueryGetBoardInitLite = (params: QueryParams) => {
    return useQuery<QueryResponse, QueryError, QueryResponse, [QueryKeys, unknown]>({
        queryKey: ['getBoardInitLite', params],
        queryFn: () => queryFn(params),
        enabled: Boolean(params.projectId),
    });
};

interface QueryParams {
    projectId?: number;
}

export interface QueryResponse {
    activityCode: IActivityCode | null;
    assignee: IAssignee[];
    cardActivityType: IActivityType[];
    cardImportant: ICardImportant[];
    cardType: ICardType[];
    cardUrgent: ICardUrgent[];
    category: ICategory[];
    projectContractVersions: IProjectContractVersions[];
    status: IStatus[];
    storedFilters: IStoredFilters[];
    tags: ITagList[];
}
