import { Link } from 'react-router-dom';
import React from 'react';
import classes from './ActionMenu.module.scss';
import useUserHook from 'hooks/useUserHook';
import { projectConfig } from 'components/Dashboards/Project/ProjectWrapper/projectConfig';
import { excellenceAppsConfig } from 'components/Dashboards/ExcellenceApps/excellenceAppsConfig';

export interface LinkProps {
    id: string;
    link: string;
    search: string;
    title: string;
}

export const getLinks = (links: LinkProps[]) => {
    return links.map((linkObject, index) => (
        <Link
            className={classes.actionMenuLink}
            key={index}
            id={linkObject.id}
            to={{ pathname: linkObject.link, state: { fromActionMenu: true }, search: linkObject.search }}
        >
            {linkObject.title}
        </Link>
    ));
};

export const prepareLinks = (id: number) => {
    const { ability } = useUserHook();
    const criticalLink = `/dashboard/project/${projectConfig.criticalityAnalysis.link}/${id}`;
    const progressOverviewLink = `/dashboard/project/${projectConfig.progressOverview.link}/${id}`;
    const clusterrcfanalysisLink = `/dashboard/program/${excellenceAppsConfig.durationForecast.link}/${id}`;
    const clusterrcfanalysisCustomLink = `/dashboard/program/${excellenceAppsConfig.durationForecast.link}/${id}`;
    const logicCheckerLink = `/dashboard/program/${excellenceAppsConfig.logicQualityCheck.link}/${id}`;
    const ganttChartLink = `/dashboard/project/${projectConfig.ganttChart.link}/${id}`;
    const crossVersionAnalysisLink = `/dashboard/project/${projectConfig.crossVersionsAnalysis.link}/${id}`;
    const dataStoriesLink = `/dashboard/project/${projectConfig.dataStories.link}`;
    const statusSummaryLink = `/dashboard/project/${projectConfig.statusSummary.link}/${id}`;
    const battleCardsLink = `/dashboard/project/${projectConfig.battlecards.link}/${id}`;

    return {
        singleProgram: [
            {
                link: statusSummaryLink,
                title: projectConfig.statusSummary.title,
                id: 'StatusSummary',
            },
            {
                link: battleCardsLink,
                title: projectConfig.battlecards.title,
                id: 'BattleCards',
                search: 'cardActivity=1,2',
            },
            {
                link: dataStoriesLink,
                title: projectConfig.dataStories.title,
                id: 'DataStories',
            },
            {
                link: progressOverviewLink,
                title: projectConfig.progressOverview.title,
                id: 'ProgressOverview',
            },
            {
                link: criticalLink,
                title: projectConfig.criticalityAnalysis.title,
                id: 'CriticalityAnalysis',
            },
            {
                link: crossVersionAnalysisLink,
                title: projectConfig.crossVersionsAnalysis.title,
                id: 'CrossVersionAnalysis',
            },
            {
                link: ganttChartLink,
                title: projectConfig.ganttChart.title,
                id: 'GanttChart',
            },

            {
                link: clusterrcfanalysisLink,
                title: excellenceAppsConfig.durationForecast.title,
                id: 'ForecastDuration',
            },
            {
                link: clusterrcfanalysisCustomLink,
                title: `${excellenceAppsConfig.durationForecast.title} Custom Search`,
                id: 'ForecastDurationCustomSearch',
                search: '?option=custom',
            },
            {
                link: logicCheckerLink,
                title: excellenceAppsConfig.logicQualityCheck.title,
                id: 'LogicChecker',
            },
        ].filter((item) => (ability.can('all', 'Admin') ? item : !item.isAdmin)),
        multipleWithContractProgram: [
            {
                link: statusSummaryLink,
                title: projectConfig.statusSummary.title,
                id: 'StatusSummary',
            },
            {
                link: progressOverviewLink,
                title: projectConfig.progressOverview.title,
                id: 'ProgressOverview',
            },
            {
                link: criticalLink,
                title: projectConfig.criticalityAnalysis.title,
                id: 'CriticalityAnalysis',
            },
            {
                link: crossVersionAnalysisLink,
                title: projectConfig.crossVersionsAnalysis.title,
                id: 'CrossVersionAnalysis',
            },
            {
                link: clusterrcfanalysisLink,
                title: excellenceAppsConfig.durationForecast.title,
                id: 'ForecastDuration',
            },
            {
                link: clusterrcfanalysisCustomLink,
                title: `${excellenceAppsConfig.durationForecast.title} Custom Search`,
                id: 'ForecastDurationCustomSearch',
                search: '?option=custom',
            },
        ],

        multipleWithoutContractProgram: [
            {
                link: clusterrcfanalysisLink,
                title: excellenceAppsConfig.durationForecast.title,
                id: 'ForecastDuration',
            },
            {
                link: clusterrcfanalysisCustomLink,
                title: `${excellenceAppsConfig.durationForecast.title} Custom Search`,
                id: 'ForecastDurationCustomSearch',
                search: '?option=custom',
            },
        ],
    };
};
