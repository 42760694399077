import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { useQuery } from 'react-query';
import { QueryKeys } from 'api/queryKeys';
import { CompareOptions } from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/CompareTo/CompareTo';

const queryFn = (params: QueryParams) => {
    return network
        .post(API_URL().FETCH_PROGRAM_DASHBOARD_RCF_CLUSTER_DATA, {
            clusterId: params.clusterId,
            paFileMetaDataIds: params.idList,
            clusterType: params.compare,
        })
        .then((response) => {
            return response.data;
        });
};

export const useQueryClustersData = (params: QueryParams) => {
    return useQuery<QueryResponse, QueryError, QueryResponse, [QueryKeys, unknown]>({
        queryKey: ['clustersData', params],
        queryFn: () => queryFn(params),
        enabled:
            params.idList.length > 0 &&
            Boolean(params.clusterId) &&
            params.isAutomatedClusteringOption &&
            Boolean(params.compare),
        staleTime: 300000,
    });
};

interface QueryParams {
    idList: number[];
    clusterId: number | undefined;
    isAutomatedClusteringOption: boolean;
    compare: CompareOptions | undefined;
}

export interface QueryResponse {
    id: number;
    name: string;
    statistics: {
        totalActivities: number;
        completedActivities: number;
        tableData: {
            plannedDuration: {
                mean: number;
                median: number;
                std: number;
            };
            actualDuration: {
                mean: number;
                median: number;
                std: number;
            };
            daysOverrun: {
                mean: number;
                median: number;
                std: number;
            };
            percentOverrun: {
                mean: number;
                median: number;
                std: number;
            };
        };
        narrativeData: {
            plannedDuration: {
                pTypical: number;
                pExtreme: number;
            };
            completedDuration: {
                pTypical: number;
                pExtreme: number;
            };
            overrunDays: {
                pTypical: number;
                pExtreme: number;
            };
            overrunRatio: {
                pTypical: number;
                pExtreme: number;
            };
        };
        info: IToolTip;
    };
    durationDistribution: {
        planned: {
            min: number;
            firstQuartile: number;
            median: number;
            thirdQuartile: number;
            max: number;
            outliers: number[];
        };
        actual: {
            min: number;
            firstQuartile: number;
            median: number;
            thirdQuartile: number;
            max: number;
            outliers: number[];
        };
        info: IToolTip;
    };
    rcf: {
        duration: {
            percentile: number;
            value: number;
        }[];
        ratio: {
            percentile: number;
            value: number;
        }[];
        info: IToolTip;
    };
    data: {
        info: IToolTip;
        clusterActivities: {
            id: number;
            activityId: string;
            activityName: string;
            activityType: string;
            plannedStartDate: number;
            plannedFinishDate: number;
            actualStartDate: number;
            actualFinishDate: number;
            plannedDuration: number;
            completedDuration: number;
            overrunDays: number;
            overrunPercent: number;
            projectWeekStart: number;
            projectWeekFinish: number;
            projectMonthStart: number;
            projectMonthFinish: number;
            percentileRank: null;
            criticalPath: boolean;
            criticalityScore: number;
            delayDriver: boolean;
            parentName: string;
            grandParentName: string;
            actualDuration: number;
            remainingDuration: number;
            float: null;
            currentStartDate: number;
            currentFinishDate: number;
            baselineStartDate: number;
            baselineFinishDate: number;
            forecastDuration: null;
            activityStatus: string;
            lateStart: number;
            lateFinish: number;
            durationOverun: number;
            forecastOverrun: null;
            versionName: string;
            numOfPredecessors: number;
            numOfSuccessors: number;
            taskHashCode: null;
            taskId: null;
            precedingActivities: number;
            succeedingActivites: number;
            precedingRisk: string;
            succeedingRisk: string;
            projectName: string;
            contractName: string;
            clusterId: null;
        }[];
    };
    narrative: null;
}
