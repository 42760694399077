import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { useQuery } from 'react-query';
import { QueryKeys } from 'api/queryKeys';
import { IFilter } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';

const queryFn = (params: QueryParams) => {
    return network.post(API_URL().FETCH_PROGRAM_DASHBOARD_HEATMAP_START_OR_FINISH, params).then((response) => {
        return response.data;
    });
};
export const useQueryHeatMapActivityStartOrFinish = (params: QueryParams) => {
    return useQuery<QueryResponse, QueryError, QueryResponse, [QueryKeys, unknown]>({
        queryKey: ['heatMapActivityStartOrFinish', params],
        queryFn: () => queryFn(params),
        enabled: params.id.length > 0,
        cacheTime: 0,
    });
};

interface QueryParams {
    id: number[];
    filter: {
        filters: IFilter[];
    };
    startDate: number | null;
    endDate: number | null;
    isWeekResolution: boolean;
}

interface IHeatMapData {
    endDate: number;
    numOfDelayDrivers: number;
    numOfTasksPerMonth: number;
    percentile: number;
    score: number;
    startDate: number;
}

interface QueryResponse {
    info?: IToolTip;
    startProjectDate: number;
    endProjectDate: number;
    heatMapItemResponseList: IHeatMapData[];
}
