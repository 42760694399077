import ScatterGraph from 'components/common/Lollipop/ScatterGraph/ScatterGraph';
import { useEffect, useMemo, useState } from 'react';
import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/CommonComponents/CriticalAnalysisMilestone/criticalAnalysisMilestone.module.scss';
import moment from 'moment-timezone';
import NoData from 'components/common/NoData/noData';
import { useThemeStatusStore } from 'store/themeStatus.store';
import {
    getApexOptions,
    getApexSeries,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/CommonComponents/CriticalAnalysisMilestone/criticalAnalysisMilestone.utils';
import { BarChart } from 'components/common/charts/barChart/barChart';
import { QueryResponse } from 'api/queries/getMilestones.query';
import { ApexOptions, getChartByID } from 'apexcharts';

export const serializeScatter = (data) => {
    return data.map((item, index) => {
        return {
            title: item.toolTip,
            date: item.calculatedDate,
            wbs: item.wbsLevel,
            wbsName: item.wbsName,
            delayDriver: item.delayDriver,
            milestoneStatus: item.milestoneStatus,
            y: index,
        };
    });
};

interface Props {
    milestones: QueryResponse;
}

const CriticalAnalysisMilestone = ({ milestones }: Props) => {
    const { themeStatus } = useThemeStatusStore();
    const [range, setRange] = useState<{ minDate: Date | null; maxDate: Date | null }>({
        minDate: null,
        maxDate: null,
    });

    const nowDate = useMemo(() => moment(milestones.nowDateNotFormatted || '201010').toDate(), [milestones]);

    const series: ApexAxisChartSeries = getApexSeries({ data: milestones.additionalData });
    const options: ApexOptions = getApexOptions({ themeStatus });

    useEffect(() => {
        if (range.minDate && range.maxDate) {
            const chart = getChartByID('milestones-chart');
            if (chart) {
                chart?.zoomX(moment(range.minDate).valueOf(), moment(range.maxDate).valueOf());
            }
        }
    }, [range]);

    return (
        <div className={classes.CriticalAnalysisMilestoneContainer}>
            {milestones?.milestones?.length === 0 ? (
                <NoData />
            ) : (
                <>
                    <div className={classes.MilestoneMainGraphContainer}>
                        <ScatterGraph
                            dataset={serializeScatter(milestones.milestones)}
                            nowDate={nowDate}
                            groupBy={'wbsName'}
                            callback={setRange}
                        />
                    </div>
                    <div className={classes.MilestoneVolumeGraphContainer}>
                        <BarChart series={series} options={options} noData={false} isFetching={false} height={180} />
                    </div>
                </>
            )}
        </div>
    );
};

export default CriticalAnalysisMilestone;
