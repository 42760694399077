import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { useQuery } from 'react-query';
import { QueryResponse as IBoardInitResponse } from 'api/queries/getBoardInitLite.query';

const getBoardInit = (params) => {
    const url = `${API_URL().FETCH_PROGRAM_SCOPE_TO_GO_GET_BOARD_INIT}?metaDataId=${params.metaDataId}`;

    return network
        .get(url)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const useQueryGetBoardInit = (params) => {
    return useQuery<IBoardInitResponse, QueryError>(['boardInit', params], () => getBoardInit(params), {
        enabled: Boolean(params.metaDataId),
    });
};
