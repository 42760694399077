import { useQuery } from 'react-query';
import { CardType } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/helper';
import {
    IAssignee,
    ICardIndicators,
    ICategory,
    IComment,
    IContract,
    IExternalLink,
    IProject,
    IRelatedActivity,
    IStatus,
    ITagList,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/battlecards.types';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { network } from 'services/network';
import qs from 'qs';
import { QueryKeys } from 'api/queryKeys';

const queryFn = (params) => {
    let idParam = params.id;
    let isVirtualParam = params.isVirtual;

    if (params.id !== 'newCard') {
        const _id = params.id.split('-');
        if (_id[1]) {
            idParam = _id[0];
            isVirtualParam = true;
        }
    }

    const queryString = qs.stringify(
        { id: idParam, isVirtual: isVirtualParam, versionId: params.versionId },
        { addQueryPrefix: true, skipNulls: true },
    );

    const url = `${API_URL().FETCH_PROGRAM_SCOPE_TO_GO_GET_CARD}${queryString}`;

    return network.get(url).then((response) => {
        return response.data;
    });
};
export const useQueryGetCard = (params) => {
    return useQuery<QueryResponse, QueryError, QueryResponse, [QueryKeys, unknown]>({
        queryKey: ['getCard', params],
        queryFn: () => queryFn(params),
        enabled: Boolean(params.id) && params.id !== 'newCard',
    });
};

export interface QueryResponse {
    id: number;
    type: {
        id: number;
        name: CardType;
        label: string;
    };
    category: ICategory;
    project: IProject;
    contract: IContract;
    assignee: IAssignee | null;
    laneId: {
        id: string | number;
        name: string;
        label: string;
    };
    title: string;
    details: string;
    relatedActivity: IRelatedActivity;
    mentionedList: string[];
    startDate: number;
    endDate: number;
    baselinePlannedStartDate: number;
    baselinePlannedEndDate: number;
    comments: IComment[];
    tagList: ITagList[];
    externalLinks: IExternalLink[];
    relatedCardIds: null;
    taskHashCode: string;
    taskVersionHashCode: string;
    taskId: number;
    rank: number;
    metaData: {
        id: number;
        versionName: string;
        sourceFile: string;
        versionDate: number;
    };
    duration: number;
    float: number | null;
    isVirtual: boolean;
    additionalData: null;
    recommendationType: null;
    isTracked: boolean;
    submittedDurationComplete: number;
    isStartOverdue: boolean;
    isFinishOverdue: boolean;
    cardIndicators: ICardIndicators;
    isLoose: boolean;
    isImportant: boolean;
    originalStatus: IStatus;
    status: IStatus;
    activityConstraints: {
        constraintName: string;
        constraintDate: number | null;
        constraintGroup: string | null;
    };
    remainingDurationInDays: number | null;
}
