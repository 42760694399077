import { useThemeStatusStore } from 'store/themeStatus.store';
import {
    serializeData,
    serializeOptions,
} from 'components/Dashboards/Project/Components/VersionCompare/components/WidgetCardGraph.utils';
import { LineChart } from 'components/common/charts/lineChart/lineChart';

export interface GraphPoints {
    versionName: string;
    versionDate: number;
    score: number;
    status: string;
    versionHashCode: number;
}

interface Props {
    data: GraphPoints[];
    xaxisTitle: string;
    yaxisTitle: string;
    yAxisMaxValue: number | undefined;
}

export const WidgetCardGraph = ({ data, xaxisTitle, yaxisTitle, yAxisMaxValue }: Props) => {
    const { themeStatus } = useThemeStatusStore();

    const series = serializeData({ data: data, themeStatus, yaxisTitle });
    const options = serializeOptions({ data: data, themeStatus, xaxisTitle, yaxisTitle, yAxisMaxValue });

    return <LineChart series={series} options={options} noData={false} isFetching={false} height={350} />;
};
