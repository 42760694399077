import { useMutation } from 'react-query';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { network } from 'services/network';

const mutationFn = (params: QueryParams) => {
    const url = `${API_URL().REFRESH_PREDICTION_DATA}?contractId=${params.contractId}`;

    return network.get(url).then((response) => {
        return response.data;
    });
};
export const useQueryRefreshPredictionData = () => {
    return useMutation<string, QueryError, QueryParams>({
        mutationFn: (params) => mutationFn(params),
    });
};

interface QueryParams {
    contractId: number;
}
