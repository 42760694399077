import moment, { MomentInput } from 'moment-timezone';

/**
 * Generates a list of objects for months filter - used in activities table
 * Example:
 * startDate = 1639143508851
 * endDate = 1649594319283
 * dateFilter = '202112'
 * getMonths({startDate, endDate}) =>
 * 0: {label: 'All Months', value: All Months}
 * 1: {label: 'Dec 2021', value: '202112'}
 * 2: {label: 'Jan 2022', value: '202201'}
 * 3: {label: 'Feb 2022', value: '202202'}
 * 4: {label: 'Mar 2022', value: '202203'}
 * 5: {label: 'Apr 2022', value: '202204'}
 * @param startDate
 * @param endDate
 * @return {{value: *, label: *}[]|*[]}
 */

export const getMonths = ({
    startDate,
    endDate,
}: {
    startDate: MomentInput;
    endDate: MomentInput;
}): ISelectOption<string>[] => {
    const arr: ISelectOption<string>[] = [];
    if (startDate && endDate) {
        const start = moment(startDate);
        const end = moment(endDate);
        const months = Array.from({ length: end.diff(start, 'month') + 1 }).map((_, index) =>
            moment(start).add(index, 'month'),
        );
        months.forEach((m) => {
            arr.push({
                value: m.format('yyyyMM'),
                label: m.format('MMM yyyy'),
            });
        });
    }
    arr.unshift({
        label: 'All Months',
        value: 'All Months',
    });
    return arr;
};
