import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { useMutation } from 'react-query';

const mutationFn = (params: QueryParams) => {
    return network.post(API_URL().CREATE_PROJECT, params).then((response) => {
        return response.data;
    });
};
export const useMutationCreateProject = () => {
    return useMutation<string, QueryError, QueryParams>({
        mutationFn: (params) => mutationFn(params),
    });
};

interface QueryParams {
    customerId: number;
    initialContractName: string;
    projectName: string;
    userGroups: string[];
}
