import moment from 'moment-timezone';
import { QueryResponse } from 'api/queries/getMilestones.query';
import { ApexOptions } from 'apexcharts';

export const getApexSeries = ({ data }: { data: QueryResponse['additionalData'] }) => {
    const delayDrivers = {
        name: 'Delay Drivers',
        data: data.map((d) => {
            return {
                x: moment(d.month).toDate(),
                y: d.positiveDelayDrivers,
            };
        }),
        color: '#cc0000',
    };

    const totalMilestones = {
        name: 'Total Milestones',
        data: data.map((d) => {
            return {
                x: moment(d.month).toDate(),
                y: d.numOfMilestones,
            };
        }),
        color: '#7d8a93',
    };
    return [totalMilestones, delayDrivers];
};

export const getApexOptions = ({ themeStatus }: { themeStatus: boolean }): ApexOptions => {
    return {
        dataLabels: {
            enabled: false,
        },
        legend: {
            show: false,
        },
        xaxis: {
            type: 'datetime',
        },
        tooltip: {
            hideEmptySeries: true,
            theme: themeStatus ? 'dark' : 'light',
        },
        chart: {
            id: 'milestones-chart',
            background: themeStatus ? '#000000' : '#ffffff',
            foreColor: themeStatus ? '#FEFEFE' : '#000000',
            toolbar: {
                show: false,
            },
            events: {
                beforeZoom(chart: any) {
                    if (chart.wheelDelay) {
                        return {
                            xaxis: {
                                min: chart.minX,
                                max: chart.maxX,
                            },
                        };
                    }
                    return undefined;
                },
            },
        },
    };
};
