import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import React, { useEffect, useMemo, useState } from 'react';
import { useMutationExportCompletionPlanToCsv } from 'api/mutations/exportCompletionPlanToCsv.mutation';
import classes from 'components/Dashboards/Project/Components/VersionCompare/style/Table.module.scss';
import { CustomizedTabs } from 'components/common/CustomizedTabs/CustomizedTabs';
import {
    initialState,
    columnsV8,
    generateTabs,
    TKeys,
    IActivityCompletionList,
} from 'components/Dashboards/ExcellenceApps/FinishDatePredictionPage/components/activityCompletionPlanComponent/utils';
import { CustomizedRoundIcon, CustomizedTooltip } from 'components/common';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import Icon from 'components/common/Icons/icon';
import { AddWidget } from 'components/Dashboards/Project/Components/CustomDashboard/AddRemoveWidget/addWidget';
import { ExportDropdown } from 'components/common/ExportDropdown/exportDropdown';
import { CustomTableColumnsModal } from 'components/common/Modals/CustomTableColumns/customTableColumnsModal';
import useCustomPageSubTitle from 'hooks/useCustomPageSubTitle';
import useTableCurrentState from 'hooks/useTableCurrentState';
import { IComponentProps, IFilter } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import { FilterNames, getFiltersData } from 'components/common/GlobalFilterComponent/GlobalFilterComponent.utils';
import { WidgetFilters } from 'components/Dashboards/Project/Components/CustomDashboard/WidgetFilters/widgetFilters';
import { useGlobalFiltersHook } from 'hooks/useGlobalFiltersHook';
import { prepareVersionSelectTitle } from 'components/common/TreeProgramFilter/VersionSelect/versionSelect.utils';
import { excellenceAppsConfig } from 'components/Dashboards/ExcellenceApps/excellenceAppsConfig';
import { FragnetSummary } from 'components/Dashboards/ExcellenceApps/FinishDatePredictionPage/components/activityCompletionPlanComponent/fragnetSummary';
import { ShareInsightButton } from 'components/common/ShareInsightButton/ShareInsightButton';
import { SortFilterPanel } from 'components/Dashboards/ExcellenceApps/FinishDatePredictionPage/components/activityCompletionPlanComponent/sortFilterPanel';
import NoData from 'components/common/NoData/noData';
import { TableV8 } from 'components/common/Tables/TableV8/tableV8';
import { useReactTableState } from 'hooks/useReactTableState';
import { CustomPagination } from 'components/common/pagination/customPagination';
import { serializeColumnsVisibility } from 'components/Dashboards/widgets/keyActivities/utils';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { useQueryActivityCompletionPlan } from 'api/queries/activityCompletionPlan.query';
import { useActivitiesCompletionPlanStore } from 'store/completionPlanPerProgres.store';
import { getStringWithoutHash } from 'utilitys/helpers/general';

export const ActivitiesPredecessors = ({
    contractId,
    latestVersionId,
    projectId,
    widgetId,
    filter,
    externalComponents = null,
    editNarrative = null,
    setSubTitleForExport,
    globalFilterData,
    hashcode,
}: IComponentProps) => {
    const {
        pagination,
        setPagination,
        sorting,
        setSorting,
        columnVisibility,
        setColumnVisibility,
        columnOrder,
        setColumnOrder,
    } = useReactTableState({
        initialSorting: widgetId && filter?.filters ? filter?.filters.sortingRequestList : initialState.sortBy,
    });

    const [totalPages, setTotalPages] = useState(0);
    const { handleSnackBar } = useCustomSnackBar();

    const id = 'finishDate-activities-predecessors';
    const elementId = 'finishDate-activities-predecessors';
    const componentKey = 'finishDateActivitiesPredecessors';
    const [modalColumnsOpen, setModalColumnsOpen] = useState<boolean>(false);
    const updatedInitialState = useTableCurrentState({ initialState, componentKey, widgetId });

    const {
        activityTypeFilter,
        statusFilter,
        submittedCompletionFilter,
        activeTab,
        isCriticalPath,
        isDelayDriver,
        update,
    } = useActivitiesCompletionPlanStore();

    const filters = useMemo(
        () =>
            widgetId
                ? filter?.filters.mainFilters || filter?.filters
                : getFiltersData({
                      [FilterNames.FRAGNET_SEARCH]: globalFilterData?.milestoneFragnet,
                      [FilterNames.ACTIVITY_TYPE_FILTER]: activityTypeFilter?.map((item) => item.value) || [],
                      [FilterNames.OPTIONS_STATUS]: statusFilter?.map((item) => item.value) || [],
                      [FilterNames.CRITICAL_PATH]: isCriticalPath ? [1] : [],
                      [FilterNames.SUBMITTED_COMPLETION_CARD_BOARD]: submittedCompletionFilter,
                      [FilterNames.DELAY_DRIVER]: isDelayDriver ? ['true'] : ['false'],
                  }),

        [
            filter,
            globalFilterData,
            activityTypeFilter,
            statusFilter,
            isCriticalPath,
            submittedCompletionFilter,
            isDelayDriver,
        ],
    );

    const milestoneFilter: IFilter | undefined = filters?.find((filter) => filter.name === FilterNames.FRAGNET_SEARCH);
    const taskVersionHashCode = milestoneFilter?.data?.[0] as string | undefined;

    const mutation = useMutationExportCompletionPlanToCsv();

    const { data, isLoading, isFetching } = useQueryActivityCompletionPlan({
        contractId,
        metaDataId: latestVersionId,
        tabId: activeTab,
        filter: {
            filters: filters,
        },
        sortingRequestList: sorting,
        paging: { ...pagination, page: pagination.page - 1 },
    });

    useEffect(() => {
        updatedInitialState &&
            setColumnOrder(updatedInitialState.columnOrder ?? columnsV8.map((item) => item.id as string));
        updatedInitialState &&
            setColumnVisibility(
                serializeColumnsVisibility({ columns: columnsV8, hiddenColumns: updatedInitialState.hiddenColumns }) ??
                    {},
            );
    }, [updatedInitialState]);

    const tableInstance = useMemo(() => {
        const hiddenColumns = Object.keys(columnVisibility).reduce((acc: string[], item: string) => {
            columnVisibility[item] === false && acc.push(item);
            return acc;
        }, []);

        return {
            setColumnOrder,
            setHiddenColumns: (hiddenColumns) =>
                setColumnVisibility(serializeColumnsVisibility({ columns: columnsV8, hiddenColumns })),
            state: {
                hiddenColumns,
            },
            allColumns: columnOrder
                .map((item) => {
                    const column = columnsV8.find((column) => column.id === item);
                    if (column && column.id) {
                        return {
                            ...column,
                            isVisible: !hiddenColumns.includes(column.id),
                        };
                    }
                    return null;
                })
                .filter((x) => x),
        };
    }, [columnVisibility, columnOrder]);

    //  reset Pagination on sorting and new filter request
    useEffect(() => {
        setPagination({ ...pagination, page: 1 });
    }, [sorting, filters]);

    useEffect(() => {
        if (data) {
            setTotalPages(data.numOfPages);
        }
    }, [data]);

    const { generateFilters: formatedFilter } = useGlobalFiltersHook({
        widgetId,
        filter: filters && {
            filters: filters,
        },
        latestVersionId,
    });

    const tabsArrData = generateTabs({ data });

    const title = `Completion Plan (Per Current Progress)`;
    const route = `${getStringWithoutHash(window.location.href)}#${elementId}`;
    const subTitle = useCustomPageSubTitle({ projectId, contractId, latestVersionId });
    const subTitleForExport = `${excellenceAppsConfig.completionPlan.title}, ${prepareVersionSelectTitle()}`;
    useEffect(() => {
        setSubTitleForExport && setSubTitleForExport({ widgetId, subTitle: subTitleForExport, title });
    }, [widgetId, subTitleForExport, title]);

    const pinBoardFilterData = useMemo(() => {
        return {
            mainFilters: filters,
            sortingRequestList: sorting,
        };
    }, [filters, sorting]);

    const handleAPICsvExport = () => {
        mutation.mutate(
            {
                params: {
                    contractId,
                    metaDataId: latestVersionId,
                    tabId: activeTab,
                    filter: {
                        filters: filters,
                    },
                    sortingRequestList: sorting,
                    paging: { ...pagination, page: pagination.page - 1 },
                },
                fileName: `${title}_${subTitle}`,
            },
            {
                onSuccess: () => {
                    handleSnackBar('File Successfully Downloaded', 'success');
                },
                onError: (error) => {
                    handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
                },
            },
        );
    };

    const handleActiveTab = (tab: TKeys) => {
        update({ activeTab: tab });
        setPagination({ ...pagination, page: 1 });
    };

    return (
        <WidgetWithTitle
            title={title}
            tooltip={data?.info}
            id={id}
            titleComponents={[
                externalComponents && <div key={'externalComponents'}>{externalComponents}</div>,
                <CustomizedTooltip
                    key={'tableColumns'}
                    tooltipPosition={TooltipPosition.Top}
                    tooltipContent={'Customize table columns'}
                    triggerElement={
                        <CustomizedRoundIcon enableHover={true} onClick={() => setModalColumnsOpen(true)}>
                            <Icon name={'tune-filter'} />
                        </CustomizedRoundIcon>
                    }
                />,
                <AddWidget
                    key={'AddWidget'}
                    componentKey={componentKey}
                    title={title}
                    projectId={projectId}
                    contractId={contractId}
                    widgetId={widgetId}
                    route={route}
                    filters={pinBoardFilterData}
                />,
                <ShareInsightButton key={'shareInsight'} title={title} link={route} />,
                <ExportDropdown
                    key={'export'}
                    title={title}
                    subTitle={subTitle}
                    activeTabTitle={activeTab}
                    handleAPICsvExport={handleAPICsvExport}
                />,
            ]}
            titleFilters={[
                editNarrative && <div key={'editNarrative'}>{editNarrative}</div>,
                widgetId && formatedFilter.length > 0 ? (
                    <WidgetFilters key={'widgetFilters'} widgetId={widgetId} filters={formatedFilter} />
                ) : null,
            ]}
        >
            <div className={classes.tableWrapperContainer}>
                {tabsArrData.length > 0 && data && <FragnetSummary data={data} />}
                {tabsArrData.length > 0 && (
                    <CustomizedTabs<TKeys> tabs={tabsArrData} activeTab={activeTab} setActiveTab={handleActiveTab} />
                )}
                {!isLoading && !widgetId && tabsArrData.length > 0 && (
                    <SortFilterPanel taskVersionHashCode={taskVersionHashCode} hashcode={hashcode} />
                )}

                {updatedInitialState && (
                    <>
                        <TableV8<IActivityCompletionList>
                            data={data?.activity_completion_list || []}
                            columns={columnsV8}
                            isLoading={isLoading || isFetching}
                            noData={
                                <NoData>
                                    To view Completion Plan for the <strong>Project Finish</strong> activity select the{' '}
                                    <strong>first</strong> activity in fragnet filter list above.
                                    <br />
                                    To view Completion Plan for a <strong>specific</strong> milestone/activity select it
                                    from the fragnet filter list above.
                                </NoData>
                            }
                            sorting={sorting}
                            setSorting={setSorting}
                            columnVisibility={columnVisibility}
                            setColumnVisibility={setColumnVisibility}
                            columnOrder={columnOrder}
                            setColumnOrder={setColumnOrder}
                        />

                        <div className={classes.pagination}>
                            <CustomPagination
                                totalPages={totalPages}
                                pagination={pagination}
                                setPagination={setPagination}
                                isDisabled={isFetching}
                            />
                        </div>
                    </>
                )}
            </div>
            <CustomTableColumnsModal
                onHideModal={() => setModalColumnsOpen(false)}
                showModal={modalColumnsOpen}
                widgetId={widgetId}
                componentKey={componentKey}
                tableInstance={tableInstance}
                initialState={initialState}
                columns={columnsV8}
                onChangeOrder={setColumnOrder}
                onChangeVisibility={setColumnVisibility}
            />
        </WidgetWithTitle>
    );
};
