import classes from './Header.module.scss';
import { CustomizedTabs, ITab } from 'components/common/CustomizedTabs/CustomizedTabs';
import { TabOptions } from 'components/common/CustomFilters/DateRangeFilter/DateRangeFilter.utils';
import React from 'react';

interface IHeaderProps {
    activeTab: TabOptions;
    setActiveTab: React.Dispatch<React.SetStateAction<TabOptions>>;
}

export const Header = ({ activeTab, setActiveTab }: IHeaderProps) => {
    return (
        <div className={classes.headerContainer}>
            <CustomizedTabs<TabOptions> tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
        </div>
    );
};

const tabs: ITab<TabOptions>[] = [
    { value: 'FIXED_DATE', label: <div className={classes.tabStyle}>Fixed Date</div> },
    { value: 'TODAY_REF', label: <div className={classes.tabStyle}>Today’s Reference</div> },
    {
        value: 'VERSION_DATE_REF',
        label: <div className={classes.tabStyle}>Version Date Reference</div>,
    },
];
