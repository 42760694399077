import moment from 'moment-timezone';
import { CustomizedTooltip } from 'components/common';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import Icon from 'components/common/Icons/icon';
import constants from 'components/common/Constants/constants';
import { Pills } from 'components/common/pills/pills';
import colorsVars from 'styles/colors.module.scss';
import { DefaultColumnFilter } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/ProgramLibraryTable/ProgramLibraryFilter/programLibraryFilter';
import { RowOptionsCell } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/RenderLibraryTable/rowOptionsCell';
import { RowOptionsHeader } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/RenderLibraryTable/rowOptionsHeader';
import { TooltipCellWithCopy } from 'components/common/Tables/Cells/tooltipCellWithCopy';
import { columnsCommonConfig } from 'components/common/Tables/columnsCommonConfig';
import { ErrorCell } from 'components/common/Tables/Cells/errorCell';
import { ColumnDef } from '@tanstack/react-table';
import { IProgramVersion } from 'components/common/VersionCompareSelectorSection/VersionCompareSelectorWrapper/VersionCompareSelectorWrapper';
const { programLibraryGreen, white, fileIconColor, black } = colorsVars;

export const DESIGNATED_BASELINE = {
    id: 'isDesignatedBaseline',
    value: 'D.Baseline',
    title: 'Designated Baseline',
};

export const IS_LATEST = {
    id: 'isLatest',
    value: 'Latest',
    title: 'Latest',
};

const filterLongDate = (rows, id, filterValue) => {
    return rows.filter((row) => {
        const rowValue = moment(row.values[id]).format('DD/MM/yyyy HH:mm');
        return rowValue.includes(filterValue);
    });
};
const filterDate = (rows, id, filterValue) => {
    return rows.filter((row) => {
        const rowValue = moment(row.values[id]).format('DD/MM/yyyy');
        return rowValue.includes(filterValue);
    });
};

const filterBaseLine = (rows, id, filterValue) => {
    const getRowValue = ({ row, accessorId }) => {
        if (accessorId === DESIGNATED_BASELINE.id) {
            return DESIGNATED_BASELINE.value;
        }
        if (accessorId === IS_LATEST.id) {
            return IS_LATEST.value;
        }
        return row.original.isBaseline ? 'Yes' : 'No';
    };
    return rows.filter((row) => {
        const rowValue = getRowValue({ row, accessorId: row.values[id] });
        return rowValue.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase());
    });
};

export const fileTypes = {
    XER: 'Primavera XER',
    PLF: 'Primavera XER + PLF',
    MPP: 'MS Project',
    PW: 'Planisware',
    EXT: 'Integrated Project',
};

export const defaultColumn = {
    Filter: DefaultColumnFilter,
};

export const initialState = {
    sortBy: [
        {
            id: 'versionDate',
            desc: true,
        },
    ],
    hiddenColumns: [],
};

export const columnsV8: ColumnDef<IProgramVersion>[] = [
    {
        accessorKey: 'fileType',
        header: 'Type',
        size: 60,
        cell: ({ row, getValue }) => {
            const value = getValue<IProgramVersion['fileType']>();
            const status = row.original.status;
            const icon = row.original.projectType === 'EXT' ? 'integrated-file' : value;
            const fileType = row.original.projectType === 'EXT' ? 'EXT' : value;

            return (
                <CustomizedTooltip
                    tooltipPosition={TooltipPosition.Top}
                    tooltipContent={fileTypes[fileType]}
                    triggerElement={<Icon name={icon} size="3.5rem" color={status === 'OK' ? fileIconColor : black} />}
                />
            );
        },
    },
    {
        header: 'Sub-Program',
        accessorKey: 'contractName',
        enableColumnFilter: true,
        ...columnsCommonConfig.contractName,
    },
    {
        header: 'Source File',
        accessorKey: 'sourceFile',
        enableColumnFilter: true,
        size: 200,
        cell: ({ getValue }) => <TooltipCellWithCopy text={getValue()} />,
    },
    {
        header: 'Version Name',
        accessorKey: 'versionName',
        enableColumnFilter: true,
        ...columnsCommonConfig.versionName,
        cell: ({ getValue }) => <TooltipCellWithCopy text={getValue()} />,
    },
    {
        header: 'Version Date',
        accessorKey: 'versionDate',
        enableColumnFilter: true,
        ...columnsCommonConfig.versionDate,
        meta: {
            isFixedSize: true,
        },
        cell: ({ getValue }) =>
            moment(getValue<IProgramVersion['versionDate']>()).format(constants.formats.date.default),
        filterFn: (row, columnId, filterValue) => {
            const value = row.getValue<IProgramVersion['versionDate']>(columnId);
            const rowValue = moment(value).format(constants.formats.date.default);
            return rowValue.includes(filterValue);
        },
    },
    {
        header: 'Uploaded By',
        accessorKey: 'uploadingUser',
        enableColumnFilter: true,
        size: 200,
        meta: {
            isFixedSize: true,
        },
        cell: ({ getValue, row, column }) => {
            // const totalFlexWidth = [columns[5], columns[6], columns[7]].reduce(
            //     (acc, column) => acc + column.totalFlexWidth,
            //     0,
            // );
            const { status, errorMessage } = row.original;
            const isError = status.toLowerCase() === 'error';
            column.colSpan = isError ? 3 : 1;

            return isError ? <ErrorCell error={errorMessage} /> : <TooltipCellWithCopy text={getValue()} />;
        },
    },
    {
        header: 'Upload Date',
        accessorKey: 'uploadedDate',
        enableColumnFilter: true,
        size: 200,
        meta: {
            isFixedSize: true,
        },
        cell: ({ getValue, row }) => {
            const isError = row.original.status.toLowerCase() === 'error';
            return isError
                ? null
                : moment(getValue<IProgramVersion['uploadedDate']>()).format(constants.formats.date.defaultWithTime);
        },
        filterFn: (row, columnId, filterValue) => {
            const value = row.getValue<IProgramVersion['versionDate']>(columnId);
            const rowValue = moment(value).format(constants.formats.date.defaultWithTime);
            return rowValue.includes(filterValue);
        },
    },
    {
        header: 'Re/Baseline',
        id: 'baseline',
        enableColumnFilter: true,
        meta: {
            isFixedSize: true,
        },
        accessorFn: (row) => {
            if (row.isDesignatedBaseline) {
                return DESIGNATED_BASELINE.id;
            }
            if (row.isLatest) {
                return IS_LATEST.id;
            }
            return 'isBaseline';
        },
        size: 130,
        cell: ({ getValue, row }) => {
            const getPillContent = (value) => {
                if (value === DESIGNATED_BASELINE.id) {
                    return { value: DESIGNATED_BASELINE.value, title: DESIGNATED_BASELINE.title };
                }
                if (value === IS_LATEST.id) {
                    return { value: IS_LATEST.value, title: IS_LATEST.title };
                }
                return null;
            };
            const pillContent = getPillContent(getValue());
            const isBaselineContent = row.original.isBaseline ? 'Yes' : 'No';
            const isError = row.original.status.toLowerCase() === 'error';
            if (isError) return null;
            return pillContent ? (
                <Pills
                    style={{
                        background: programLibraryGreen,
                        color: white,
                        fontSize: '1.6rem',
                    }}
                >
                    <CustomizedTooltip
                        tooltipPosition={TooltipPosition.Top}
                        tooltipContent={pillContent.title}
                        triggerElement={pillContent.value}
                    />
                </Pills>
            ) : (
                isBaselineContent
            );
        },
        filterFn: (row, columnId, filterValue) => {
            const getRowValue = ({ row, accessorId }) => {
                if (accessorId === DESIGNATED_BASELINE.id) {
                    return DESIGNATED_BASELINE.value;
                }
                if (accessorId === IS_LATEST.id) {
                    return IS_LATEST.value;
                }
                return row.original.isBaseline ? 'Yes' : 'No';
            };
            const rowValue = getRowValue({ row, accessorId: row.getValue(columnId) });
            return rowValue.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase());
        },
    },
    {
        header: ({ table }) => {
            const { getToggleAllRowsSelectedHandler, getIsAllRowsSelected, getSelectedRowModel } = table;
            return (
                <RowOptionsHeader
                    toggleAllRowsSelected={getToggleAllRowsSelectedHandler()}
                    isAllRowsSelected={getIsAllRowsSelected()}
                    selectedFlatRows={getSelectedRowModel().flatRows}
                />
            );
        },
        accessorFn: () => null,
        enableColumnFilter: false,
        enableSorting: false,
        ...columnsCommonConfig.rowOptions,
        size: 200,
        meta: {
            isFixedSize: true,
        },
        cell: ({ row }) => {
            const { index, original, getIsSelected, getToggleSelectedHandler } = row;
            const { status, errorMessage, id, versionHashCode, sourceFile, versionName } = original;
            return (
                <RowOptionsCell
                    index={index}
                    status={status}
                    errorMessage={errorMessage}
                    id={id}
                    sourceFile={sourceFile}
                    versionHashCode={versionHashCode}
                    isSelected={getIsSelected()}
                    toggleRowSelected={getToggleSelectedHandler()}
                    versionName={versionName}
                />
            );
        },
    },
];
