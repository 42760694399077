import { LoaderContainer, WidgetCard } from 'components/common';
import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import classes from 'components/Dashboards/Portfolio/PortfolioOverview/PortfolioOverview.module.scss';
import { CardItem } from 'components/common/WidgetWithCards/WidgetCard/CardItem/CardItem';
import {
    capitalizeFirstLetter,
    getCardItemValue,
    getColorFromStatus,
    serializeoverAllStatusData,
} from 'components/Dashboards/Portfolio/PortfolioOverview/helper';
import { CardItemProgressBar } from 'components/Dashboards/Portfolio/PortfolioOverview/components/CardItemProgressBar';
import OverlayWithSpinner from 'components/common/OverlayWithSpinner/overlayWithSpinner';
import NoData from 'components/common/NoData/noData';
import { sortBy } from 'lodash';
import { ExportDropdown } from 'components/common/ExportDropdown/exportDropdown';
import React, { useEffect, useMemo } from 'react';
import { IPortfolioTopHeroesItems } from 'api/queries/getProjectScoreCardsSummary';
import { IPortfolioProps } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import { AddWidget } from 'components/Dashboards/Project/Components/CustomDashboard/AddRemoveWidget/addWidget';
import { prepareVersionSelectTitle } from 'components/common/TreeProgramFilter/VersionSelect/versionSelect.utils';
import { portfolioConfig } from 'components/Dashboards/Portfolio/portfolioConfig';
import { ShareInsightButton } from 'components/common/ShareInsightButton/ShareInsightButton';
import { FilterNames, getFiltersData } from 'components/common/GlobalFilterComponent/GlobalFilterComponent.utils';
import { useGlobalFilterDataStore } from 'store/globalFilterData.store';
import { getStringWithoutHash } from 'utilitys/helpers/general';
import { useQueryPortfolioTopHeroes } from 'api/queries/getPortfolioTopHeroes.query';
const overallStatusId = 'overall-status';

const OverallStatus = ({
    widgetId,
    externalComponents = null,
    editNarrative = null,
    setSubTitleForExport,
    filter,
}: IPortfolioProps) => {
    const globalFilterData = useGlobalFilterDataStore((state) => state.globalFilterData);
    const filters = useMemo(
        () =>
            widgetId
                ? filter?.filters || []
                : getFiltersData({
                      [FilterNames.TAG_PORTFOLIO_BOARD]: globalFilterData?.programTags?.map((item) => item.value),
                  }),

        [filter, globalFilterData],
    );
    const {
        data: overAllStatus,
        isLoading: isLoadingoverAllStatus,
        isFetching: isFetchingoverAllStatus,
    } = useQueryPortfolioTopHeroes({
        filter: {
            filters,
        },
    });

    const subTitle = (
        <>
            Total <span className={classes.totalCount}>{overAllStatus?.numOfItems ?? 0}</span> projects/sub-programs
        </>
    );

    const overAllStatusData = serializeoverAllStatusData({ data: overAllStatus?.componentsList });
    const route = `${getStringWithoutHash(window.location.href)}#${overallStatusId}`;
    const componentKey = 'overallStatus';
    const title = 'Overall Status';

    const subTitleForExport = `${portfolioConfig.portfolioOverview.title}, ${prepareVersionSelectTitle()}`;

    useEffect(() => {
        setSubTitleForExport && setSubTitleForExport({ widgetId, subTitle: subTitleForExport, title });
    }, [widgetId, subTitleForExport, title]);

    return (
        <WidgetWithTitle
            title={title}
            tooltip={overAllStatus?.info}
            id={overallStatusId}
            titleComponents={[
                <span key={'subTitle'} className={classes.subProjectTitle}>
                    {subTitle}
                </span>,
                externalComponents && <div key={'externalComponents'}>{externalComponents}</div>,
                <AddWidget
                    key={'AddWidget'}
                    componentKey={componentKey}
                    title={title}
                    widgetId={widgetId}
                    route={route}
                    projectId={null}
                    contractId={null}
                    filters={filters}
                />,
                <ShareInsightButton key={'shareInsight'} title={title} link={route} />,
                <ExportDropdown title={title} key={'export'} subTitle={subTitleForExport} />,
            ]}
            titleFilters={[editNarrative && <div key={'editNarrative'}>{editNarrative}</div>]}
        >
            <div className={classes.widgetCardsWrapper}>
                {(isLoadingoverAllStatus || isFetchingoverAllStatus) && (
                    <LoaderContainer height={'500px'}>
                        <OverlayWithSpinner />
                    </LoaderContainer>
                )}
                {!isLoadingoverAllStatus && !overAllStatusData && <NoData />}
                {!isLoadingoverAllStatus &&
                    overAllStatusData &&
                    Object.entries<IPortfolioTopHeroesItems[]>(overAllStatusData).map(([key, value]) => {
                        const titleText = key.slice(2);
                        return (
                            <WidgetCard
                                id={`overallStatusCard_${key}`}
                                contentHeight="100%"
                                titleText={titleText}
                                key={key}
                            >
                                {sortBy(value, ['order']).map((item, index) => {
                                    return (
                                        <CardItem
                                            key={`overallStatus-${item.name}`}
                                            title={capitalizeFirstLetter(item.name.toLowerCase())}
                                            showTooltip={false}
                                            showCardInfoTooltip={false}
                                            id={`overallStatusCardItem_title_${key}_${index}`}
                                        >
                                            <CardItemProgressBar
                                                id={`overallStatusCardItem_value_${key}_${index}`}
                                                mainText={item.value}
                                                currentValue={getCardItemValue(item.value)}
                                                textColor={getColorFromStatus(item.name, titleText)}
                                                maxValue={overAllStatus?.numOfItems}
                                            />
                                        </CardItem>
                                    );
                                })}
                            </WidgetCard>
                        );
                    })}
            </div>
        </WidgetWithTitle>
    );
};
export default React.memo(OverallStatus);
