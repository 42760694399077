import { useEffect, useState } from 'react';
import { head } from 'lodash';
import { QueryResponse as IVersion, useQueryVersionList } from 'api/queries/versionList.query';

/**
 * hook to perform convert from hashcode that is used in the url to id that is used for API calls
 * Use:
 * Example 1: useHashmapConverter("-9876543") => { [10], [versionObject] }
 * Example 2: useHashmapConverter("-9876543,-1234567") => { [10, 7], [versionObject, versionObject] }
 * @param hashcode
 * @return {{versionList: *[], idList: *[]}}
 */
function useHashmapConverter(hashcode: string) {
    const [versionList, setVersionList] = useState<IVersion[]>([]);
    const [idList, setIdList] = useState<number[]>([]);
    const [contractUUID, setContractUUID] = useState<string | undefined>(undefined);
    const [projectUUID, setProjectUUID] = useState<string | undefined>(undefined);
    const hashListArr = hashcode ? hashcode.split(',') : [];
    const { data } = useQueryVersionList({ hashList: hashListArr });
    const sorted = (a, b) =>
        hashListArr.indexOf(String(a.versionHashCode)) - hashListArr.indexOf(String(b.versionHashCode));

    useEffect(() => {
        if (data) {
            setVersionList([...data].sort(sorted));
            setIdList([...data].sort(sorted).map((item) => item.versionDbId));
            setContractUUID(head(data.map((item) => item.contractUuid)));
            setProjectUUID(head(data.map((item) => item.projectUuid)));
        }
    }, [data]);

    return { idList, versionList, contractUUID, projectUUID };
}

export default useHashmapConverter;
