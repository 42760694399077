import analytics from 'utilitys/helpers/Analytics/analytics';

interface Props {
    category: string;
    action: string;
    label: string;
}

const useEventWithDimensions = () => {
    const sendEventWithDimensions = ({ category, action, label }: Props) => {
        analytics.mixpanelTrack(`useraction: ${category} - ${action}`, {
            category,
            action,
            label,
        });

        const event = `${category} - ${action}`;
        const options = {
            metadata: label,
        };
        analytics.fullStoryTrack(event, options);
    };

    return { sendEventWithDimensions };
};

export default useEventWithDimensions;
