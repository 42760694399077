import React, { useMemo } from 'react';
import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import { MyCustomSelect } from 'components/common';
import classes from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/RCF/rcf.module.scss';
import { ExportDropdown } from 'components/common/ExportDropdown/exportDropdown';
import { findClosestNumber } from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/ClusterRCFAnalysis.utils';
import { useThemeStatusStore } from 'store/themeStatus.store';
import { serializeData, serializeOptions } from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/RCF/rcf.utils';
import { LineChart } from 'components/common/charts/lineChart/lineChart';
import { QueryResponse } from 'api/queries/clustersData.query';

interface Props {
    rcfDurationData?: QueryResponse['rcf']['duration'];
    rcfDurationRatioData?: QueryResponse['rcf']['ratio'];
    tooltipArg?: IToolTip;
    loadingCluster: boolean;
    subTitle: string;
}

type TDurationRatio = 'Duration' | 'DurationRatio';

const Rcf = ({ rcfDurationData, rcfDurationRatioData, tooltipArg, loadingCluster, subTitle }: Props) => {
    const { themeStatus } = useThemeStatusStore();

    // we need to plot both duration in days and durationRatio
    const [durationOrDurationRatio, setDurationOrDurationRatio] = React.useState<ISelectOption<TDurationRatio>>({
        label: ' Duration Ratio',
        value: 'DurationRatio',
    });

    const data = useMemo(() => {
        if (rcfDurationData && rcfDurationRatioData) {
            return durationOrDurationRatio.value === 'Duration' ? rcfDurationData : rcfDurationRatioData;
        }
        return [];
    }, [durationOrDurationRatio, rcfDurationData, rcfDurationRatioData]);

    const series = serializeData({ data: data, themeStatus, yaxisName: durationOrDurationRatio.label });
    const graphOptions = serializeOptions({
        data: data,
        themeStatus,
        yaxisName: durationOrDurationRatio.label,
        isAnnotation: durationOrDurationRatio.value === 'DurationRatio',
    });

    const title = 'Cluster RCF';
    const options: ISelectOption<TDurationRatio>[] = [
        { label: 'Duration (days)', value: 'Duration' },
        { label: ' Duration Ratio', value: 'DurationRatio' },
    ];
    const handleChange = (value: ISelectOption<TDurationRatio> | null) => {
        if (value) {
            setDurationOrDurationRatio(value);
        }
    };
    const closest50 = findClosestNumber({ data, target: 50, key: 'percentile', initial: 50 });
    const p50 = data?.find((item) => item.percentile === closest50);
    const closest80 = findClosestNumber({ data, target: 80, key: 'percentile', initial: 100 });
    const p80 = data?.find((item) => item.percentile === closest80);
    return (
        <WidgetWithTitle
            title={title}
            tooltip={tooltipArg}
            titleComponents={[
                <MyCustomSelect<ISelectOption<TDurationRatio>>
                    options={options}
                    id={'duration-select'}
                    onChange={handleChange}
                    value={durationOrDurationRatio}
                    key={'durationRatio'}
                />,

                <ExportDropdown title={title} key={'export'} subTitle={subTitle} />,
            ]}
            className={classes.RCFWidget}
        >
            <div className={classes.boxContainer}>
                <div className={classes.boxWrapper}>
                    {!loadingCluster && data.length > 0 && (
                        <LineChart
                            series={series}
                            options={graphOptions}
                            noData={false}
                            isFetching={false}
                            height={430}
                        />
                    )}
                    {!loadingCluster && data.length === 0 && (
                        <p className={classes.text}>No actual (completed) data exists</p>
                    )}
                    {loadingCluster && <p className={classes.text}>Waiting for data...</p>}
                    <div data-testid={'duration-ratio'} className={classes.durationRatio}>
                        {durationOrDurationRatio.label}: <span className={classes.title}>Typical:</span>{' '}
                        <span className={classes.value}>{p50 ? Math.round(p50.value * 100) / 100 : ''}</span>,{' '}
                        <span className={classes.title}>Pessimistic:</span>{' '}
                        <span className={classes.value}>{p80 ? Math.round(p80.value * 100) / 100 : ''}</span>
                    </div>
                </div>
            </div>
        </WidgetWithTitle>
    );
};

export default Rcf;
