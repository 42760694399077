import classes from 'components/Dashboards/ExcellenceApps/CompletionPlanPage/components/SortFilterPanel.module.scss';
import { MyCustomSelect } from 'components/common';
import { InputOption, ValueContainer } from 'components/common/MyCustomSelect/myCustomSelect';
import { Link } from 'react-router-dom';
import React from 'react';
import { projectConfig } from 'components/Dashboards/Project/ProjectWrapper/projectConfig';
import { projectActivitiesCompletionPlanSegmentFilter } from 'components/Dashboards/ExcellenceApps/CompletionPlanPage/utils/helper';
import SwitchComponent from 'components/common/Switch/Switch';
import Slider from 'components/common/Slider/Slider';
import qs from 'qs';
import { useActivitiesPredecessorsStore } from 'store/activitiesPredecessors.store';
import {
    activityTypeOptions,
    IActivityTypeOptions,
    statusOptions,
} from 'components/Dashboards/ExcellenceApps/CompletionPlanPage/utils/utils';

interface Props {
    taskVersionHashCode: string | undefined;
    hashcode: string | undefined;
}

export const SortFilterPanel = ({ taskVersionHashCode, hashcode }: Props) => {
    const {
        activityTypeFilter,
        statusFilter,
        submittedCompletionFilter,
        activeTab: completionPlanSegment,
        isCriticalPath,
        isDelayDriver,
        update,
    } = useActivitiesPredecessorsStore();

    const linkToBattlecards = () => {
        const riskIndicatorsIsCriticalPath = isCriticalPath ? 'CRITICAL_PATH_USER_CARD_BOARD' : null;
        const riskIndicatorsIsDelayedDriver = isDelayDriver ? 'DELAY_DRIVER_CARD_BOARD' : null;
        const riskIndicators = [riskIndicatorsIsCriticalPath, riskIndicatorsIsDelayedDriver].filter(Boolean);
        const riskIndicatorsFilter = riskIndicators.join(',');

        const querystring = qs.stringify(
            {
                cardActivity: activityTypeFilter.map((item) => item.queryStringValue).join(','),
                status: statusFilter.map((item) => item.value).join(','),
                submittedCompletion: submittedCompletionFilter.join(','),
                riskIndicators: riskIndicatorsFilter.length === 0 ? null : riskIndicatorsFilter,
                activityFragnet: taskVersionHashCode,
                completionPlanSegment: projectActivitiesCompletionPlanSegmentFilter[completionPlanSegment],
            },
            { addQueryPrefix: true, skipNulls: true, encode: false },
        );

        return `/dashboard/project/${projectConfig.battlecards.link}/${hashcode}${querystring}`;
    };

    return (
        <div className={classes.sortFilterContainer}>
            <div data-testid={'filter-container'} className={classes.filterContainer}>
                <div className={classes.selectWrapper}>
                    <div className={classes.title}>Activity Type</div>
                    <MyCustomSelect<IActivityTypeOptions, true>
                        value={activityTypeFilter}
                        options={activityTypeOptions}
                        onChange={(value) => update({ activityTypeFilter: value })}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        isMulti={true}
                        placeholder={'Select Activity Type...'}
                        components={{
                            Option: InputOption,
                            ValueContainer: ValueContainer,
                        }}
                    />
                </div>
                <div className={classes.selectWrapper}>
                    <div className={classes.title}>Status</div>
                    <MyCustomSelect<ISelectOption<string>, true>
                        value={statusFilter}
                        options={statusOptions}
                        onChange={(value) => update({ statusFilter: value })}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        isMulti={true}
                        placeholder={'Select Status...'}
                        components={{
                            Option: InputOption,
                            ValueContainer: ValueContainer,
                        }}
                    />
                </div>
                <div>
                    Delay Driver
                    <SwitchComponent
                        onChange={(event) => update({ isDelayDriver: event.target.checked })}
                        checked={isDelayDriver}
                    />
                </div>
                <div>
                    Critical Path Only
                    <SwitchComponent
                        onChange={(event) => update({ isCriticalPath: event.target.checked })}
                        checked={isCriticalPath}
                    />
                </div>

                <div className={classes.submittedCompletion}>
                    <div className={classes.sliderTitle}>Submitted Completion</div>
                    <div className={classes.sliderWrapper}>
                        <Slider
                            id={'completion-plan-submittedCompletion-slider'}
                            defaultValue={submittedCompletionFilter}
                            step={10}
                            min={0}
                            max={100}
                            valueLabelDisplay={'on'}
                            onChangeCommitted={(e, value) => update({ submittedCompletionFilter: value })}
                        />
                    </div>
                </div>
            </div>
            <div className={classes.linkContainer}>
                <Link to={linkToBattlecards()}>View in {projectConfig.battlecards.title}</Link>
            </div>
        </div>
    );
};
