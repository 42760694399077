import moment from 'moment-timezone';
import constants from 'components/common/Constants/constants';

export const BasicDateCell = ({ value }: { value?: number | null | string }) => {
    return (
        <>
            {value && typeof value === 'number' && moment(value).isValid()
                ? moment(value).format(constants.formats.date.default)
                : ''}
        </>
    );
};
