import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/RenderLibraryTable/ActionMenuPending.module.scss';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import { CustomizedTooltip } from 'components/common';
import { LoaderSpinner } from 'components/common/LodaerSpinner/LoaderSpinner';

interface Props {
    className?: string;
}

export const ActionMenuPending = ({ className }: Props) => {
    return (
        <CustomizedTooltip
            tooltipPosition={TooltipPosition.Bottom}
            tooltipContent={'Program processing is pending. It might take a few minutes...'}
            triggerElement={
                <div className={classes.actionMenuPending}>
                    <LoaderSpinner className={className} />
                    Uploading...
                </div>
            }
        />
    );
};
