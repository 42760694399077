import { RowOptionsCell } from 'components/Settings/Components/RolesManagement/components/RowOptionCell';
import { DefaultColumnFilter } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/ProgramLibraryTable/ProgramLibraryFilter/programLibraryFilter';
import { columnsCommonConfig } from 'components/common/Tables/columnsCommonConfig';
import { customCaseSensitiveSort } from 'components/Dashboards/Project/Components/VersionCompare/TableColumns/TableColumns.utils';

export const defaultColumn = {
    Filter: DefaultColumnFilter,
};

export const initialState = {
    sortBy: [
        {
            id: 'label',
        },
    ],
};

export const columns = [
    {
        Header: 'Role name',
        accessor: 'label',
        width: 1285,
        sortType: customCaseSensitiveSort,
    },
    {
        Header: '',
        ...columnsCommonConfig.rowOptions,
        Cell: ({ row }) => {
            return <RowOptionsCell row={row} />;
        },
    },
];
