import { CardTab } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/CardTab/CardTab';
import { IActiveTab } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/TabsSection/TabsSection';
import { ITab } from 'components/common/CustomizedTabs/CustomizedTabs';

export const tabsArrData: ITab<IActiveTab>[] = [
    {
        value: 'cardDetails',
        label: <CardTab title={'Card Details'} />,
    },
    {
        value: 'activityDetails',
        label: <CardTab title={'Activity Details'} />,
    },
    {
        value: 'activityRelationships',
        label: <CardTab title={'Activity Relationships'} />,
    },
    {
        value: 'relatedCards',
        label: <CardTab title={'Associated Activities'} />,
    },
    {
        value: 'auditLogs',
        label: <CardTab title={'Audit Log'} />,
    },
];
