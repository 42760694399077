import ErrorBoundary from 'components/common/ErrorBoundary/ErrorBoundary';
import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import { ExportDropdown } from 'components/common/ExportDropdown/exportDropdown';
import React, { useEffect, useMemo, useState } from 'react';
import { FilterNames, getFiltersData } from 'components/common/GlobalFilterComponent/GlobalFilterComponent.utils';
import { IComponentProps } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import { AddWidget } from 'components/Dashboards/Project/Components/CustomDashboard/AddRemoveWidget/addWidget';
import { WidgetFilters } from 'components/Dashboards/Project/Components/CustomDashboard/WidgetFilters/widgetFilters';
import { useGlobalFiltersHook } from 'hooks/useGlobalFiltersHook';
import useCustomPageSubTitle from 'hooks/useCustomPageSubTitle';
import { ShareInsightButton } from 'components/common/ShareInsightButton/ShareInsightButton';
import SwitchComponent from 'components/common/Switch/Switch';
import { useGraphAnnotationStore } from 'store/graphAnnotation.store';
import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/CommonComponents/CriticalAnalysisIntensity/criticalAnalysisIntensity.module.scss';
import { List } from 'components/common/ListWrapper/list';
import { useThemeStatusStore } from 'store/themeStatus.store';

import {
    getPoints,
    serializeData,
    serializeOptions,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramProgressOverview/ActivitiesCompleteScurveGraph/utils';
import { useQueryGetActivitiesCompleteScurveGraph } from 'api/queries/getActivitiesCompleteScurveGraph.query';
import { LineChart } from 'components/common/charts/lineChart/lineChart';
import { useQueryGetMilestoneWeightScurveGraph } from 'api/queries/getMilestoneWeightScurveGraph.query';
import { getStringWithoutHash } from 'utilitys/helpers/general';

const activityTypeOptions = [
    { label: 'Milestones', value: 'milestones' },
    { label: 'ALL', value: 'all' },
];

const ActivitiesCompleteScurveGraph = ({
    projectId,
    contractId,
    widgetId,
    latestVersionId,
    compareVersionId,
    filter,
    globalFilterData,
    externalComponents = null,
    editNarrative = null,
    setSubTitleForExport,
}: IComponentProps): JSX.Element => {
    const [activityTypeSelection, setActivityTypeSelection] = useState<ISelectOption<string>>({
        label: 'ALL',
        value: 'all',
    });
    const isShowActivitiesAnnotation = useGraphAnnotationStore((store) => store.isShowActivitiesAnnotation);
    const { update } = useGraphAnnotationStore();
    const idList = useMemo(() => (latestVersionId ? [latestVersionId] : []), [latestVersionId]);
    const { generateFilters: formattedFilter } = useGlobalFiltersHook({ widgetId, filter, latestVersionId });
    const filters = useMemo(
        () =>
            widgetId
                ? filter?.filters
                : getFiltersData({
                      [FilterNames.ACTIVITY_CODES]: globalFilterData?.activityCodes
                          ?.filter((item) => !item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.ACTIVITY_CODES_EXCLUDE]: globalFilterData?.activityCodes
                          ?.filter((item) => item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.FRAGNET_SEARCH]: globalFilterData?.milestoneFragnet,
                      [FilterNames.TAG_CARD_BOARD]: globalFilterData?.tags
                          ?.filter((item) => !item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.TAG_CARD_BOARD_EXCLUDE]: globalFilterData?.tags
                          ?.filter((item) => item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.WBS_SEARCH]: globalFilterData?.wbs,
                  }),

        [filter, globalFilterData],
    );
    const { data: activitiesWeight, isFetching: activitiesWeightFetching } = useQueryGetActivitiesCompleteScurveGraph({
        id: idList,
        latestVersion: latestVersionId,
        comparedVersion: compareVersionId || latestVersionId,
        filter: {
            filters,
        },
    });

    const { data: milestoneWeight, isFetching: milestoneWeightFetching } = useQueryGetMilestoneWeightScurveGraph({
        id: idList,
        latestVersion: latestVersionId,
        comparedVersion: compareVersionId || latestVersionId,
        filter: {
            filters,
        },
    });

    const id = 'ActivitiesCompletionSCurve';
    const title = 'Activities Completion S-Curve';
    const componentKey = 'activitiesCompletionSCurve';
    const selectedData = activityTypeSelection.value === 'all' ? activitiesWeight : milestoneWeight;
    const isFetching = activitiesWeightFetching && milestoneWeightFetching;
    const route = `${getStringWithoutHash(window.location.href)}#${id}`;
    const subTitle = useCustomPageSubTitle({ projectId, contractId, latestVersionId });
    useEffect(() => {
        setSubTitleForExport && setSubTitleForExport({ widgetId, subTitle, title });
    }, [widgetId, subTitle, title]);

    const { themeStatus } = useThemeStatusStore();
    const pointsAnnotation = getPoints(selectedData);
    const series = serializeData(selectedData);
    const options = serializeOptions({
        series,
        nowDate: selectedData?.nowDate,
        themeStatus,
        isShowAnnotation: isShowActivitiesAnnotation,
        pointsAnnotation,
    });

    return (
        <ErrorBoundary>
            <WidgetWithTitle
                id={id}
                title={title}
                tooltip={selectedData?.info}
                titleComponents={[
                    <div className={classes.selectWrapper} key={'activitySelection'}>
                        <div className={classes.title}>Activity type:</div>
                        <List<string>
                            onChange={setActivityTypeSelection}
                            options={activityTypeOptions}
                            value={activityTypeSelection}
                        />
                    </div>,
                    <div className={classes.annotationFilter} key={'annotations'}>
                        {isShowActivitiesAnnotation ? 'Hide Annotations' : 'Show Annotations'}
                        <SwitchComponent
                            onChange={(event) => update({ isShowActivitiesAnnotation: event.target.checked })}
                            checked={isShowActivitiesAnnotation}
                        />
                    </div>,
                    <AddWidget
                        key={'AddWidget'}
                        componentKey={componentKey}
                        title={title}
                        projectId={projectId}
                        contractId={contractId}
                        widgetId={widgetId}
                        route={route}
                        filters={filters}
                    />,
                    <ShareInsightButton key={'shareInsight'} title={title} link={route} />,
                    <ExportDropdown title={title} key={'export'} subTitle={subTitle} />,
                    externalComponents && <div key={'externalComponents'}>{externalComponents}</div>,
                ]}
                titleFilters={[
                    editNarrative && <div key={'editNarrative'}>{editNarrative}</div>,
                    widgetId && formattedFilter.length > 0 ? (
                        <WidgetFilters key={'widgetFilters'} widgetId={widgetId} filters={formattedFilter} />
                    ) : null,
                ]}
            >
                <LineChart
                    series={series}
                    options={options}
                    noData={
                        !isFetching &&
                        selectedData?.plannedGraphLine?.data?.length === 0 &&
                        selectedData?.actualGraphLine?.data?.length === 0
                    }
                    isFetching={isFetching}
                    className={classes.lineChart}
                />
            </WidgetWithTitle>
        </ErrorBoundary>
    );
};
export default React.memo(ActivitiesCompleteScurveGraph);
