import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { useQuery } from 'react-query';
import moment from 'moment-timezone';
import {
    IBaselineVsActualPayload,
    IBaselineVsActualResponse,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramProgressOverview/progress.type';
import { IFilter } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';

const getBaselineVsActual = (params) => {
    const preparedUrl = new URL(API_URL().FETCH_PROGRAM_PROGRESS_BASELINE_VS_ACTUAL_DATA);
    return network
        .post(preparedUrl.toString(), params)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

const getHighLevelProgram = (params: IHighLevelProgramPayload): Promise<IHighLevelProgramResponse> => {
    return network.post(API_URL().FETCH_PROGRAM_DASHBOARD_HIGH_LEVEL, params).then((response) => {
        const nowDate = moment(response.data.nowDate || '201010').toDate();
        return {
            data: response.data.wbs,
            info: response.data.info,
            nowDate: nowDate,
        };
    });
};

const useQueryBaselineVsActual = (params: IBaselineVsActualPayload) => {
    return useQuery<IBaselineVsActualResponse, QueryError>(
        ['baselineVsActual', params],
        () => getBaselineVsActual(params),
        {
            enabled:
                Boolean(params.contractId) &&
                Boolean(params.projectId) &&
                Boolean(params.actualMetaDataId) &&
                Boolean(params.baselineMetaDataId),
        },
    );
};

const useQueryHighLevelProgram = (params: IHighLevelProgramPayload) => {
    return useQuery<IHighLevelProgramResponse, QueryError>(
        ['highLevelProgram', params],
        () => getHighLevelProgram(params),
        {
            enabled: params.id.length > 0 && params.id.filter((item) => item).length > 0,
        },
    );
};

interface IHighLevelProgramPayload {
    id: number[];
    filter: { filters: IFilter[] | undefined };
}

export interface IHighLevelProgramResponse {
    info: IToolTip;
    nowDate: Date;
    data: {
        actualEndDate: null;
        actualStartDate: number;
        color: string;
        description: string;
        'duration%Complete': number;
        level: number;
        plannedEndDate: number;
        plannedStartDate: number;
        taskCode: string;
    }[];
}

export { useQueryBaselineVsActual, useQueryHighLevelProgram };
