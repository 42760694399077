import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import CrossVersionsAnalysisGraph, {
    SingleLineOptions,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramCrossVersions/CrossVersionsAnalysisIntensity/crossVersionsAnalysisGraph';
import { ExportDropdown } from 'components/common/ExportDropdown/exportDropdown';
import { LoaderContainer } from 'components/common';
import OverlayWithSpinner from 'components/common/OverlayWithSpinner/overlayWithSpinner';
import {
    getDataSetBySelectedItem,
    getDefaultSelectionDataset,
    getPreDefinedCrossVersionsData,
    getPreDefinedListObj,
    preDefinedList,
    sortFunc,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramCrossVersions/programCrossVersions.utils';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useHashmapConverter from 'hooks/useHashmapConverter';
import useEventWithDimensions from 'hooks/useEventWithDimensions';
import { useQueryCrossVersionsTrends } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramCrossVersions/queries/useQueryCrossVersions';
import { handleGrouping } from 'utilitys/helpers/general';
import useCustomPageSubTitle from 'hooks/useCustomPageSubTitle';

const mapOptions = (item, index) => ({ id: index, value: item.label, label: item.label, note: item.note });
const mapEntries = (item: [string, any], index) => ({
    id: index,
    value: item[1][0].title,
    label: item[1][0].title,
});

export interface Dataset {
    title: string;
    seriesname: string;
    data: {
        text: string;
        displayValue: string;
        label: number;
        value: number;
        actualValue: number;
    }[];
    color: string;
    selectedByDefault: boolean;
}

export interface GraphData {
    dataset: Dataset[];
}

interface GroupedData {
    [key: string]: Dataset[];
}

export const ProgramCrossVersions = () => {
    const { id } = useParams<any>();
    const { idList, versionList } = useHashmapConverter(id);
    const projectId = versionList[0]?.projectId;
    const contractId = versionList[0]?.contractId;
    const { sendEventWithDimensions } = useEventWithDimensions();
    const [options, setOptions] = useState<ISelectOption<string>[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<ISelectOption<string> | ISelectOption<string>[]>([]);
    const [singleLine, setSingleLine] = useState<SingleLineOptions>('Single Parameter (Actual value)');
    const [groupedData, setGroupedData] = useState<GroupedData>({});
    const [graphData, setGraphData] = useState<GraphData>({ dataset: [] });
    const { data, isLoading } = useQueryCrossVersionsTrends({ ids: idList });

    useEffect(() => {
        if (data) {
            const crossVersionsData =
                singleLine !== 'Pre-defined Combinations (Normalized values)'
                    ? singleLine === 'Single Parameter (Actual value)'
                        ? data.actual?.graph
                        : data.normalized?.graph
                    : getPreDefinedCrossVersionsData(data.normalized?.graph, preDefinedList);

            if (!crossVersionsData) return;

            setGraphData({ dataset: getDefaultSelectionDataset(crossVersionsData) });
            setGroupedData(handleGrouping(Object.values(crossVersionsData), 'title'));
        }
    }, [data, singleLine]);

    useEffect(() => {
        if (singleLine === 'Pre-defined Combinations (Normalized values)') {
            setOptions(preDefinedList.map(mapOptions));
            const option = preDefinedList.filter((item) => item.selected).map(mapOptions)[0];
            setSelectedOptions(option);
        } else {
            const optionsList = Object.entries(groupedData).map(mapEntries);
            const selectedByDefault = Object.entries(groupedData)
                .filter((item: [string, any]) => {
                    return item[1][0].selectedByDefault;
                })
                .map(mapEntries);
            setOptions(optionsList);
            setSelectedOptions(selectedByDefault);
        }
    }, [groupedData]);

    const handleItemSelection = (value: ISelectOption<string> | ISelectOption<string>[]) => {
        const label = Array.isArray(value) ? value.map((item) => item.label).join(', ') : value.label;
        const dataset = getDataSetBySelectedItem(
            singleLine === 'Pre-defined Combinations (Normalized values)'
                ? getPreDefinedListObj(value, preDefinedList)
                : value,
            groupedData,
            singleLine,
        );
        sendEventWithDimensions({
            category: 'Trends',
            action: 'drop-list selection',
            label,
        });
        setSelectedOptions(value);
        setGraphData({ dataset: dataset });
    };

    const handleSingle = (event: ChangeEvent<HTMLInputElement>) => {
        setSingleLine(event.target.value as SingleLineOptions);
    };

    const title = 'Trend Analysis Per Selected Parameters';

    const subTitle = useCustomPageSubTitle({ projectId, contractId, latestVersionId: idList[0] });

    return (
        <WidgetWithTitle
            id={id}
            title={title}
            tooltip={singleLine === 'Single Parameter (Actual value)' ? data?.actual?.info : data?.normalized?.info}
            titleComponents={[<ExportDropdown title={title} subTitle={subTitle} />]}
        >
            {isLoading && !data && (
                <LoaderContainer>
                    <OverlayWithSpinner />
                </LoaderContainer>
            )}

            {!isLoading && data && (
                <CrossVersionsAnalysisGraph
                    data={graphData}
                    handleSingle={handleSingle}
                    singleLine={singleLine}
                    options={options.sort(sortFunc)}
                    selectedOptions={selectedOptions}
                    handleItemSelection={handleItemSelection}
                />
            )}
        </WidgetWithTitle>
    );
};
