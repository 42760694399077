import ErrorBoundary from 'components/common/ErrorBoundary/ErrorBoundary';
import Slider, { marks } from 'components/common/Slider/Slider';
import HighLevelProgram from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/CommonComponents/HighLevelProgram/highLevelProgram';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import SliderWrapper from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramProgressOverview/SliderWrapper';
import { serialize } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramProgressOverview/helper';
import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import OverlayWithSpinner from 'components/common/OverlayWithSpinner/overlayWithSpinner';
import { LoaderContainer } from 'components/common';
import NoData from 'components/common/NoData/noData';
import { ExportDropdown } from 'components/common/ExportDropdown/exportDropdown';
import { IComponentProps } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import { useQueryHighLevelProgram } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramProgressOverview/queries/progressOverviewQuery';
import { FilterNames, getFiltersData } from 'components/common/GlobalFilterComponent/GlobalFilterComponent.utils';
import { getMaxNumberByKey, getStringWithoutHash } from 'utilitys/helpers/general';
import { WidgetFilters } from 'components/Dashboards/Project/Components/CustomDashboard/WidgetFilters/widgetFilters';
import { AddWidget } from 'components/Dashboards/Project/Components/CustomDashboard/AddRemoveWidget/addWidget';
import { useQueryGetProjectVersionsList } from 'components/common/TreeProgramFilter/Queries/useQueryGetProjectVersionsList';
import { find } from 'lodash';
import { useGlobalFiltersHook } from 'hooks/useGlobalFiltersHook';
import useCustomPageSubTitle from 'hooks/useCustomPageSubTitle';
import { ShareInsightButton } from 'components/common/ShareInsightButton/ShareInsightButton';

const RenderHighLevelProgram = ({
    projectId,
    contractId,
    widgetId,
    latestVersionId,
    filter,
    globalFilterData,
    externalComponents = null,
    editNarrative = null,
    setSubTitleForExport,
}: IComponentProps) => {
    const { data: versionsList } = useQueryGetProjectVersionsList({
        projectId,
        contractId,
    });
    const maxLevel = getMaxNumberByKey({ list: versionsList, key: 'maxLevel' });
    const minValueItem = find(filter?.filters, { name: FilterNames.MIN_WBS_LEVEL });
    const minValue = widgetId ? (minValueItem?.data?.[0] as number) : 1;
    const maxValueItem = find(filter?.filters, { name: FilterNames.MAX_WBS_LEVEL });
    const maxValue = widgetId ? (maxValueItem?.data?.[0] as number) : 10;
    const highLevelDefaultValue = [minValue, maxValue];
    const [highLevelFilter, setHighLevelFilter] = useState<number[]>(highLevelDefaultValue);

    const idList = useMemo(() => (latestVersionId ? [latestVersionId] : []), [latestVersionId]);
    const { generateFilters: formattedFilter } = useGlobalFiltersHook({ widgetId, filter, latestVersionId });
    const filters = useMemo(
        () =>
            widgetId
                ? filter?.filters
                : getFiltersData({
                      [FilterNames.ACTIVITY_CODES]: globalFilterData?.activityCodes
                          ?.filter((item) => !item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.ACTIVITY_CODES_EXCLUDE]: globalFilterData?.activityCodes
                          ?.filter((item) => item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.FRAGNET_SEARCH]: globalFilterData?.milestoneFragnet,
                      [FilterNames.TAG_CARD_BOARD]: globalFilterData?.tags
                          ?.filter((item) => !item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.TAG_CARD_BOARD_EXCLUDE]: globalFilterData?.tags
                          ?.filter((item) => item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.WBS_SEARCH]: globalFilterData?.wbs,
                      [FilterNames.MIN_WBS_LEVEL]: [highLevelFilter[0]],
                      [FilterNames.MAX_WBS_LEVEL]: [highLevelFilter[1]],
                  }),

        [filter, globalFilterData, highLevelFilter[0], highLevelFilter[1]],
    );
    const { data: highLevelProgram, isFetching: highLevelProgramLoading } = useQueryHighLevelProgram({
        id: idList,
        filter: {
            filters,
        },
    });

    const { info, data, nowDate } = highLevelProgram || {};
    const handleHighLevelFilter = useCallback((value) => {
        setHighLevelFilter(value);
    }, []);

    const noDataText = data === null ? 'Not applicable with the applied filter' : 'No data to display';
    const id = 'HighLevelProgram';
    const route = `${getStringWithoutHash(window.location.href)}#${id}`;
    const componentKey = 'highLevelProgramLatest';
    const title = 'High Level Program (Latest)';
    const subTitle = useCustomPageSubTitle({ projectId, contractId, latestVersionId });
    useEffect(() => {
        setSubTitleForExport && setSubTitleForExport({ widgetId, subTitle, title });
    }, [widgetId, subTitle, title]);
    return (
        <ErrorBoundary>
            <WidgetWithTitle
                id={id}
                title={title}
                tooltip={info}
                titleComponents={[
                    !widgetId && (
                        <SliderWrapper key={'SliderWrapper'}>
                            <Slider
                                key={'Slider'}
                                defaultValue={highLevelDefaultValue}
                                step={1}
                                min={1}
                                max={maxLevel}
                                marks={marks(1, maxLevel)}
                                onChangeCommitted={(e, value) => handleHighLevelFilter(value)}
                            />
                        </SliderWrapper>
                    ),
                    externalComponents && <div key={'externalComponents'}>{externalComponents}</div>,
                    <AddWidget
                        key={'AddWidget'}
                        componentKey={componentKey}
                        title={title}
                        projectId={projectId}
                        contractId={contractId}
                        widgetId={widgetId}
                        route={route}
                        filters={filters}
                    />,
                    <ShareInsightButton key={'shareInsight'} title={title} link={route} />,
                    <ExportDropdown key={'export'} title={title} subTitle={subTitle} />,
                ]}
                titleFilters={[
                    editNarrative && <div key={'editNarrative'}>{editNarrative}</div>,
                    widgetId && formattedFilter.length > 0 ? (
                        <WidgetFilters key={'widgetFilters'} widgetId={widgetId} filters={formattedFilter} />
                    ) : null,
                ]}
            >
                {data && data.length > 0 && (
                    <HighLevelProgram data={serialize(data)} nowDate={nowDate} filter={highLevelFilter} />
                )}
                {highLevelProgramLoading && !data && (
                    <LoaderContainer>
                        <OverlayWithSpinner />
                    </LoaderContainer>
                )}
                {!highLevelProgramLoading && (data === null || data?.length === 0) && <NoData>{noDataText}</NoData>}
            </WidgetWithTitle>
        </ErrorBoundary>
    );
};

export default React.memo(RenderHighLevelProgram);
