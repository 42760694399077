import { CustomizedButton } from 'components/common';
import React from 'react';
import { useProjectStore } from 'store/project.store';
import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/SubProgramPreferences/components/subProgramPreferences.module.scss';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { useQueryRefreshPredictionData } from 'api/mutations/refreshPredictionData.mutation';
import useUserHook from 'hooks/useUserHook';

export const RefreshFinishDatePrediction = () => {
    const { handleSnackBar } = useCustomSnackBar();
    const { mutate, isLoading } = useQueryRefreshPredictionData();
    const { ability } = useUserHook();
    const viewFinishDatePredictionIsAuthorized = ability.can('view', 'FinishDatePrediction');
    const handlePredictionRefresh = () => {
        if (contract) {
            mutate(
                {
                    contractId: contract.id,
                },
                {
                    onSuccess: () => handleSnackBar('Refresh completed successfully.', 'success'),
                    onError: (error) => {
                        handleSnackBar(error?.response?.data || 'Something went wrong, Try in a few minutes', 'error');
                    },
                },
            );
        }
    };

    const {
        selectedProject: { contract },
    } = useProjectStore();
    return viewFinishDatePredictionIsAuthorized ? (
        <>
            <span className={classes.title}>Finish Date Prediction:</span>
            <CustomizedButton isLoading={isLoading} color={'primary'} onClick={() => handlePredictionRefresh()}>
                Refresh Cache
            </CustomizedButton>
        </>
    ) : null;
};
