import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { useQuery } from 'react-query';
import { IDataStoryUser } from 'components/Dashboards/Project/Components/DataStories/dataStories.types';
import { QueryResponse } from 'api/queries/getDataStories.query';

const getStoryImage = (params) => {
    const url = `${API_URL().GET_STORY_IMAGE}?id=${params.id}`;

    return network
        .post(url, null, {
            transformRequest: [
                (data, headers) => {
                    if (headers) {
                        headers.Accept = 'image/png';
                    }
                    return data;
                },
            ],
            responseType: 'blob',
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const getExpandStory = (params) => {
    const url = `${API_URL().FETCH_PROJECT_SINGLE_DATA_STORY}?id=${params}`;

    return network.post(url).then((response) => {
        return response.data;
    });
};

const getCustomerUsersList = () => {
    return network
        .get(API_URL().FETCH_CUSTOMER_USERS)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const useQueryExpandStory = (params) => {
    return useQuery<QueryResponse, QueryError>(['expandStory', params], () => getExpandStory(params), {
        enabled: Boolean(params),
        placeholderData: {
            tags: [],
            dataStory: [],
            startDate: 0,
            endDate: 0,
            token: '',
        },
    });
};

const useQueryGetStoryImage = (params) => {
    return useQuery<string, QueryError>(['getStoryImage', params], () => getStoryImage(params), {
        enabled: Boolean(params.id),
        cacheTime: 100000,
    });
};

const useQueryGetCustomerUsersList = () => {
    return useQuery<IDataStoryUser[], QueryError>('customerUsersList', () => getCustomerUsersList());
};

export { useQueryExpandStory, useQueryGetCustomerUsersList, useQueryGetStoryImage };
