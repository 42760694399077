import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import { useSharedResourcesAvailability } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramCriticalAnalysis/queries/sharedResourcesAvailabilityQuery';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Gantt } from '@bryntum/gantt';
import GanttChartDisplay from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramCriticalAnalysis/components/SharedResourcesAvailability/ganttChartDisplay';
import { CustomizedRoundIcon, CustomizedTooltip } from 'components/common';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import { MuiIcon } from 'components/common/muiIcon/muiIcon';
import { AddWidget } from 'components/Dashboards/Project/Components/CustomDashboard/AddRemoveWidget/addWidget';
import { ExportDropdown } from 'components/common/ExportDropdown/exportDropdown';
import useCustomPageSubTitle from 'hooks/useCustomPageSubTitle';
import { GanttSettingsModal } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramGanttChart/ganttSettingsModal';
import { ISharedResourcesAvailabilityProps } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import { WidgetFilters } from 'components/Dashboards/Project/Components/CustomDashboard/WidgetFilters/widgetFilters';
import { useGlobalFiltersHook } from 'hooks/useGlobalFiltersHook';
import { getActiveFilterCount } from 'components/common/GlobalFilterComponent/GlobalFilterComponent.utils';
import NoData from 'components/common/NoData/noData';
import { useGlobalFilterDataStore } from 'store/globalFilterData.store';
import { ShareInsightButton } from 'components/common/ShareInsightButton/ShareInsightButton';
import { getStringWithoutHash } from 'utilitys/helpers/general';

export const SharedResourcesAvailability = ({
    latestVersionId,
    widgetId,
    externalComponents,
    projectId,
    contractId,
    localFilters,
    editNarrative,
    globalFilterData,
}: ISharedResourcesAvailabilityProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
    const title = 'Shared Resources Availability For Overlapping Activities';
    const id = 'shared-resources-availability';
    const route = `${getStringWithoutHash(window.location.href)}#${id}`;
    const { data: ganttData, isFetching } = useSharedResourcesAvailability({
        metaDataId: latestVersionId,
    });
    const defaultValues = useGlobalFilterDataStore((store) => store.defaultValues);
    const activeFilterCount = getActiveFilterCount(globalFilterData, Object.keys(defaultValues));
    const isGlobalFilterEmpty = activeFilterCount === 0;
    const [isCriticalPath, setIsCriticalPath] = useState<boolean>(localFilters ? localFilters?.isCriticalPath : false);
    const ganttInstance = useRef<Gantt | null>(null);
    const getInstance = useCallback((gantt) => {
        if (gantt) {
            ganttInstance.current = gantt;
        }
    }, []);

    const [showSettings, setShowSettings] = useState(false);
    const subTitle = useCustomPageSubTitle({ projectId, contractId, latestVersionId });
    useEffect(() => {
        const handleFullScreen = () => {
            if (document.fullscreenElement) {
                setIsFullScreen(true);
            } else {
                setIsFullScreen(false);
            }
        };

        document.addEventListener('fullscreenchange', handleFullScreen);
        return () => {
            document.removeEventListener('fullscreenchange', handleFullScreen);
        };
    }, []);

    const handleFullscreen = () => {
        if (ref.current) {
            if (!document.fullscreenElement) {
                return ref.current.requestFullscreen();
            } else if (document.exitFullscreen) {
                return document.exitFullscreen();
            }
        }
    };

    const zoomToFit = () => {
        const instance = ganttInstance.current;
        if (instance) {
            instance.zoomToFit();
        }
    };
    const { generateFilters: formattedFilter } = useGlobalFiltersHook({
        widgetId,
        filter: {
            filters: [{ name: 'Critical Path Only', data: [localFilters?.isCriticalPath ? 'True' : 'False'] }],
        },
    });

    return (
        <>
            <WidgetWithTitle
                id={id}
                title={title}
                tooltip={ganttData?.info}
                titleComponents={[
                    externalComponents && <div key={'externalComponents'}>{externalComponents}</div>,
                    <CustomizedTooltip
                        key={'zoomToFit'}
                        tooltipPosition={TooltipPosition.Top}
                        tooltipContent={`Zoom To Fit`}
                        triggerElement={
                            <CustomizedRoundIcon onClick={zoomToFit}>
                                <MuiIcon icon={'zoom_in_map'} fontSize={'2.4rem'} />
                            </CustomizedRoundIcon>
                        }
                    />,
                    <CustomizedTooltip
                        key={'fullScreen'}
                        tooltipPosition={TooltipPosition.Top}
                        tooltipContent={`Full Screen`}
                        triggerElement={
                            <CustomizedRoundIcon onClick={handleFullscreen}>
                                <MuiIcon icon={'fullscreen'} fontSize={'2.8rem'} />
                            </CustomizedRoundIcon>
                        }
                    />,
                    !widgetId && (
                        <CustomizedTooltip
                            tooltipPosition={TooltipPosition.Top}
                            tooltipContent={`Gantt Settings`}
                            triggerElement={
                                <CustomizedRoundIcon onClick={() => setShowSettings(true)}>
                                    <MuiIcon icon={'tune'} fontSize={'2.4rem'} />
                                </CustomizedRoundIcon>
                            }
                        />
                    ),
                    <AddWidget
                        key={'addWidget'}
                        title={title}
                        componentKey={'sharedResourcesAvailability'}
                        projectId={projectId}
                        contractId={contractId}
                        widgetId={widgetId}
                        route={route}
                        localFilters={{ isCriticalPath }}
                    />,
                    <ShareInsightButton key={'shareInsight'} title={title} link={route} />,
                    <ExportDropdown title={title} key={'export'} subTitle={subTitle} />,
                ]}
                titleFilters={[
                    editNarrative && <div key={'editNarrative'}>{editNarrative}</div>,
                    widgetId && formattedFilter.length > 0 && (
                        <WidgetFilters key={'widgetFilters'} widgetId={widgetId} filters={formattedFilter} />
                    ),
                ]}
            >
                <div ref={ref}>
                    {!isGlobalFilterEmpty ? (
                        <NoData>Not applicable with the applied filter</NoData>
                    ) : (
                        <GanttChartDisplay
                            isFullScreen={isFullScreen}
                            ganttData={ganttData}
                            isLoading={isFetching}
                            getInstanceAgain={getInstance}
                            widgetId={widgetId}
                            isCriticalPath={isCriticalPath}
                            setIsCriticalPath={setIsCriticalPath}
                        />
                    )}
                </div>
            </WidgetWithTitle>
            <GanttSettingsModal showModal={showSettings} onHideModal={() => setShowSettings(false)} />
        </>
    );
};
